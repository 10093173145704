import { useEffect, useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import { MultiSelect } from "../../Inputs/CustomMultiSelect/MultiSelect";
import {
  ContentWrapper,
  SelectBlock,
  SelectBlockLabel,
  SelectBlockTitle,
  InfoCircleIcon,
} from "./style";
import { Select } from "../../Inputs/CustomSelect/Select";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import {
  resetSelectedUsers,
  selectNewUser,
  setUsersEmails,
} from "../../../store/slices/userManagement";
import { ISelect } from "../../Inputs/CustomMultiSelect/types";
import {
  requestAddUserToTeam,
  requestAllTeams,
} from "../../../store/slices/teams";
import { getTeams } from "../../../store/selectors/teams";
import { getAllUsers } from "../../../store/selectors/users";
import { getTeamMembersByTeamId } from "../../../helpers/getLastManager";
import { setActiveModal } from "../../../store/slices/modals";

type ModalProps = {
  onClose: () => void;
};
interface Form {
  role: string;
  users: string[];
  teams: string[];
}

export const AddToTeam = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const selectedUsers = useSelector(getSelectedUsers);
  const teams = useSelector(getTeams);
  const users = useSelector(getAllUsers);

  const defaultForm: Form = {
    role: "manager",
    users: users
      ?.filter((user) => selectedUsers.find((us) => us === user.uid))
      .map((user) => user.email),
    teams: [],
  };
  useEffect(() => {
    if (!teams.length) {
      dispatch(requestAllTeams({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isClosing, setIsClosing] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    const existMembersEmail: string[] = [];

    form.teams.forEach(async (team) => {
      const teamMembersEmail = getTeamMembersByTeamId(teams, team)?.map(
        (member) => member.email
      );
      const emailsToAdd: string[] = [];

      form.users.forEach((email) => {
        if (teamMembersEmail.includes(email)) {
          dispatch(setUsersEmails(email));
          existMembersEmail.push(email);
        } else {
          emailsToAdd.push(email);
        }
      });

      if (!!emailsToAdd.length) {
        dispatch(
          requestAddUserToTeam({
            id: team,
            items: emailsToAdd?.map((user) => ({
              email: user,
              role: form.role.toLowerCase(),
            })),
            callbacks: {
              onSuccess: () => dispatch(resetSelectedUsers()),
            },
          })
        );
      }
    });

    if (!!existMembersEmail.length) {
      setTimeout(() => {
        dispatch(setActiveModal({ id: "usersAlreadyInTeam" }));
      }, 600);
    }

    handleOnClose();
  };

  const handleOnSelect = (item: ISelect[]) => {
    const currentUsers = form.users;
    const addedUsers = item.filter(
      (el) => !currentUsers.includes(typeof el === "string" ? el : el.value)
    );
    const removedUsers = currentUsers.filter(
      (el) =>
        !item.map((el) => (typeof el === "string" ? el : el.value)).includes(el)
    );
    addedUsers.forEach((el) => {
      if (typeof el !== "string") {
        const isOptionValid = users.some((option) => option.email === el.value);
        const isUserSelected = selectedUsers.some((user) => user === el.value);
        if (isOptionValid && !isUserSelected) {
          const userUid = users.find((user) => user.email === el.value)?.uid;

          if (userUid) dispatch(selectNewUser(userUid));
        }
      }
    });

    removedUsers.forEach((email) => {
      const isOptionValid = users.some((option) => option.email === email);
      if (isOptionValid) {
        const userUid = users.find((user) => user.email === email)?.uid;

        if (userUid) dispatch(selectNewUser(userUid));
      }
    });
    setForm({
      ...form,
      users: item.map((el) => (typeof el === "string" ? el : el.value)),
    });
  };

  const rolesOptions = [
    {
      option: "Manager",
      value: "manager",
      label: `Users - Invite / Remove, Edit and Block users invited by himself \nProjects - Create / Edit / Remove / Comments / Publish / Share`,
    },
    {
      option: "Editor",
      value: "editor",
      label: `Projects - Create / Edit / Remove / Comments / Publish / Share`,
    },
    {
      option: "Viewer",
      value: "viewer",
      label: `Projects - View / Comments / Share`,
    },
  ];

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalContainer>
            <ModalHeading>Add to Teams</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ContentWrapper>
              <SelectBlock>
                <MultiSelect
                  placeholder="Add people"
                  disableRightIcons
                  value={form.users}
                  options={users.map((el) => ({
                    value: el.email,
                    option: el.name,
                    label: el.email,
                    avatar: true,
                  }))}
                  limit={50}
                  errors={["@", "."]}
                  limitMessage="You can add maximum 50 members to team"
                  onSelect={handleOnSelect}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon />A team can have up to 50 members.
                </SelectBlockLabel>
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>Teams</SelectBlockTitle>
                <MultiSelect
                  placeholder="Select teams"
                  value={form.teams}
                  disableAddByEnter
                  onSelect={(item) => {
                    setForm({ ...form, teams: item.map((el) => el.value) });
                  }}
                  options={teams.map((el) => ({
                    value: el.id,
                    option: el.name,
                  }))}
                />
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>Role and permissions</SelectBlockTitle>
                <Select
                  onSelect={(item) => {
                    setForm({ ...form, role: item?.value });
                  }}
                  value={form.role}
                  options={rolesOptions}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon />{" "}
                  {rolesOptions.find((opt) => opt.value === form.role)?.label}
                </SelectBlockLabel>
              </SelectBlock>
            </ContentWrapper>
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Add"
              onClick={handleSave}
              variant={true ? "secondary" : "disabled"}
              disabled={false}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashCircle } from "react-bootstrap-icons";
import { Tooltip } from "react-tooltip";
import { getDeleteUserId } from "../../../store/selectors/users";
import { setDeleteUserId } from "../../../store/slices/users";
import { getSelectedTeam } from "../../../store/selectors/teams";
import {
  resetSelectedUsers,
  selectNewUser,
} from "../../../store/slices/userManagement";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { Button } from "../../Button";
import { AvatarCard } from "../../AvatarCard";
import { getInitials } from "../../../helpers/getInitials";
import { Chip } from "../../UserManagementComponents/Users/UsersRow/styled";
import { getTeams, getTeamUsers } from "../../../store/selectors/teams";
import {
  requestAllTeams,
  requestDeleteTeamUser,
  requestDeleteTeamUsers,
  setSelectedTeam,
} from "../../../store/slices/teams";
import { TeamMember } from "../../../models/Teams";
import { RemoveUsersIcon } from "../../../assets";

import {
  Actions,
  CloseIcon,
  ModalFooterWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
  HeaderContainer,
} from "../styles";
import * as s from "../RemoveUsers/styles";

const tooltipId = "user-team-name";

type Props = {
  onClose: () => void;
};

export const RemoveTeamUsers = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const selectedUsers = useSelector(getSelectedUsers);
  const deleteUserId = useSelector(getDeleteUserId);
  const users = useSelector(getTeamUsers);
  const teams = useSelector(getTeams);
  const selectedTeamId = useSelector(getSelectedTeam);

  const [isClosing, setIsClosing] = useState(false);
  const [removeTeamUsers, setRemoveTeamUsers] = useState<TeamMember[]>([]);
  const hasMultipleRemoveUsers = removeTeamUsers?.length > 1;
  const isDeleteUserInSelection = selectedUsers?.includes(deleteUserId);

  useEffect(() => {
    if (!!teams.length) return;

    dispatch(requestAllTeams({}));
  }, [dispatch, teams]);

  useEffect(() => {
    if ((!deleteUserId || !selectedUsers?.length) && !users?.length) {
      return;
    }
    setRemoveTeamUsers(
      users?.filter((user) =>
        (deleteUserId ? [deleteUserId] : selectedUsers)?.find(
          (us) => us === user.id
        )
      )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserId, selectedUsers]);

  const handleClose = () => {
    dispatch(setDeleteUserId(undefined));
    dispatch(setSelectedTeam(undefined));
    setIsClosing(true);
    setRemoveTeamUsers([]);

    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    if (deleteUserId) {
      dispatch(
        requestDeleteTeamUser({
          id: selectedTeamId,
          userId: deleteUserId,
          callbacks: {
            onSuccess: () => {
              if (isDeleteUserInSelection) {
                dispatch(selectNewUser(deleteUserId));
              }
            },
          },
        })
      );
    } else {
      dispatch(
        requestDeleteTeamUsers({
          teamId: selectedTeamId,
          teamUserIds: removeTeamUsers?.map((user) => user?.id),
          callbacks: {
            onSuccess: () => {
              dispatch(resetSelectedUsers());
            },
          },
        })
      );
    }

    handleClose();
  };

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer>
          <RemoveUsersIcon />
          <s.HeaderTitle>
            {`Are you sure you want to delete ${
              hasMultipleRemoveUsers
                ? `these ${selectedUsers?.length} users`
                : "this user"
            } form [${
              teams?.find((item) => item?.id === selectedTeamId)?.name
            }] team?`}
          </s.HeaderTitle>
          <s.HeaderDescrition>
            {hasMultipleRemoveUsers
              ? "Removing these users will only remove them from this team, not from the system."
              : "Removing the user will only remove them from this team, not from the system."}
          </s.HeaderDescrition>
          <s.HeaderCloseIcon>
            <CloseIcon onClick={handleClose} />
          </s.HeaderCloseIcon>
        </HeaderContainer>

        <OverviewContent>
          <s.BodyContainer>
            {!!removeTeamUsers?.length &&
              removeTeamUsers?.map((teamUser, index, teamUsers) => (
                <s.RemoveUserContainer
                  key={teamUser?.uid}
                  $isLastItem={teamUsers?.length === index + 1}
                >
                  <s.RemoveUserBox $alignItems="center">
                    <s.RemoveAvatarBox>
                      <AvatarCard
                        avatar={teamUser?.user?.profile?.avatar}
                        size="xs"
                        initials={getInitials(teamUser?.user.name)}
                      />
                      <s.RemoveAvatarName
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={teamUser?.user?.name}
                      >
                        {teamUser?.user?.name}
                      </s.RemoveAvatarName>
                    </s.RemoveAvatarBox>

                    <s.RemoveTeamBox>
                      <s.RemoveUserRole>
                        <Chip $role={teamUser?.role}>{teamUser?.role}</Chip>
                      </s.RemoveUserRole>
                    </s.RemoveTeamBox>
                  </s.RemoveUserBox>
                </s.RemoveUserContainer>
              ))}
            <Tooltip id={tooltipId} style={{ zIndex: 9999 }} />
          </s.BodyContainer>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Remove"
              onClick={handleSave}
              variant="danger"
              size="medium"
              icon={<DashCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

import React, { useEffect, useState } from "react";
import { THElement } from "../CreateProjectDataTable/components/THElement";
import { TableBody, TableHeader } from "../CreateProjectDataTable/style";
import { TRElement } from "../CreateProjectDataTable/components/TRElement";
import { FileLibraryItem } from "../../../../../../models/Files";
import { MeasuresType } from "../../../../CreateComparison/utils";
import SortingMenu from "../../../../../SortMenuTable/SortTableMenu";

type Props = {
  programSelected?: string;
  setProgramSelected: any;
  search?: string;
  datasets?: FileLibraryItem[];
  allMeasures?: { [key: string]: MeasuresType[] };
};

const SelectProgramStep: React.FC<Props> = ({
  programSelected,
  setProgramSelected,
  search,
  datasets,
  allMeasures,
}) => {
  const [dataSets, setDatasets] = useState<FileLibraryItem[]>(datasets || []);
  const [columnMenu, setColumnMenu] = useState<{
    top: number;
    left: number;
    key: string;
  } | null>(null);
  const [activeSort, setActiveSort] = useState<{
    key: string;
    order: "asc" | "desc";
  }>({
    key: "",
    order: "asc",
  });

  useEffect(() => {
    const newValue = search?.trim()?.toLowerCase();
    if (newValue) {
      const filteredDatasets = datasets?.filter((item) =>
        item.name.trim()?.toLowerCase().includes(newValue)
      );
      setDatasets(filteredDatasets || []);
    } else {
      setDatasets(datasets || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns = [
    { key: "name", label: "Program name", size: "367px" },
    {
      key: "measures",
      label: "Measures",
      getValue: (item: FileLibraryItem) =>
        (allMeasures && allMeasures[item?.id!]?.length) ?? 0,
      size: "91px",
    },
  ];

  const handleColumnClick = (event: React.MouseEvent, key: string) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const top = rect.bottom;
    const left = rect.left;
    setColumnMenu({ top: top + 55, left: left - 10, key });
  };

  return (
    <>
      <TableHeader>
        {columns.map((column) => (
          <THElement
            text={column.label}
            key={column.key}
            size={column?.size}
            onClick={(e) => handleColumnClick(e, column.key)}
          />
        ))}
      </TableHeader>
      {dataSets.length ? (
        <TableBody>
          {dataSets.map((item) => (
            <TRElement
              key={item.id}
              id={item.name}
              header={item.name}
              subHeader={item?.description || ""}
              measures={(allMeasures && allMeasures[item?.id!]?.length) ?? 0}
              selected={programSelected === item.id}
              selectHandler={() => setProgramSelected(item.id)}
            />
          ))}
        </TableBody>
      ) : null}
      {columnMenu && (
        <SortingMenu
          top={columnMenu.top}
          left={columnMenu.left}
          data={dataSets}
          setData={setDatasets}
          columns={columns}
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          closeMenu={() => setColumnMenu(null)}
          columnKey={columnMenu.key} // Pass column key to SortingMenu
        />
      )}
    </>
  );
};

export default SelectProgramStep;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getProjects } from "../../store/selectors/projects";
import { useSortByCreatedDate } from "../../hooks/useSortByDate";
import { setActiveModal } from "../../store/slices/modals";
import {
  TableCell,
  TableContainer,
  TableFilters,
  TableHead,
  TableHeadWrapper,
} from "./styles";
import { IActiveFilters } from "../UserManagementComponents/Users/UsersList";
import InfiniteScroll from "react-infinite-scroll-component";
import ProjectRow from "./components/ProjectRow";
import moment from "moment";
import { ThreeDots } from "react-bootstrap-icons";
import SortingMenu from "../SortMenuTable/SortTableMenu";
import { EmptyProjects } from "../EmptyProjects";
import TableValuesFilter from "../TableValuesFilter";
import { applyFilters } from "../TableValuesFilter/utils/filters";

export interface IProjectsList {
  id: string;
  name: string;
  description: string;
  pages: number;
  role: string;
  team: string[];
  updatedOn: string;
}

export type ProjectListKeys = keyof IProjectsList;

export const ProjectsTable = () => {
  const { items: projects } = useSelector(getProjects);
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleSortByCreatedDate, sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(projects ? projects : []);
  
  const allProjects: IProjectsList[] = sortedItems.map(
    (item: any, index: number) => {
      return ({
      id: item.id,
      name: item.name,
      description: item.description,
      pages: item.pagesCount,
      role: item?.role ? item?.role : index % 2 ? "owner" : "manager",
      team: item?.team ? item?.team : index % 2 ? ["IT and Technical"] : ["Administratives"],
      updatedOn: moment(item.updatedAt).format("DD/MM/YYYY h:mm A"),
    })}
  );

  const openCreateProjectModal = () =>
    dispatch(setActiveModal({ id: "createProjectModal" }));
  const [maxData, setMaxData] = useState<number>(30);
  const [hasMore, setHasMore] = useState(true);
  const [columnMenu, setColumnMenu] = useState<{
    top: number;
    left: number;
    key: string;
  } | null>(null);
  const [activeFilters, setActiveFilters] = useState<
    IActiveFilters<IProjectsList>
  >({});
  const [isOpenFilter, setIsOpenFilter] = useState<ProjectListKeys | null>(
    null
  );

  const maxDataLength = allProjects.length;
  const end = maxData;

  const handleColumnClick = (event: React.MouseEvent, key: string) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const top = rect.bottom;
    const left = rect.left;
    setColumnMenu({ top: top + 60, left: left - 10, key });
  };

  const fetchData = () => {
    if (maxData + 30 < maxDataLength) {
      setMaxData((prev) => prev + 30);
    } else {
      setMaxData(maxDataLength);
      setHasMore(false);
    }
  };

  const [projectsList, setProjectsList] =
    useState<IProjectsList[]>(allProjects);

  useEffect(() => {
    const newData = applyFilters({
      data: allProjects,
      activeFilters,
    });
    setProjectsList(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const [activeSort, setActiveSort] = useState<{
    key: string;
    order: "asc" | "desc";
  }>({
    key: "",
    order: "asc",
  });

  const columns = [
    {
      key: "name",
      label: "Name",
      size: undefined,
    },
    {
      key: "description",
      label: "Description",
      size: undefined,
    },
    {
      key: "pages",
      label: "Pages",
      size: undefined,
    },
    {
      key: "role",
      label: "Role",
      size: undefined,
    },
    {
      key: "team",
      label: "Team",
      size: undefined,
    },
    {
      key: "updatedOn",
      label: "Updated on",
      size: undefined,
    },
  ];

  return (
    <>
      {!sortedItems.length ? (
        <EmptyProjects
          openNewProjectModal={openCreateProjectModal}
          isShowButton={!location.pathname.includes("/users/") && !id}
        />
      ) : (
        <>
          <TableContainer>
            <TableHeadWrapper>
              <TableHead>
                <TableCell $width={236}>
                  Name
                  <ThreeDots onClick={(e) => handleColumnClick(e, "name")} />
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell $width={96}>
                  Pages
                  <ThreeDots onClick={(e) => handleColumnClick(e, "pages")} />
                </TableCell>
                <TableCell $width={120}>
                  Role
                  <ThreeDots onClick={(e) => handleColumnClick(e, "role")} />
                </TableCell>
                <TableCell $width={200}>
                  Team
                  <ThreeDots onClick={(e) => handleColumnClick(e, "team")} />
                </TableCell>
                <TableCell $width={160}>
                  Updated on
                  <ThreeDots
                    onClick={(e) => handleColumnClick(e, "updatedOn")}
                  />
                </TableCell>
                <TableCell $width={32}></TableCell>
              </TableHead>
              <TableFilters>
                <TableCell $width={236} $noPadding>
                  <TableValuesFilter
                    keyName={"name"}
                    filter={false}
                    dataList={allProjects}
                    setIsOpenFilter={setIsOpenFilter}
                    isOpenFilter={isOpenFilter}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                  />
                </TableCell>
                <TableCell $noPadding>
                  <TableValuesFilter
                    keyName={"description"}
                    dataList={allProjects}
                    setIsOpenFilter={setIsOpenFilter}
                    isOpenFilter={isOpenFilter}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    disabled
                    filter={false}
                  />
                </TableCell>
                <TableCell $width={96} $noPadding>
                  <TableValuesFilter
                    keyName={"pages"}
                    dataList={allProjects}
                    setIsOpenFilter={setIsOpenFilter}
                    isOpenFilter={isOpenFilter}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    disabled
                    filter={false}
                  />
                </TableCell>
                <TableCell $width={120} $noPadding>
                  <TableValuesFilter
                    keyName={"role"}
                    dataList={allProjects}
                    setIsOpenFilter={setIsOpenFilter}
                    isOpenFilter={isOpenFilter}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                  />
                </TableCell>
                <TableCell $width={200} $noPadding>
                  <TableValuesFilter
                    keyName={"team"}
                    dataList={allProjects}
                    setIsOpenFilter={setIsOpenFilter}
                    isOpenFilter={isOpenFilter}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                  />
                </TableCell>
                <TableCell $width={160} $noPadding>
                  <TableValuesFilter
                    keyName={"updatedOn"}
                    dataList={allProjects}
                    setIsOpenFilter={setIsOpenFilter}
                    isOpenFilter={isOpenFilter}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                  />
                </TableCell>
                <TableCell $width={32} $noPadding></TableCell>
              </TableFilters>
            </TableHeadWrapper>

            <InfiniteScroll
              dataLength={maxData}
              next={fetchData}
              hasMore={hasMore}
              loader={false && <h4>Loading...</h4>}
              endMessage={null}
            >
              <div
                style={{
                  maxHeight: "100%",
                  overflowY: "auto",
                }}
              >
                {projectsList.slice(0, end).map((item, index: number) => (
                  <>
                    <ProjectRow
                      id={item.id}
                      name={item.name}
                      description={item.description}
                      pages={item.pages}
                      role={item.role}
                      team={item.team}
                      updatedOn={item.updatedOn}
                    />
                  </>
                ))}
              </div>
            </InfiniteScroll>
          </TableContainer>
        </>
      )}

      {columnMenu && (
        <SortingMenu
          top={columnMenu.top}
          left={columnMenu.left}
          data={projectsList}
          setData={setProjectsList}
          columns={columns}
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          closeMenu={() => setColumnMenu(null)}
          columnKey={columnMenu.key} // Pass column key to SortingMenu
        />
      )}
    </>
  );
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DashCircle } from "react-bootstrap-icons";
import { Tooltip } from "react-tooltip";
import { AvatarCard } from "../../AvatarCard";
import { getInitials } from "../../../helpers/getInitials";
import { Button } from "../../Button";
import {
  requestDeleteTeam,
  setMembersIdWithSingleTeam,
  setSelectedTeam,
} from "../../../store/slices/teams";
import { requestAllUsers } from "../../../store/slices/users";
import {
  getMembersIdWithSingleTeam,
  getSelectedTeam,
  getTeams,
} from "../../../store/selectors/teams";
import { RemoveTeamIcon } from "../../../assets";

import { Chip } from "../../UserManagementComponents/Users/UsersRow/styled";
import {
  Actions,
  CloseIcon,
  ModalFooterWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
  HeaderContainer,
} from "../styles";
import * as s from "../RemoveUsers/styles";

const tooltipId = "user-team-name";

type Props = {
  onClose: () => void;
};

export const RemoveTeam = ({ onClose }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedTeamId = useSelector(getSelectedTeam);
  const teams = useSelector(getTeams);
  const membersIdWithSingleTeam = useSelector(getMembersIdWithSingleTeam);

  const [isClosing, setIsClosing] = useState(false);

  const members = teams
    ?.find((team) => team?.id === selectedTeamId)
    ?.members?.filter((member) =>
      membersIdWithSingleTeam?.some((id) => id === member?.user?.uid)
    );

  const hasMultipleMembers = members?.length > 1;

  const handleClose = () => {
    dispatch(setSelectedTeam(undefined));
    dispatch(setMembersIdWithSingleTeam([]));
    dispatch(requestAllUsers({ limit: 30 }));
    setIsClosing(true);

    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    dispatch(
      requestDeleteTeam({
        id: selectedTeamId,
        callbacks: {
          onSuccess: () => {
            const teamId = localStorage.getItem("selectedTeam");
            if (teamId === selectedTeamId) {
              const newTeam = teams
                .filter((item) => item.id !== selectedTeamId)
                ?.at(0);
              localStorage.setItem("selectedTeam", newTeam.id);
            }
          },
        },
      })
    );

    handleClose();
    navigate("/management/teams");
  };

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer $isShowDivider={members?.length >= 1}>
          <RemoveTeamIcon />
          <s.HeaderTitle>
            {`Are you sure you want to delete the team [${
              teams?.find((item) => item?.id === selectedTeamId)?.name
            }]`}
          </s.HeaderTitle>
          <s.HeaderDescrition>
            {hasMultipleMembers
              ? "Deleting the team will also remove all users from the team. These users, who are only in this team, will be left without."
              : `Deleting the team will also remove the user from the team. ${
                  members?.length === 1
                    ? "This user, who are only in this team, will be left without."
                    : ""
                }`}
          </s.HeaderDescrition>
          <s.HeaderCloseIcon>
            <CloseIcon onClick={handleClose} />
          </s.HeaderCloseIcon>
        </HeaderContainer>

        <OverviewContent>
          <s.BodyContainer>
            {!!members?.length &&
              members?.map((teamUser, index, teamUsers) => (
                <s.RemoveUserContainer
                  key={teamUser?.id}
                  $isLastItem={teamUsers?.length === index + 1}
                >
                  <s.RemoveUserBox $alignItems="center">
                    <s.RemoveAvatarBox>
                      <AvatarCard
                        avatar={teamUser?.user?.profile?.avatar}
                        size="xs"
                        initials={getInitials(teamUser?.user?.name)}
                      />
                      <s.RemoveAvatarName
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={teamUser?.user?.name}
                      >
                        {teamUser?.user?.name}
                      </s.RemoveAvatarName>
                    </s.RemoveAvatarBox>

                    <s.RemoveTeamBox>
                      <s.RemoveUserRole>
                        <Chip $role={teamUser?.role}>{teamUser?.role}</Chip>
                      </s.RemoveUserRole>
                    </s.RemoveTeamBox>
                  </s.RemoveUserBox>
                </s.RemoveUserContainer>
              ))}
            <Tooltip id={tooltipId} style={{ zIndex: 9999 }} />
          </s.BodyContainer>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Remove"
              onClick={handleSave}
              variant="danger"
              size="medium"
              icon={<DashCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

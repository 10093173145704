import { useEffect, useMemo, useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import { MultiSelect } from "../../Inputs/CustomMultiSelect/MultiSelect";
import {
  ContentWrapper,
  SelectBlock,
  SelectBlockLabel,
  SelectBlockTitle,
  InfoCircleIcon,
} from "./style";
import { Select } from "../../Inputs/CustomSelect/Select";
import { mockDataUsersList } from "../../../pages/UserManagement/pages/Users/data/mock";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { selectNewUser } from "../../../store/slices/userManagement";
import { ISelect } from "../../Inputs/CustomMultiSelect/types";
import { useLocation } from "react-router";
import {
  requestAddUserToTeam,
  requestAllTeams,
  setSelectedTeam,
} from "../../../store/slices/teams";
import { getSelectedTeam, getTeams } from "../../../store/selectors/teams";
import { getAllUsers } from "../../../store/selectors/users";
import { requestAllUsers } from "../../../store/slices/users";

type ModalProps = {
  onClose: () => void;
};
interface Form {
  role: string;
  users: string[];
}

export const InviteToTeam = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const selectedUsers = useSelector(getSelectedUsers);
  const selectedTeam = useSelector(getSelectedTeam);
  const teams = useSelector(getTeams);
  const users = useSelector(getAllUsers);
  const { pathname } = useLocation();
  const id = selectedTeam || pathname.split("/").pop();


  useEffect(() => {
    if (!teams.length) {
      dispatch(requestAllTeams({}));
    }
    if (!users.length) {
      dispatch(requestAllUsers({ limit: 1000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const team = useMemo(() => (id ? teams?.find((item) => item.id === id) : undefined), [id, teams])

  const defaultForm: Form = {
    role: "manager",
    users: selectedUsers,
  };

  const [isClosing, setIsClosing] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      dispatch(setSelectedTeam(""));
    }, 400);
  };

  const handleSave = () => {
    handleOnClose();
    if (id) {
      dispatch(
        requestAddUserToTeam({
          id: id,
          items: form.users?.map((user) => ({
            email: user,
            role: form.role.toLowerCase(),
          })),
        })
      );
      toast.success("Successfuly!");
    }
  };

  const handleOnSelect = (item: ISelect[]) => {
    const currentUsers = form.users;
    const addedUsers = item.filter(
      (el) => !currentUsers.includes(typeof el === "string" ? el : el.value)
    );
    const removedUsers = currentUsers.filter(
      (el) =>
        !item.map((el) => (typeof el === "string" ? el : el.value)).includes(el)
    );
    addedUsers.forEach((el) => {
      if (typeof el !== "string") {
        const isOptionValid = mockDataUsersList.some(
          (option) => option.uuid === el.value
        );
        const isUserSelected = selectedUsers.some((user) => user === el.value);
        if (isOptionValid && !isUserSelected) {
          dispatch(selectNewUser(el.value));
        }
      }
    });
    removedUsers.forEach((user) => {
      dispatch(selectNewUser(user));
    });
    setForm({
      ...form,
      users: item.map((el) => (typeof el === "string" ? el : el.value)),
    });
  };

  const rolesOptions = [
    {
      option: "Manager",
      value: "manager",
      label: `Users - Invite / Remove, Edit and Block users invited by himself \nProjects - Create / Edit / Remove / Comments / Publish / Share`,
    },
    {
      option: "Editor",
      value: "editor",
      label: `Projects - Create / Edit / Remove / Comments / Publish / Share`,
    },
    {
      option: "Viewer",
      value: "viewer",
      label: `Projects - View / Comments / Share`,
    },
  ];

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalContainer>
            <ModalHeading>Invite People to {'['}{team?.name}{']'}</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ContentWrapper>
              <SelectBlock>
                <MultiSelect
                  placeholder="Add people"
                  disableRightIcons
                  value={form.users}
                  options={[...users].filter((el) => !team?.members?.some((member) => member.email === el.email)).map((el) => ({ value: el.email, option: el.name, label: el.email, avatar: true }))}
                  hiddenOptions={[...users].filter((el) => team?.members?.filter((member) => member.email === el.email)).map((el) => ({ value: el.email, option: el.name, label: el.email, avatar: true }))}
                  hiddenMessage="User already in the team"
                  limit={team?.members?.length >= 50 ? 0 : 50 - (team?.members?.length - 1 || 0)}
                  errors={["@", "."]}
                  limitMessage="You can add maximum 50 members to team"
                  onSelect={handleOnSelect}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon />A team can have up to 50 members.
                </SelectBlockLabel>
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>Role and permissions</SelectBlockTitle>
                <Select
                  onSelect={(item) => {
                    setForm({ ...form, role: item?.value });
                  }}
                  value={form.role}
                  options={rolesOptions}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon />{" "}
                  {rolesOptions.find((opt) => opt.value === form.role)?.label}
                </SelectBlockLabel>
              </SelectBlock>
            </ContentWrapper>
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Invite"
              onClick={handleSave}
              variant={true ? "secondary" : "disabled"}
              disabled={false}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

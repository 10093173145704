import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Bell,
  BellFill,
  ChatLeftTextFill,
  Share,
  ArrowLeft,
  ChevronRight,
  CheckLg,
} from "react-bootstrap-icons";

import { ReactComponent as Builder } from "./assets/builder.svg";
import { ReactComponent as Overview } from "./assets/overview.svg";
import { ReactComponent as DesignSystem } from "./assets/ds.svg";

import {
  HeaderContainer,
  LinkContainer,
  MenuWrapper,
  NavLinkButton,
  NotificationAlert,
  Icon,
  ProjectNameTitle,
  ProjectNameWrapper,
  ProjectTitleAction,
  VerticalSeparator,
  ProjectTitleWrapper,
  Status,
  StatusTitle,
  Hamburger,
  RightWrapper,
  ClockIcon,
  ProjectNameBlock,
  ProjectPopulation,
  ProjectSettingsBlock,
  SubmenuBlock,
  ProjectTitleBlock,
  Header,
  RightBlock,
  LeftBlock,
  Comments,
  HamburgerMenuWrapper,
  HamburgerMenu,
  HamburgerMenuItem,
  HamburgerCloseIcon,
  IconWrapper,
  LinkBox,
} from "./styles";
import { Search } from "../SearchBar";
import { Button } from "../Button";
import { Logo } from "../../assets";
import { UserDetails } from "../UserDetails";
import { Submenu } from "../Submenu";
import {
  requestCloneProjectById,
  requestDeleteProject,
  setProjectsSearch,
} from "../../store/slices/projects";
import { getIsPublicMode, getUser } from "../../store/selectors/main";
import {
  getAllPages,
  getCurrentProjectData,
  getCurrentProjectId,
} from "../../store/selectors/projects";
import { setActiveModal } from "../../store/slices/modals";
import { useDebounce } from "../../hooks/useDebounce";
import { setIsEditMode } from "../../store/slices/appConfig";
import { EditSwitchComponent } from "../Inputs/EditSwitchComponent/Switch";
import { DotSeparator } from "../ProjectMenu/styles";
import { DiscussionMain } from "../Comments/components/DiscussionMain/DiscussionMain";
import { countUnreadComments } from "../../store/selectors/comments";
import { requestFetchComments } from "../../store/slices/commnets";
import { Loader } from "../Loader";
import { useOnClickOutside } from "usehooks-ts";
import { requestProfile } from "../../store/slices/profiles";
import { useAbilityContext } from "../../casl/context";
import { getProfiles } from "../../store/selectors/profiles";

export const NavBar = () => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const ability = useAbilityContext();

  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEditMode, setProjectEditMode] = useState(false);
  const [openHamburger, setOpenHamburger] = useState<boolean>(false);

  const [loadingClone, setLoadingClone] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const project = useSelector(getCurrentProjectData);
  const isPublicMode = useSelector(getIsPublicMode);
  const pages = useSelector(getAllPages);
  const totalCommentsCount = useSelector(countUnreadComments);
  const projectId = useSelector(getCurrentProjectId);
  const profile = useSelector(getProfiles);

  const formatter = new Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
  });
  const matchManagement = useMatch("/management/*");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const currentPath = location.pathname;
  const hiddenRoutePattern = /^\/projects\/.+/;
  const currentId = currentPath.split("/projects/")[1]?.split("/")[0];

  const debounceValue = useDebounce(searchInput, 1000);

  useOnClickOutside([menuRef], () => setOpenHamburger(false));

  useEffect(() => {
    if (user?.accessToken) {
      dispatch(requestProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const count = pages.items?.reduce((acc, obj) => {
    if (obj.isPublic) {
      return acc + 1;
    }
    return acc;
  }, 0);

  useEffect(() => {
    dispatch(setProjectsSearch(debounceValue));
  }, [debounceValue, dispatch]);

  useEffect(() => {
    if (!projectId) return;
    if (!isPublicMode) dispatch(requestFetchComments({ projectId }));
  }, [dispatch, isPublicMode, projectId]);

  useEffect(() => {
    setProjectEditMode(true);
    // dispatch(setIsEditMode(true));
  }, [currentId, dispatch]);

  const removeProject = () => {
    dispatch(requestDeleteProject({ id: currentId, navigate }));
    navigate("/");
    setMenuOpen(false);
  };

  const onDuplicate = () => {
    setLoadingClone(true);
    dispatch(
      requestCloneProjectById({
        id: currentId,
        callbacks: {
          onSuccess: (data) => {
            setLoadingClone(false);
            if (data && data.id) {
              const page = data?.pages?.at(0);
              if (page) {
                navigate!(`/projects/${data.id}/${page.id}`);
              } else {
                navigate!("/projects");
                setTimeout(() => {
                  navigate(`/projects/${data.id}`);
                }, 300);
              }
            }
          },
        },
      })
    );
  };

  if (location.pathname === "/sign-in") return null;
  if (location.pathname === "/sign-up") return null;
  if (location.pathname === "/forgot-password") return null;
  if (location.pathname === "/new-profile") return null;
  if (location.pathname === "/reset-password") return null;

  const onEdit = () => {
    dispatch(
      setActiveModal({ id: "createProjectModal", projectId: currentId })
    );
  };

  const setEditMode = () => {
    setProjectEditMode(!isEditMode);
    dispatch(setIsEditMode(!isEditMode));
  };

  return (
    <Header>
      {loadingClone ? <Loader fullScreen={true} /> : null}
      <HeaderContainer>
        {!isPublicMode && (
          <>
            <HamburgerMenuWrapper $isOpen={openHamburger}>
              {!openHamburger && (
                <IconWrapper onClick={() => setOpenHamburger(true)}>
                  <Hamburger />
                </IconWrapper>
              )}
              {openHamburger && (
                <IconWrapper onClick={() => setOpenHamburger(false)}>
                  <HamburgerCloseIcon />
                </IconWrapper>
              )}
              {openHamburger && (
                <HamburgerMenu ref={menuRef}>
                  <HamburgerMenuItem
                    href="https://dhcs-design.fuselabcreative.com/overview"
                    target="_blank"
                  >
                    <Overview className={"icon"} />
                    Overview
                    <ChevronRight />
                  </HamburgerMenuItem>
                  <HamburgerMenuItem $active>
                    <Builder className={"icon"} />
                    Builder
                    <CheckLg />
                  </HamburgerMenuItem>
                  <HamburgerMenuItem
                    href="https://dhcs-design.fuselabcreative.com/"
                    target="_blank"
                  >
                    <DesignSystem className={"icon"} />
                    Design System
                    <ChevronRight />
                  </HamburgerMenuItem>
                </HamburgerMenu>
              )}
            </HamburgerMenuWrapper>

            <VerticalSeparator />
          </>
        )}
        <LeftBlock $isPublic={isPublicMode}>
          <LinkBox>
            <NavLink to="/" style={{ display: "flex", alignItems: "center" }}>
              <img width="138" src={Logo} alt="Logo" />
            </NavLink>
            <VerticalSeparator $right={-12} />
          </LinkBox>
          {hiddenRoutePattern.test(currentPath) && (
            <>
              <ProjectNameWrapper>
                {!isPublicMode && (
                  <ArrowLeft
                    fill="#5F6877"
                    cursor={"pointer"}
                    onClick={() => navigate("/projects")}
                  />
                )}
                <ProjectTitleWrapper>
                  <ProjectNameBlock>
                    <ProjectTitleBlock>
                      <ProjectNameTitle>{project?.name}</ProjectNameTitle>
                      {!isPublicMode && isEditMode && (
                        <SubmenuBlock>
                          <ProjectTitleAction
                            onClick={() => {
                              setMenuOpen((prevMenuOpen) => !prevMenuOpen);
                            }}
                          />
                        </SubmenuBlock>
                      )}
                    </ProjectTitleBlock>
                    <ProjectSettingsBlock>
                      <ProjectPopulation>
                        {project?.population}
                      </ProjectPopulation>
                      <DotSeparator />
                      <ProjectPopulation>{project?.measure}</ProjectPopulation>
                    </ProjectSettingsBlock>
                  </ProjectNameBlock>
                  {!isPublicMode && project?.isPublic && (
                    <Status $size={11}>
                      <StatusTitle>Published - {count}</StatusTitle>
                    </Status>
                  )}
                  {isEditMode && menuOpen && (
                    <Submenu
                      projectMenu
                      menuOpen={menuOpen}
                      onEdit={onEdit}
                      setMenuOpen={setMenuOpen}
                      onRemove={removeProject}
                      onDuplicate={onDuplicate}
                    />
                  )}
                </ProjectTitleWrapper>
              </ProjectNameWrapper>
              <RightWrapper>
                {!isPublicMode && (
                  <Comments>
                    <Icon
                      $showAlert={showAlert2}
                      onClick={() => {
                        setShowAlert2(!showAlert2);
                      }}
                    >
                      {!showAlert2 ? (
                        <ChatLeftTextFill />
                      ) : (
                        <ChatLeftTextFill />
                      )}
                      {!!totalCommentsCount && (
                        <NotificationAlert $active={!showAlert2}>
                          {formatter.format(totalCommentsCount)}
                        </NotificationAlert>
                      )}
                    </Icon>
                    {showAlert2 && (
                      <DiscussionMain setShowAlert2={setShowAlert2} />
                    )}
                  </Comments>
                )}
                <Button
                  onClick={() => {
                    dispatch(setActiveModal({ id: "shareProjectModal" }));
                  }}
                  variant="primary"
                  size="medium"
                  name="Share"
                  icon={<Share />}
                />
                {!isPublicMode && (
                  <EditSwitchComponent
                    onChange={setEditMode}
                    onBlur={() => {}}
                    checked={isEditMode}
                  />
                )}
              </RightWrapper>
            </>
          )}

          {!!profile?.teams?.length &&
            !hiddenRoutePattern.test(currentPath) && (
              <>
                <LinkContainer>
                  <NavLinkButton to="/projects">Projects</NavLinkButton>
                  <NavLinkButton to="/programs">Programs</NavLinkButton>
                  <NavLinkButton
                    to={
                      ability.can("read", "users")
                        ? "/management/users"
                        : "/management/teams"
                    }
                    className={matchManagement ? "active" : ""}
                  >
                    User Management
                  </NavLinkButton>
                </LinkContainer>
              </>
            )}

          <MenuWrapper>
            {!!location.pathname.includes("/projects") &&
              !!profile?.teams?.length &&
              !hiddenRoutePattern.test(currentPath) && (
                <Search
                  width="medium"
                  onChange={(value: string) => setSearchInput(value)}
                />
              )}

            {/* <VerticalSeparator $right={-10} /> */}
            {!isPublicMode && (
              <Icon $showAlert={false} onClick={() => {}} $disableBackground>
                <ClockIcon />
              </Icon>
            )}
          </MenuWrapper>
        </LeftBlock>
        {!isPublicMode && (
          <RightBlock>
            <Icon
              $disableBackground
              $color={"neutralTextWeak"}
              $showAlert={showAlert}
              onClick={() => {
                setShowAlert(!showAlert);
              }}
            >
              {showAlert ? <BellFill /> : <Bell />}
              {showAlert ? <NotificationAlert>8</NotificationAlert> : null}
            </Icon>
            <UserDetails />
          </RightBlock>
        )}
      </HeaderContainer>
    </Header>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Check } from "react-bootstrap-icons";
import { getAllUsers } from "../../../store/selectors/users";
import { getUsersEmails } from "../../../store/selectors/userManagement";
import {
  resetSelectedUsers,
  resetUsersEmails,
} from "../../../store/slices/userManagement";
import { AvatarCard } from "../../AvatarCard";
import { Button } from "../../Button";
import { getInitials } from "../../../helpers/getInitials";
import { Chip } from "../../UserManagementComponents/Users/UsersRow/styled";
import { IUser } from "../../../models/Users";
import { AlreadyExistUsersIcon } from "../../../assets";

import {
  CloseIcon,
  HeaderContainer,
  ModalFooterWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import * as s from "../RemoveUsers/styles";

const tooltipId = "users-already-in-team";

type Props = {
  onClose: () => void;
};

export const UsersAlreadyInTeam = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const users = useSelector(getAllUsers);
  const emails = useSelector(getUsersEmails);
  const hasMultipleEmails = emails?.length > 1;

  const [isClosing, setIsClosing] = useState(false);
  const [existUsers, setExistUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (!emails?.length && !users?.length) {
      return;
    }
    setExistUsers(
      users?.filter((user) => emails?.find((email) => email === user.email))
    );
  }, [users, emails]);

  const handleClose = () => {
    setIsClosing(true);
    dispatch(resetUsersEmails());
    dispatch(resetSelectedUsers());

    setTimeout(() => {
      onClose();
    }, 400);
  };

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer>
          <AlreadyExistUsersIcon />

          <s.HeaderTitle>
            {hasMultipleEmails
              ? "These users are already in the teams!"
              : "This user is already in the teams!"}
          </s.HeaderTitle>
          <s.HeaderDescrition>
            {hasMultipleEmails
              ? "To avoid team member duplication, re-adding users to the team is not possible."
              : "To avoid team member duplication, re-adding user to the team is not possible."}
          </s.HeaderDescrition>
          <s.HeaderCloseIcon>
            <CloseIcon onClick={handleClose} />
          </s.HeaderCloseIcon>
        </HeaderContainer>

        <OverviewContent>
          <s.BodyContainer>
            {!!existUsers?.length &&
              existUsers?.map((user, index, users) => (
                <s.RemoveUserContainer
                  key={user?.uid}
                  $isLastItem={users?.length === index + 1}
                >
                  <s.RemoveUserBox>
                    <s.RemoveAvatarBox>
                      <AvatarCard
                        avatar={user?.profile?.avatar}
                        size="xs"
                        initials={getInitials(user.name)}
                      />
                      <s.RemoveAvatarName
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={user?.name}
                      >
                        {user?.name}
                      </s.RemoveAvatarName>
                    </s.RemoveAvatarBox>
                    <s.RemoveTeamBox>
                      {user?.teams?.teams?.map((team, index) => (
                        <Chip key={index}>{team?.name}</Chip>
                      ))}
                    </s.RemoveTeamBox>
                  </s.RemoveUserBox>
                </s.RemoveUserContainer>
              ))}
            <Tooltip id={tooltipId} style={{ zIndex: 9999 }} />
          </s.BodyContainer>
        </OverviewContent>

        <ModalFooterWrapper $flexEnd={true}>
          <Button
            name="Got it"
            onClick={handleClose}
            variant="secondary"
            size="medium"
            icon={<Check />}
          />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

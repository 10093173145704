import styled, { css } from "styled-components";
import { ThreeDots } from "react-bootstrap-icons";

const textStyle = css`
  color: ${({theme}) => theme.neutralText};
  font-style: normal;
  padding: 9px 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`

export const RowWrapper = styled.div<{$selected?: boolean}>`
  display: flex;
  overflow: visible;
  align-items: center;
  transition: 0.3s;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  &:last-child{
    border: none;
  }

  &:hover {
    ${({$selected, theme}) => !$selected && `
      background: ${theme.neutralBackgroundHover}
    `}
  }

  ${({$selected, theme}) => $selected && `
    background: ${theme.primaryBackground}
  `}
`;

export const CheckBoxWrapper = styled.div`
  ${textStyle};
  min-width: 34px;
`


export const FileName = styled.div`
  ${textStyle};
  word-wrap: break-word;
  font-weight: 500;
  max-width: 220px;
  font-size: 12px;
  cursor: pointer;

  padding: 8px;
  width: 100%;
`;

export const Dimensions = styled.div`
  display: flex;
  min-height: 20px;
  gap: 4px;
  flex: 4;
  flex-flow: wrap;
  min-width: 250px;
  padding-right: 10px;
  width: 100%;
  color: ${({ theme }) => theme.neutralText};
`;

export const Teams = styled.div`
  display: flex;
  min-height: 20px;
  gap: 4px;
  flex: 4;
  flex-flow: wrap;
  min-width: 250px;
  padding-right: 10px;
  width: 100%;
  color: ${({ theme }) => theme.neutralText};
`;

export const LabelText = styled.div`
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Dimension = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.neutralBackground};
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  align-self: center;

  img {
    height: 14px;
    width: 14px;
  }
`;

export const DatasetNameSeparator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  height: 16px;
  position: relative;
  align-self: center;
`;

export const UploadedOn = styled.div`
  display: flex;
  gap: 3px;
  text-align: right;
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  font-weight: 400;
  min-width: 215px;
  margin-right: auto;
`;
export const UploadedOnTime = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const More = styled(ThreeDots)`
  color: ${({ theme }) => theme.neutralTextWeak};
  cursor: pointer;
  margin: 0 8px 0 8px;
`;

export const DatasetDimensionsCount = styled.div`
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 4px;
  align-items: center;
  display: flex;
  width: 28px;
  height: 24px;
  align-self: center;
  justify-content: center;
  font-size: 12px;
  color: ${({ theme }) => theme.neutralText};
  cursor: pointer;
`;

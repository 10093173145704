import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Label,
  TeamContainer,
  Title,
  WrapperContent,
  ImageOverlay,
  ImageContainer,
  TopWrapperContent,
  Dots,
  LabelsWrapper,
  Chip,
  ButtonsWrapper,
} from "./styles";

import { ProjectCardUsers } from "../../../ProjectCardUsers";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { DropDown } from "../DropDown";
import { selectTeam } from "../../../../store/slices/userManagement";
import { Team } from "../../../../models/Teams";
import { ReactComponent as TeamImage } from "./assets/teamImage.svg";
import { setSelectedTeam } from "../../../../store/slices/teams";
import { useSelector } from "react-redux";
import { getProfiles } from "../../../../store/selectors/profiles";
import { useAbilityContext } from "../../../../casl/context";

interface Props extends Team {
  color: string;
}

export const TeamItem: React.FC<Props> = ({
  members,
  name,
  id,
  datasets,
  color,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = useAbilityContext()
  const menuRef = useRef<any>(null);
  const profile = useSelector(getProfiles)
  const [menuOpen, setMenuOpen] = useState(false);
  useOutsideClick(menuRef, () => {
    setMenuOpen(false);
  });
  const role = members?.find(m => m.user.uid === profile.uid)?.role;

  return (
    <>
      <TeamContainer
        onClick={() => {
          navigate(`/management/teams/${id}`);
        }}
      >
        <ProjectCardUsers
          users={
            members?.length
              ? members
                  ?.filter((member) => member.role !== "admin")
                  ?.map((member) => ({
                    id: member?.user.uid,
                    name: member?.user.name,
                    avatar: member?.user?.profile?.avatar,
                  }))
              : []
          }
        />
        {!profile.isAdmin && (
          <ButtonsWrapper>
            <Chip $role={role}>
              {role}
            </Chip>
          </ButtonsWrapper>
        )}
        <ImageContainer $blur={!TeamImage} $fill={color}>
          <TeamImage />
        </ImageContainer>
        <ImageOverlay />
        <WrapperContent>
          <TopWrapperContent>
            <LabelsWrapper>
              {datasets?.map((dataset) => {
                return <Label>{dataset.name}</Label>;
              })}
            </LabelsWrapper>
            {ability.can('update', 'teams') && (
              <div ref={menuRef}>
                <Dots
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(selectTeam(id));
                    dispatch(setSelectedTeam(id));
                    setMenuOpen(!menuOpen);
                  }}
                />
                {menuOpen && (
                  <DropDown
                    id={id}
                    menuOpen={menuOpen}
                    onCloseMenu={() => setMenuOpen(false)}
                  />
                )}
              </div>
            )}
          </TopWrapperContent>
          <Title>{name}</Title>
        </WrapperContent>
      </TeamContainer>
    </>
  );
};

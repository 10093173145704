import { useCallback, useEffect, useMemo, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WidgetChartWrapper } from "../../Widgets";
import { KPISettingsWrapper } from "../../KPISettingsWrapper";
import Comments from "../../Comments/Comments";
import { checkFullProperty } from "../../../helpers/checkFullProperty";
import { getMaxHeight } from "../../../helpers/extractMaxHeight";
import { WIDGETS } from "../../Widgets/widgets";
import { LAYOUTS } from "../config";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getLayout,
  getPageSettings,
} from "../../../store/selectors/projects";
import { getIsPublicMode } from "../../../store/selectors/main";
import { getCustomeCommentsCursor } from "../../../store/selectors/comments";
import {
  setSelectWidgetId,
  toggleCommentsCursor,
} from "../../../store/slices/commnets";
import {
  requestPagePublicKpis,
  requestPageKpis,
} from "../../../store/slices/projectPages";
import { Column, Row } from "./components/LayoutBuildingBlocks";
import { Container } from "./styles";

const MemoizedWidgetChartWrapper = memo(WidgetChartWrapper);
const MemoizedKPISettingsWrapper = memo(KPISettingsWrapper);
const MemoizedComments = memo(Comments);

export const Layout = () => {
  const dispatch = useDispatch();
  const pageWidgetsState = useSelector(getCurrentPageWidgets);
  const currentPageState = useSelector(getPageSettings);
  const isPublic = useSelector(getIsPublicMode);
  const selectedLayout = useSelector(getLayout);
  const aiKpi = useSelector(getCurrentPageKpi);
  const isCustomeCursor = useSelector(getCustomeCommentsCursor);

  const [isFullTemplate, setIsFullTemplate] = useState(false);
  const [maxHeight, setMaxHeight] = useState("33.3333%");

  const pageWidgets = useMemo(() => pageWidgetsState, [pageWidgetsState]);
  const currentPageId = useMemo(
    () => currentPageState?.id,
    [currentPageState?.id]
  );

  const layout = useMemo(() => {
    let localLayout = LAYOUTS.find((r) => r.id === selectedLayout);
    if (!localLayout) {
      localLayout = LAYOUTS.find((r) => r.id === "3_8_c");
    }
    return localLayout;
  }, [selectedLayout]);

  useEffect(() => {
    const newFullTemplate = checkFullProperty(layout?.arranging);
    setIsFullTemplate(newFullTemplate);
    const maxHeight = getMaxHeight(layout?.arranging?.rows || []);
    setMaxHeight(maxHeight);
  }, [layout?.arranging]);

  const getKpis = useCallback(() => {
    if (currentPageId) {
      if (isPublic) {
        dispatch(
          requestPagePublicKpis({
            pageId: currentPageId,
            includeData: true,
          })
        );
      } else {
        dispatch(
          requestPageKpis({
            pageId: currentPageId,
            includeData: true,
          })
        );
      }
    }
  }, [currentPageId, isPublic, dispatch]);

  useEffect(() => {
    getKpis();
  }, [getKpis]);

  const handleSlelectWidget = useCallback(
    (id: string) => () => {
      if (isCustomeCursor) {
        dispatch(setSelectWidgetId(id));
        dispatch(toggleCommentsCursor(false));
      }
    },
    [isCustomeCursor, dispatch]
  );

  const renderLayout = useCallback(
    (arranging: any) => {
      return (
        <>
          {arranging?.rows?.map((row: any, rowIndex: number) => {
            if (layout?.id && ["3_8_c", "2_8_c"].includes(layout?.id)) {
              const processedRows = row.columns
                .map((column: any) => {
                  const isMainRow = column.scroll;
                  if (isMainRow) return column;

                  let widget =
                    column?.blockId &&
                    !!pageWidgets?.items?.find(
                      (w) =>
                        w.blockId && parseInt(w.blockId) === column?.blockId
                    );

                  if (column?.blockId === 1 && aiKpi?.count > 0) {
                    widget = true;
                  }

                  return widget ? column : null;
                })
                .filter(Boolean);

              if (processedRows.length === 0) return null;
            }

            const punchcardChart = pageWidgets?.items?.find(
              (w) => w.chartType === "punchcardChart"
            );

            const isPunchcardChart = row.columns?.find(
              (col: { blockId: number }) =>
                punchcardChart?.blockId &&
                col?.blockId === parseInt(punchcardChart?.blockId)
            );

            let height = row.height;
            let minHeight = "200px";

            if (!!isPunchcardChart && !row.isFullScreen && row.height) {
              if (maxHeight === "33.3333%") {
                minHeight = "350px";
              } else {
                height = maxHeight;
              }
            }

            return (
              <Row key={rowIndex} height={height} minHeight={minHeight}>
                {row.columns.map((column: any, colIndex: number) => {
                  const widget =
                    column?.blockId &&
                    pageWidgets?.items?.find(
                      (w) =>
                        w.blockId && parseInt(w.blockId) === column?.blockId
                    );
                  const ChartComponent =
                    widget?.chartType && WIDGETS[widget.chartType];

                  return (
                    <Column
                      key={colIndex}
                      size={column.size}
                      scroll={column.scroll}
                      full={
                        column.full &&
                        widget?.chartType === "mapChart" &&
                        widget.layout === "fullScreen"
                      }
                      hasPadding={column.hasPadding}
                      isWidget={column.rows?.length > 0}
                    >
                      <MemoizedComments
                        isStorytelling={widget?.chartType === "mapChart"}
                        id={widget?.id}
                      />

                      {column.widgets &&
                        column.blockId === 1 &&
                        aiKpi?.count > 0 && (
                          <>
                            <MemoizedComments
                              id={aiKpi.items?.[0]?.datasetId}
                            />
                            <MemoizedWidgetChartWrapper
                              storytelling={false}
                              map={false}
                              isFullTemplate={isFullTemplate}
                              onSelect={handleSlelectWidget(
                                aiKpi.items?.[0]?.datasetId
                              )}
                            >
                              <MemoizedKPISettingsWrapper
                                recommended={false}
                                storytelling={false}
                                kpis={aiKpi.items}
                              />
                            </MemoizedWidgetChartWrapper>
                          </>
                        )}

                      {widget?.chartType &&
                        column.widgets &&
                        widget &&
                        ChartComponent && (
                          <MemoizedWidgetChartWrapper
                            storytelling={false}
                            map={widget.chartType === "mapChart"}
                            isFullTemplate={
                              isFullTemplate && widget.chartType !== "mapChart"
                            }
                            onSelect={handleSlelectWidget(widget?.id)}
                          >
                            <ChartComponent
                              key={colIndex}
                              storytelling={false}
                              currentWidget={widget}
                              isFullScreen={column.full}
                            />
                          </MemoizedWidgetChartWrapper>
                        )}

                      {column.rows && renderLayout(column)}
                    </Column>
                  );
                })}
              </Row>
            );
          })}
        </>
      );
    },
    [
      pageWidgets,
      aiKpi,
      isFullTemplate,
      handleSlelectWidget,
      layout?.id,
      maxHeight,
    ]
  );

  return (
    <Container $isFullProperty={!checkFullProperty(layout)} key={currentPageId}>
      {layout && layout?.id && !!pageWidgets?.count
        ? renderLayout(layout?.arranging)
        : null}
    </Container>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h4`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const Description = styled.p`
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

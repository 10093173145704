import styled from "styled-components";

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

import { IProfile } from '../types/profile';

import { AbilityBuilder, Ability } from '@casl/ability';

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

export type AppAbility = Ability<[Actions, Subjects]> | undefined

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAbility = Ability as any

export type ACLObj = {
  action: Actions
  subject: string
}

export function defineRulesFor(user: IProfile, teamId: string) {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)
  const teamRole = user.teams.find(t => t.id === teamId)?.role;
  const teamRoles = ['admin','manager', 'editor', 'viewer'];
  const highestRole = user.teams.reduce((max, team) => Math.max(max, teamRoles.indexOf(team.role)), 0);
  
  if (user.isAdmin) {
    can('manage', 'all')
  } else if ((!teamId || teamRole === 'admin') && highestRole === 0) {
    can('manage', 'all')
    cannot('create', ['teams'])
    cannot('delete', ['teams', 'users'])
  } else if ((!teamId || teamRole === 'manager') && highestRole <= 1) {
    can('manage', 'all')
    can('manage', ['users'])
    cannot('create', ['teams'])
    cannot('delete', ['teams', 'users'])
  } else if (teamRole === 'editor' && highestRole <= 2) {
    can('read', 'all')
    cannot('read', ['users'])
    cannot('update', ['teamUsers', 'teams'])
  } else if (teamRole === 'viewer' && highestRole <= 3) {
    can('read', 'all')
    cannot('read', ['users'])
    cannot('update', ['teamUsers', 'teams'])
  }

  return rules
}

export const buildAbilityFor = (user: IProfile, teamId: string): AppAbility => {
  return new AppAbility(defineRulesFor(user, teamId), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type
  })
}
import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { setUserProfileById } from "../../../store/slices/profiles";

import { UserNameBox, UserName } from "../styles";

export const UserHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <UserNameBox
      onClick={() => {
        navigate(-1);
        dispatch(setUserProfileById(undefined));
      }}
    >
      <ChevronLeft />
      <UserName>Users</UserName>
    </UserNameBox>
  );
};

export const getPath = (url?: string): string => {
  if (!url) return;

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return url;
  }

  const fullPath = new URL(url).pathname;

  return fullPath.replace(/^\/(dev\/)?/, "");
};

import {
  AiKpiDto,
  CreateAndUpdateWidgetsDTO,
  CreateAreaChartWidgetDto,
  CreateBubbleWidgetDto,
  CreateLineChartWidgetDto,
  CreateSparkLineChartWidgetDto,
  CreateSparkAreaChartWidgetDto,
  CreateMatrixChartWidgetDto,
  CreatePolarAreaWidgetDto,
  CreatePunchcardWidgetDto,
  CreateRadarWidgetDto,
  CreateSankeyChartWidgetDto,
  CreateScatterplotWidgetDto,
  CreateRadialBarChartWidgetDto,
  ICreateKpi,
  IDeleteKpi,
  IUpdateKpi,
  KpiItemsDTO,
  QueryGetKpis,
} from "./../../models/Widgets/index";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ServerResponse } from "../../models";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  createAreaChartWidget,
  createBarChartWidget,
  createBubbleChartWidget,
  createKpi,
  createLineChartWidget,
  createSparkLineChartWidget,
  createSparkAreaChartWidget,
  createLollipopChartWidget,
  createMapChartWidget,
  createMapWidget,
  createMatrixChartWidget,
  createPolarAreaChartWidget,
  createPunchcardChartWidget,
  createRadarChartWidget,
  createSankeyChartWidget,
  createScatterplotChartWidget,
  createRadialBarChartWidget,
  deleteKpi,
  getAllKpis,
  getAllPublicWidgets,
  getAllWidgets,
  getPageBanner,
  getPageStories,
  getProjectSuggestions,
  getWidgetSuggestions,
  PageStoriesQuery,
  ProjectSuggestionsQuery,
  removeWidget,
  updateAreaChartWidget,
  updateBarChartWidget,
  updateBubbleChartWidget,
  updateKpi,
  updateLineChartWidget,
  updateSparkLineChartWidget,
  updateSparkAreaChartWidget,
  updateLollipopChartWidget,
  updateMapWidget,
  updateMatrixChartWidget,
  updatePolarAreaChartWidget,
  updatePunchcardChartWidget,
  updateRadarChartWidget,
  updateSankeyChartWidget,
  updateScatterplotChartWidget,
  updateRadialBarChartWidget,
  WidgetsSuggestionsQuery,
  DeleteWidgetQuery,
  getAllPublicKpis,
} from "../../api/widgets";
import {
  CreateBarWidgetDto,
  CreateLollipopChartWidgetDto,
  CreateMapWidgetDto,
  CreateWidgetDTO,
  CreateWidgetsDTO,
  QueryGetWidgets,
  UpdateSingleWidgetDTO,
  UpdateWidgetsDTO,
  WidgetsItemsDTO,
} from "../../models/Widgets";
import {
  setAiBannerImage,
  setAiKpis,
  setAiSuggestions,
  setStorytellingSuggestions,
} from "../slices/widgets";
import {
  setIsLoading,
  setKpiWidgets,
  setPageSections,
  setPageWidgets,
} from "../slices/projectPages";
import {
  getLineChartColors,
  getLineChartMarkers,
} from "../../components/Widgets/LineChart/utils/getLineChartMarkers";
import {
  getLollipopChartColors,
  getLollipopChartMarkers,
} from "../../components/Widgets/Lollipop/utils/getLollipopChartMarkers";
import {
  AreaChartDefaultMarkers,
  getAreaChartDefaultColors,
} from "../../components/Widgets/AreaChart/utils/getGroupData";
import { getBarChartFormatting } from "../../components/Widgets/GroupedBarChart/utils/getKeys";
import {
  createSection,
  getAllPublicSections,
  getAllSections,
  ICreateSection,
  ISection,
  IUpdateSection,
  QueryGetSections,
  removeSection,
  updateSection,
} from "../../api/sections";
import {
  getComparison,
  getPublicComparison,
  QueryGetComparison,
} from "../../api/comparison";
import {
  setComparisonKpis,
  setComparisonWidgets,
  setError,
} from "../slices/comparison";
import {
  getScatterPlotDefaultColors,
  ScatterPlotDefaultMarkers,
} from "../../components/Widgets/ScatterPlot/utils/getGroupData";
import {
  getRadarChartDefaultColors,
  getRadarChartDefaultMarkers,
} from "../../components/Widgets/RadarChart/utils/getGroupData";
import { getPolarAreaChartDefaultColors } from "../../components/Widgets/PolarAreaChart/utils/getGroupData";
import { getBubbleChartDefaultColors } from "../../components/Widgets/BubbleChart/utils/getGroupData";
import { getPageSettings } from "../selectors/projects";
import { PageSettingsDTO } from "../../models/Pages";
import { getSparkLineChartColors } from "../../components/Widgets/SparkLineChart/utils/getSparkLineChartColors";
import { getSparkAreaChartColors } from "../../components/Widgets/SparkAreaChart/utils/getSparkAreaChartColors";
import { getRadialBarDefaultColors } from "../../components/Widgets/RadialBarChart/utils/getGroupData";

export function* handleGetAllWidgets({
  payload,
}: PayloadAction<QueryGetWidgets>) {
  const { response }: ServerResponse = yield call(getAllWidgets, payload);
  if (response?.status === 200) {
    yield put(setPageWidgets({ items: [], count: 0 }));
    payload.callbacks?.onSuccess(response.data as WidgetsItemsDTO);
    yield put(setPageWidgets(response.data));
    yield put(setIsLoading(false));
  }
}

export function* handleGetAllPublicWidgets({
  payload,
}: PayloadAction<QueryGetWidgets>) {
  const { response }: ServerResponse = yield call(getAllPublicWidgets, payload);
  if (response?.status === 200) {
    yield put(setPageWidgets({ items: [], count: 0 }));
    payload.callbacks?.onSuccess(response.data as WidgetsItemsDTO);
    yield put(setPageWidgets(response.data));
    yield put(setIsLoading(false));
  }
}

export function* handleRequestComparisonWidgets(
  action: PayloadAction<QueryGetComparison>
) {
  const { response }: ServerResponse = yield call(
    getComparison,
    action.payload
  );
  try {
    if (response?.status !== 200) return;
    yield put(
      setComparisonWidgets({
        items: response?.data.items,
        count: response?.data.count,
      })
    );
    yield put(
      setComparisonKpis({
        items: response?.data.kpis,
        count: response?.data.count,
      })
    );
  } catch (e) {
    console.error(e);
    put(setError("error"));
  }
}

export function* handleRequestPublicComparisonWidgets(
  action: PayloadAction<QueryGetComparison>
) {
  const { response }: ServerResponse = yield call(
    getPublicComparison,
    action.payload
  );
  try {
    if (response?.status !== 200) return;
    yield put(
      setComparisonWidgets({
        items: response?.data.items,
        count: response?.data.count,
      })
    );
    yield put(
      setComparisonKpis({
        items: response?.data.kpis,
        count: response?.data.count,
      })
    );
  } catch (e) {
    console.error(e);
    put(setError("error"));
  }
}

export function* handleCreateMapWidget(
  action: PayloadAction<CreateMapWidgetDto>
) {
  yield call(createMapWidget, { ...action.payload, legend: false });
}

export function* handleCreateWidgets(action: PayloadAction<CreateWidgetsDTO>) {
  for (const barChart of action.payload.barChart) {
    yield call(createBarChartWidget, {
      ...barChart,
      orientation: barChart?.orientation || "vertical",
      formatting: barChart?.formatting?.length
        ? barChart?.formatting
        : getBarChartFormatting(barChart),
      colors: barChart?.colors || [],
      legend: barChart?.legend || true,
      tooltip: true,
    });
  }

  for (const lineChart of action.payload.lineChart) {
    yield call(createLineChartWidget, {
      ...lineChart,
      markers: lineChart?.markers?.length
        ? lineChart?.markers
        : getLineChartMarkers(lineChart),
      formatting: lineChart?.formatting?.length
        ? lineChart?.formatting
        : getLineChartColors(lineChart),
      legend: lineChart?.legend || true,
      tooltip: true,
    });
  }

  for (const sparkLineChart of action.payload.sparkLineChart) {
    yield call(createSparkLineChartWidget, {
      ...sparkLineChart,
      formatting: sparkLineChart?.formatting?.length
        ? sparkLineChart?.formatting
        : getSparkLineChartColors(sparkLineChart),
      legend: sparkLineChart?.legend || true,
      tooltip: true,
    });
  }

  for (const sparkAreaChart of action.payload.sparkAreaChart) {
    yield call(createSparkAreaChartWidget, {
      ...sparkAreaChart,
      formatting: sparkAreaChart?.formatting?.length
        ? sparkAreaChart?.formatting
        : getSparkAreaChartColors(sparkAreaChart),
      legend: sparkAreaChart?.legend || true,
      tooltip: true,
    });
  }

  for (const lollipopChart of action.payload.lollipopChart) {
    yield call(createLollipopChartWidget, {
      ...lollipopChart,
      orientation: lollipopChart?.orientation || "vertical",
      markers: getLollipopChartMarkers(lollipopChart),
      formatting: lollipopChart?.formatting?.length
        ? lollipopChart?.formatting
        : getLollipopChartColors(lollipopChart),
      legend: lollipopChart?.legend || true,
      tooltip: true,
    });
  }

  for (const sankeyChart of action.payload.sankeyChart) {
    yield call(createSankeyChartWidget, {
      ...sankeyChart,
      palette: {
        paletteId: sankeyChart?.palette?.paletteId || "SequentialColors1",
        autoRange: sankeyChart?.palette?.autoRange || true,
        range: sankeyChart?.palette?.range ?? [],
      },
      paletteId: sankeyChart?.paletteId || "SequentialColors1",
      legend: sankeyChart?.legend || true,
      tooltip: true,
    });
  }

  for (const mapChart of action.payload.mapChart) {
    yield call(createMapChartWidget, {
      ...mapChart,
      legend: mapChart?.legend || false,
      paletteId: mapChart?.paletteId || "SequentialColors1",
    });
  }

  for (const matrixChart of action.payload.matrixChart) {
    yield call(createMatrixChartWidget, {
      ...matrixChart,
      palette: {
        paletteId: matrixChart?.palette?.paletteId || "SequentialColors1",
        autoRange: matrixChart?.palette?.autoRange ?? true,
        range: matrixChart?.palette?.range ?? [],
      },
      legend: matrixChart?.legend ?? true,
      tooltip: true,
    });
  }

  for (const areaChart of action.payload.areaChart) {
    yield call(createAreaChartWidget, {
      ...areaChart,
      markers:
        areaChart.markers && areaChart.markers.length
          ? areaChart.markers
          : AreaChartDefaultMarkers(areaChart),
      formatting: areaChart?.formatting?.length
        ? areaChart.formatting
        : getAreaChartDefaultColors(areaChart),
      legend: areaChart?.legend || true,
      tooltip: true,
    });
  }

  for (const polarAreaChart of action.payload.polarAreaChart) {
    yield call(createPolarAreaChartWidget, {
      ...polarAreaChart,
      formatting: getPolarAreaChartDefaultColors(polarAreaChart),
      colors: polarAreaChart?.colors || [],
      legend: polarAreaChart?.legend || true,
      tooltip: true,
    });
  }

  for (let scatterplotChart of action.payload.scatterplotChart) {
    yield call(createScatterplotChartWidget, {
      ...scatterplotChart,
      formatting: scatterplotChart?.formatting?.length
        ? scatterplotChart.formatting
        : getScatterPlotDefaultColors(scatterplotChart),
      markers: scatterplotChart?.markers?.length
        ? scatterplotChart.markers
        : ScatterPlotDefaultMarkers(scatterplotChart),
      colors: [],
      legend: true,
      tooltip: true,
    });
  }

  for (const radarChart of action.payload.radarChart) {
    yield call(createRadarChartWidget, {
      ...radarChart,
      formatting: radarChart?.formatting?.length
        ? radarChart.formatting
        : getRadarChartDefaultColors(radarChart),
      markers: radarChart?.markers?.length
        ? radarChart.markers
        : getRadarChartDefaultMarkers(radarChart),
      colors: radarChart?.colors || [],
      legend: radarChart?.legend || true,
      tooltip: true,
    });
  }

  for (const bubbleChart of action.payload.bubbleChart) {
    yield call(createBubbleChartWidget, {
      ...bubbleChart,
      formatting: bubbleChart?.formatting?.length
        ? bubbleChart.formatting
        : getBubbleChartDefaultColors(bubbleChart),
      colors: bubbleChart?.colors || [],
      legend: bubbleChart?.legend || true,
      tooltip: true,
    });
  }

  for (const punchcardChart of action.payload.punchcardChart) {
    yield call(createPunchcardChartWidget, {
      ...punchcardChart,
      palette: {
        paletteId: punchcardChart?.palette?.paletteId || "SequentialColors1",
        autoRange: punchcardChart?.palette?.autoRange || true,
        range: punchcardChart?.palette?.range ?? [],
      },
      paletteId: punchcardChart?.paletteId || "SequentialColors1",
      legend: punchcardChart?.legend || true,
      tooltip: true,
    });
  }

  for (const radialBarChart of action.payload.radialBarChart) {
    yield call(createRadialBarChartWidget, {
      ...radialBarChart,
      orientation: radialBarChart?.orientation || "vertical",
      formatting:
        radialBarChart?.formatting || getRadialBarDefaultColors(radialBarChart),
      legend: radialBarChart?.legend || true,
      tooltip: true,
    });
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      query: action.payload.query,
      includeData: true,
    },
  } as PayloadAction<QueryGetWidgets>);

  const pageSettings: PageSettingsDTO = yield select(getPageSettings);

  if (pageSettings.id && pageSettings.dashType === "comparison") {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get("query");
    yield call(handleRequestComparisonWidgets, {
      payload: {
        pageId: pageSettings.id,
        measure: pageSettings.comparisonMeasure?.split("=")[0],
        ...(query && { query }),
      },
    } as PayloadAction<QueryGetComparison>);
  }
}

export function* handleRequestWidgetSuggestions({
  payload,
}: PayloadAction<WidgetsSuggestionsQuery>) {
  const { projectId, includeData, pageId, withStorytelling, callbacks } =
    payload;

  const { response }: ServerResponse = yield call(getWidgetSuggestions, {
    projectId,
    pageId,
    includeData,
    withStorytelling,
  });

  if (response?.status === 200) {
    yield put(setAiSuggestions(response.data.suggested_charts));
    yield put(setAiKpis(response.data.kpis));
    callbacks?.onSuccess(response.data);
  }
}

export function* handleRequestProjectSuggestions({
  payload,
}: PayloadAction<ProjectSuggestionsQuery>) {
  const { projectId, callbacks } = payload;
  const { response }: ServerResponse = yield call(getProjectSuggestions, {
    projectId,
    callbacks,
  });

  if (response?.status === 200) {
    // yield put(setAiSuggestions(response.data.suggested_charts));
    // callbacks?.onSuccess(response.data.suggested_charts);
  }
}

export function* handleRequestDeleteWidget({ payload }: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(removeWidget, payload);

  if (response?.status === 200) {
  }
}

export function* handleRequestDeleteWidgetWithCallback({
  payload,
}: PayloadAction<DeleteWidgetQuery>) {
  const { response }: ServerResponse = yield call(removeWidget, payload.id);

  if (response?.status === 200) {
    payload.callbacks?.onSuccess();
  }
}

export function* handleUpdateWidget(
  action: PayloadAction<UpdateSingleWidgetDTO>
) {
  const pageSettings: PageSettingsDTO = yield select(getPageSettings);
  if (action.payload.chartType === "lollipopChart") {
    const { response }: ServerResponse = yield call(
      updateLollipopChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "barChart") {
    const { response }: ServerResponse = yield call(
      updateBarChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "lineChart") {
    const { response }: ServerResponse = yield call(
      updateLineChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "sparkLineChart") {
    const { response }: ServerResponse = yield call(
      updateSparkLineChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "sparkAreaChart") {
    const { response }: ServerResponse = yield call(
      updateSparkAreaChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "sankeyChart") {
    const { response }: ServerResponse = yield call(
      updateSankeyChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "sankey") {
    const { response }: ServerResponse = yield call(
      updateSankeyChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "mapChart") {
    const { response }: ServerResponse = yield call(
      updateMapWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "matrix") {
    const { response }: ServerResponse = yield call(
      updateMatrixChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "areaChart") {
    const { response }: ServerResponse = yield call(
      updateAreaChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "scatterplot") {
    const { response }: ServerResponse = yield call(
      updateScatterplotChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "radar") {
    const { response }: ServerResponse = yield call(
      updateRadarChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "bubbleChart") {
    const { response }: ServerResponse = yield call(
      updateBubbleChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "punchcardChart") {
    const { response }: ServerResponse = yield call(
      updatePunchcardChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "polarAreaChart") {
    const { response }: ServerResponse = yield call(
      updatePolarAreaChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }
  if (action.payload.chartType === "radialBarChart") {
    const { response }: ServerResponse = yield call(
      updateRadialBarChartWidget,
      action.payload
    );
    if (response?.status === 200) {
      action.payload.callbacks?.onSuccess();
    }
  }

  if (pageSettings.id && pageSettings.dashType === "comparison") {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get("query");

    yield call(handleRequestComparisonWidgets, {
      payload: {
        pageId: pageSettings.id,
        measure: pageSettings.comparisonMeasure?.split("=")[0],
        ...(query && { query }),
      },
    } as PayloadAction<QueryGetComparison>);
  }
}

export function* handleUpdateWidgets(action: PayloadAction<UpdateWidgetsDTO>) {
  for (const barChart of action.payload.barChart) {
    yield call(updateBarChartWidget, barChart);
  }
  for (const lineChart of action.payload.lineChart) {
    yield call(updateLineChartWidget, lineChart);
  }
  for (const sparkLineChart of action.payload.sparkLineChart) {
    yield call(updateSparkLineChartWidget, sparkLineChart);
  }
  for (const sparkAreaChart of action.payload.sparkAreaChart) {
    yield call(updateSparkAreaChartWidget, sparkAreaChart);
  }
  for (const lollipopChart of action.payload.lollipopChart) {
    yield call(updateLollipopChartWidget, lollipopChart);
  }
  for (const sankeyChart of action.payload.sankeyChart) {
    yield call(updateSankeyChartWidget, sankeyChart);
  }
  for (const mapChart of action.payload.mapChart) {
    yield call(updateMapWidget, mapChart);
  }
  for (const matrixChart of action.payload.matrixChart) {
    yield call(updateMatrixChartWidget, matrixChart);
  }
  for (const areaChart of action.payload.areaChart) {
    yield call(updateAreaChartWidget, areaChart);
  }
  for (const polarAreaChart of action.payload.polarAreaChart) {
    yield call(updatePolarAreaChartWidget, polarAreaChart);
  }
  for (const punchcardChart of action.payload.punchcardChart) {
    yield call(updatePunchcardChartWidget, punchcardChart);
  }
  for (const scatterplotChart of action.payload.scatterplotChart) {
    yield call(updateScatterplotChartWidget, scatterplotChart);
  }
  for (const radarChart of action.payload.radarChart) {
    yield call(updateRadarChartWidget, radarChart);
  }
  for (const chart of action.payload.bubbleChart) {
    yield call(updateBubbleChartWidget, chart);
  }
  for (const chart of action.payload.radialBarChart) {
    yield call(updateRadialBarChartWidget, chart);
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);
}

export function* handleCreateAndUpdateWidgets(
  action: PayloadAction<CreateAndUpdateWidgetsDTO>
) {
  const { callbacks } = action.payload;
  for (const barChart of action.payload.barChart) {
    yield call(updateBarChartWidget, barChart);
  }
  for (const lineChart of action.payload.lineChart) {
    yield call(updateLineChartWidget, lineChart);
  }
  for (const sparkLineChart of action.payload.sparkLineChart) {
    yield call(updateSparkLineChartWidget, sparkLineChart);
  }
  for (const sparkAreaChart of action.payload.sparkAreaChart) {
    yield call(updateSparkAreaChartWidget, sparkAreaChart);
  }
  for (const lollipopChart of action.payload.lollipopChart) {
    yield call(updateLollipopChartWidget, lollipopChart);
  }
  for (const sankeyChart of action.payload.sankeyChart) {
    yield call(updateSankeyChartWidget, sankeyChart);
  }
  for (const mapChart of action.payload.mapChart) {
    yield call(updateMapWidget, mapChart);
  }
  for (const matrixChart of action.payload.matrixChart) {
    yield call(updateMatrixChartWidget, matrixChart);
  }
  for (const areaChart of action.payload.areaChart) {
    yield call(updateAreaChartWidget, areaChart);
  }
  for (const polarAreaChart of action.payload.polarAreaChart) {
    yield call(updatePolarAreaChartWidget, polarAreaChart);
  }
  for (const punchcardChart of action.payload.punchcardChart) {
    yield call(updatePunchcardChartWidget, punchcardChart);
  }
  for (const scatterplotChart of action.payload.scatterplotChart) {
    yield call(updateScatterplotChartWidget, scatterplotChart);
  }
  for (const radarChart of action.payload.radarChart) {
    yield call(updateRadarChartWidget, radarChart);
  }
  for (const chart of action.payload.bubbleChart) {
    yield call(updateBubbleChartWidget, chart);
  }
  for (const chart of action.payload.radialBarChart) {
    yield call(updateRadialBarChartWidget, chart);
  }

  for (const barChart of action.payload.createBarChart) {
    yield call(createBarChartWidget, {
      ...barChart,
      orientation: barChart?.orientation || "vertical",
      formatting: barChart?.formatting?.length
        ? barChart.formatting
        : getBarChartFormatting(barChart),
      colors: [],
      tooltip: true,
    });
  }

  for (const lineChart of action.payload.createLineChart) {
    yield call(createLineChartWidget, {
      ...lineChart,
      markers: lineChart.markers?.length
        ? lineChart.markers
        : getLineChartMarkers(lineChart),
      formatting: lineChart?.formatting?.length
        ? lineChart.formatting
        : getLineChartColors(lineChart),
      tooltip: true,
    });
  }

  for (const sparkLineChart of action.payload.createSparkLineChart) {
    yield call(createSparkLineChartWidget, {
      ...sparkLineChart,
      formatting: sparkLineChart?.formatting.length
        ? sparkLineChart?.formatting
        : getSparkLineChartColors(sparkLineChart),
      tooltip: true,
    });
  }

  for (const sparkAreaChart of action.payload.createSparkAreaChart) {
    yield call(createSparkAreaChartWidget, {
      ...sparkAreaChart,
      formatting: sparkAreaChart?.formatting.length
        ? sparkAreaChart?.formatting
        : getSparkAreaChartColors(sparkAreaChart),
      tooltip: true,
    });
  }

  for (const lollipopChart of action.payload.createLollipopChart) {
    yield call(createLollipopChartWidget, {
      ...lollipopChart,
      orientation: lollipopChart?.orientation || "vertical",
      markers: lollipopChart.markers?.length
        ? lollipopChart.markers
        : getLollipopChartMarkers(lollipopChart),
      formatting: lollipopChart?.formatting?.length
        ? lollipopChart.formatting
        : getLollipopChartColors(lollipopChart),
      tooltip: true,
    });
  }

  for (const sankeyChart of action.payload.createSankeyChart) {
    yield call(createSankeyChartWidget, {
      ...sankeyChart,
      palette: {
        paletteId: sankeyChart?.palette?.paletteId || "SequentialColors1",
        autoRange: sankeyChart?.palette?.autoRange || true,
        range: sankeyChart?.palette?.range ?? [],
      },
      tooltip: true,
    });
  }

  for (const mapChart of action.payload.createMapChart) {
    yield call(createMapChartWidget, { ...mapChart, legend: false });
  }

  for (const matrixChart of action.payload.createMatrixChart) {
    yield call(createMatrixChartWidget, {
      ...matrixChart,
      palette: {
        paletteId: matrixChart?.palette?.paletteId || "SequentialColors1",
        autoRange: matrixChart?.palette?.autoRange || true,
        range: matrixChart?.palette?.range ?? [],
      },
      tooltip: true,
    });
  }

  for (const areaChart of action.payload.createAreaChart) {
    yield call(createAreaChartWidget, {
      ...areaChart,
      markers: areaChart.markers?.length
        ? areaChart.markers
        : AreaChartDefaultMarkers(areaChart),
      formatting: areaChart?.formatting?.length
        ? areaChart.formatting
        : getAreaChartDefaultColors(areaChart),
      tooltip: true,
    });
  }

  for (const scatterplotChart of action.payload.createScatterplotChart) {
    yield call(createScatterplotChartWidget, {
      ...scatterplotChart,
      formatting: scatterplotChart?.formatting?.length
        ? scatterplotChart.formatting
        : getScatterPlotDefaultColors(scatterplotChart),
      markers: scatterplotChart?.markers?.length
        ? scatterplotChart.markers
        : ScatterPlotDefaultMarkers(scatterplotChart),
      colors: [],
      tooltip: true,
    });
  }

  for (const polarAreaChart of action.payload.createPolarAreaChart) {
    yield call(createPolarAreaChartWidget, {
      ...polarAreaChart,
      formatting: polarAreaChart?.formatting?.length
        ? polarAreaChart.formatting
        : getPolarAreaChartDefaultColors(polarAreaChart),
      colors: [],
      tooltip: true,
    });
  }

  for (const radarChart of action.payload.createRadarChart) {
    yield call(createRadarChartWidget, {
      ...radarChart,
      formatting: radarChart?.formatting?.length
        ? radarChart.formatting
        : getRadarChartDefaultColors(radarChart),
      colors: [],
      tooltip: true,
    });
  }

  for (const bubbleChart of action.payload.createBubbleChart) {
    yield call(createBubbleChartWidget, {
      ...bubbleChart,
      formatting: bubbleChart?.formatting?.length
        ? bubbleChart.formatting
        : getBubbleChartDefaultColors(bubbleChart),
      colors: [],
      tooltip: true,
    });
  }

  for (const punchcardChart of action.payload.createPunchcardChart) {
    yield call(createPunchcardChartWidget, {
      ...punchcardChart,
      palette: {
        paletteId: punchcardChart?.palette?.paletteId || "SequentialColors1",
        autoRange: punchcardChart?.palette?.autoRange || true,
        range: punchcardChart?.palette?.range ?? [],
      },
      paletteId: "SequentialColors1",
      colors: [],
      tooltip: true,
    });
  }

  for (const radialBarChart of action.payload.createRadialBarChart) {
    yield call(createRadialBarChartWidget, {
      ...radialBarChart,
      formatting: radialBarChart?.formatting?.length
        ? radialBarChart.formatting
        : getRadialBarDefaultColors(radialBarChart),
      tooltip: true,
    });
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);
  callbacks?.onSuccess();
}

export function* handleRequestCreateWidgetSection(
  action: PayloadAction<CreateWidgetDTO>
) {
  if (action.payload.chartType === "lollipopChart") {
    yield call(
      createLollipopChartWidget,
      action.payload as CreateLollipopChartWidgetDto
    );
  }
  if (action.payload.chartType === "barChart") {
    yield call(createBarChartWidget, action.payload as CreateBarWidgetDto);
  }
  if (action.payload.chartType === "lineChart") {
    yield call(
      createLineChartWidget,
      action.payload as CreateLineChartWidgetDto
    );
  }
  if (action.payload.chartType === "sparkLineChart") {
    yield call(
      createSparkLineChartWidget,
      action.payload as CreateSparkLineChartWidgetDto
    );
  }
  if (action.payload.chartType === "sparkAreaChart") {
    yield call(
      createSparkAreaChartWidget,
      action.payload as CreateSparkAreaChartWidgetDto
    );
  }
  if (action.payload.chartType === "sankeyChart") {
    yield call(
      createSankeyChartWidget,
      action.payload as CreateSankeyChartWidgetDto
    );
  }
  if (action.payload.chartType === "mapChart") {
    yield call(createMapWidget, {
      ...action.payload,
      legend: false,
    } as CreateMapWidgetDto);
  }
  if (action.payload.chartType === "matrix") {
    yield call(
      createMatrixChartWidget,
      action.payload as CreateMatrixChartWidgetDto
    );
  }
  if (action.payload.chartType === "areaChart") {
    yield call(
      createAreaChartWidget,
      action.payload as CreateAreaChartWidgetDto
    );
  }
  if (action.payload.chartType === "polarAreaChart") {
    yield call(
      createPolarAreaChartWidget,
      action.payload as CreatePolarAreaWidgetDto
    );
  }
  if (action.payload.chartType === "punchcardChart") {
    yield call(
      createPunchcardChartWidget,
      action.payload as CreatePunchcardWidgetDto
    );
  }
  if (action.payload.chartType === "radarChart") {
    yield call(createRadarChartWidget, action.payload as CreateRadarWidgetDto);
  }
  if (action.payload.chartType === "bubbleChart") {
    yield call(
      createBubbleChartWidget,
      action.payload as CreateBubbleWidgetDto
    );
  }
  if (action.payload.chartType === "scatterplotChart") {
    yield call(
      createScatterplotChartWidget,
      action.payload as unknown as CreateScatterplotWidgetDto
    );
  }
  if (action.payload.chartType === "radialBarChart") {
    yield call(
      createRadialBarChartWidget,
      action.payload as CreateRadialBarChartWidgetDto
    );
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);

  setTimeout(() => {
    action.payload.callbacks?.onSuccess();
  }, 1000);
}

export function* handleRequestPageStories({
  payload,
}: PayloadAction<PageStoriesQuery>) {
  const { projectId, pageId, filter, callbacks } = payload;

  const { response }: ServerResponse = yield call(getPageStories, {
    projectId,
    pageId,
    filter,
  });

  if (response?.status === 200) {
    yield put(setStorytellingSuggestions(response.data));
    callbacks?.onSuccess(response.data);
  }
}

export function* handleRequestPageBanner({
  payload,
}: PayloadAction<PageStoriesQuery>) {
  const { projectId, pageId, filter, callbacks } = payload;

  const { response }: ServerResponse = yield call(getPageBanner, {
    projectId,
    pageId,
    filter,
  });

  if (response?.status === 200) {
    yield put(setAiBannerImage(response.data.banner_image));
    callbacks?.onSuccess(response.data);
  }
}

export function* handlePageSections({
  payload,
}: PayloadAction<QueryGetSections>) {
  const { response }: ServerResponse = yield call(getAllSections, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(
      response.data as { items: ISection[]; count: number }
    );
    setPageSections(response?.data?.count);
  }
}

export function* handlePublicPageSections({
  payload,
}: PayloadAction<QueryGetSections>) {
  const { response }: ServerResponse = yield call(
    getAllPublicSections,
    payload
  );
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(
      response.data as { items: ISection[]; count: number }
    );
    setPageSections(response?.data?.count);
  }
}

export function* handleCreatePageSections({
  payload,
}: PayloadAction<ICreateSection>) {
  const { response }: ServerResponse = yield call(createSection, payload);
  if (response?.status === 201) {
    payload.callbacks?.onSuccess(response.data as ISection);
  }
}

export function* handleUpdatePageSections({
  payload,
}: PayloadAction<IUpdateSection>) {
  const { response }: ServerResponse = yield call(updateSection, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data as ISection);
  }
}

export function* handleRemovePageSections({
  payload,
}: PayloadAction<IUpdateSection>) {
  const { response }: ServerResponse = yield call(removeSection, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data as ISection);
  }
}

export function* handleGetAllKpis({ payload }: PayloadAction<QueryGetKpis>) {
  const { response }: ServerResponse = yield call(getAllKpis, payload);
  if (response?.status === 200) {
    yield put(setKpiWidgets(response.data));
    payload.callbacks?.onSuccess(response.data as KpiItemsDTO);
  }
}

export function* handleGetAllPublicKpis({
  payload,
}: PayloadAction<QueryGetKpis>) {
  const { response }: ServerResponse = yield call(getAllPublicKpis, payload);
  if (response?.status === 200) {
    yield put(setKpiWidgets(response.data));
    payload.callbacks?.onSuccess(response.data as KpiItemsDTO);
  }
}

export function* handleCreateKpis({ payload }: PayloadAction<ICreateKpi>) {
  const { response }: ServerResponse = yield call(createKpi, payload);
  const pageSettings: PageSettingsDTO = yield select(getPageSettings);
  if (response?.status === 201) {
    payload.callbacks?.onSuccess(response.data as AiKpiDto);

    if (pageSettings.id && pageSettings.dashType === "comparison") {
      const queryParams = new URLSearchParams(window.location.search);
      const query = queryParams.get("query");

      yield call(handleRequestComparisonWidgets, {
        payload: {
          pageId: pageSettings.id,
          measure: pageSettings.comparisonMeasure?.split("=")[0],
          ...(query && { query }),
        },
      } as PayloadAction<QueryGetComparison>);
    }
  }
}

export function* handleUpdateKpis({ payload }: PayloadAction<IUpdateKpi>) {
  const { response }: ServerResponse = yield call(updateKpi, payload);
  const pageSettings: PageSettingsDTO = yield select(getPageSettings);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data as AiKpiDto);

    if (pageSettings.id && pageSettings.dashType === "comparison") {
      const queryParams = new URLSearchParams(window.location.search);
      const query = queryParams.get("query");

      yield call(handleRequestComparisonWidgets, {
        payload: {
          pageId: pageSettings.id,
          measure: pageSettings.comparisonMeasure?.split("=")[0],
          ...(query && { query }),
        },
      } as PayloadAction<QueryGetComparison>);
    }
  }
}

export function* handleDeleteKpis({ payload }: PayloadAction<IDeleteKpi>) {
  const { response }: ServerResponse = yield call(deleteKpi, payload.id);
  const pageSettings: PageSettingsDTO = yield select(getPageSettings);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess();

    if (pageSettings.id && pageSettings.dashType === "comparison") {
      const queryParams = new URLSearchParams(window.location.search);
      const query = queryParams.get("query");

      yield call(handleRequestComparisonWidgets, {
        payload: {
          pageId: pageSettings.id,
          measure: pageSettings.comparisonMeasure?.split("=")[0],
          ...(query && { query }),
        },
      } as PayloadAction<QueryGetComparison>);
    }
  }
}

export function* watchWidgetsSaga() {
  yield takeLatest("currentProject/requestPageWidgets", handleGetAllWidgets);
  yield takeLatest(
    "currentProject/requestPublicPageWidgets",
    handleGetAllPublicWidgets
  );
  yield takeLatest("widgets/requestCreateMapWidget", handleCreateMapWidget);
  yield takeLatest("widgets/requestCreateWidgets", handleCreateWidgets);
  yield takeLatest(
    "widgets/requestWidgetsSuggestions",
    handleRequestWidgetSuggestions
  );
  yield takeLatest("widgets/requestPageStories", handleRequestPageStories);
  yield takeLatest("widgets/requestPageBanner", handleRequestPageBanner);
  yield takeLatest(
    "widgets/requestProjectSuggestions",
    handleRequestProjectSuggestions
  );
  yield takeLatest("widgets/requestDeleteWidget", handleRequestDeleteWidget);
  yield takeLatest(
    "widgets/requestDeleteWidgetWithCallback",
    handleRequestDeleteWidgetWithCallback
  );
  yield takeLatest(
    "widgets/requestCreateWidgetSection",
    handleRequestCreateWidgetSection
  );
  yield takeLatest("widgets/requestUpdateWidgets", handleUpdateWidgets);
  yield takeLatest(
    "widgets/requestCreateAndUpdateWidgets",
    handleCreateAndUpdateWidgets
  );

  yield takeLatest("currentProject/requestPageSections", handlePageSections);
  yield takeLatest(
    "currentProject/requestPublicPageSections",
    handlePublicPageSections
  );
  yield takeLatest(
    "currentProject/requestCreatePageSections",
    handleCreatePageSections
  );
  yield takeLatest(
    "currentProject/requestUpdatePageSections",
    handleUpdatePageSections
  );
  yield takeLatest(
    "currentProject/requestRemovePageSections",
    handleRemovePageSections
  );
  yield takeLatest("widgets/requestUpdateWidget", handleUpdateWidget);
  yield takeLatest("currentProject/requestPageKpis", handleGetAllKpis);
  yield takeLatest(
    "currentProject/requestPagePublicKpis",
    handleGetAllPublicKpis
  );
  yield takeLatest("currentProject/requestCreateKpis", handleCreateKpis);
  yield takeLatest("currentProject/requestUpdateKpis", handleUpdateKpis);
  yield takeLatest("currentProject/requestDeleteKpis", handleDeleteKpis);
  yield takeLatest(
    "comparisonSlice/requestComparisonWidgets",
    handleRequestComparisonWidgets
  );
  yield takeLatest(
    "comparisonSlice/requestPublicComparisonWidgets",
    handleRequestPublicComparisonWidgets
  );
}

import styled from "styled-components";

export const ProjectCardUsersWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 7px;
  height: 38px;
  width: 160px;
  display: flex;
  z-index: 1;
`;

export const UserIconWrapper = styled.div<{ $user?: string }>`
  position: relative;
  transition: 0.3s;
  margin-left: -16px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 8px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:first-child {
    margin-left: 0;
  }
`;

export const UserIcon = styled.div`
  background: ${({ theme }) => theme.neutralBackground};
  color: ${({ theme }) => theme.neutralText};
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 400;
`;

import React from "react";
import { useSelector } from "react-redux";
import { ProfileContactsSection } from "./ProfileContactsSection";
import { ProfileAboutSection } from "./ProfileAboutSection";
import { SecurityProfileSection } from "./SecurityProfileSection";
import { ProfileSideBarWrapper } from "../ProfileSideBar/styles";
import { getUserProfilesById } from "../../store/selectors/profiles";

export const ProfileUserSideBar = () => {
  const userProfile = useSelector(getUserProfilesById);

  return (
    <ProfileSideBarWrapper>
      <ProfileAboutSection profile={userProfile} />
      <ProfileContactsSection profile={userProfile} />
      <SecurityProfileSection />
    </ProfileSideBarWrapper>
  );
};

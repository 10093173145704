import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UsersHeader, TeamsHeader, TeamHeader, UserHeader } from "./components";

import { Container } from "./styles";

const UserManagementMenu = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const renderHeader = useMemo(() => {
    if (pathname === "/management/users") {
      return <UsersHeader />;
    }
    if (pathname === "/management/teams") {
      return <TeamsHeader />;
    }
    if (pathname.includes("/management/teams") && id) {
      return <TeamHeader />;
    }

    if (pathname.includes("/management/users") && id) {
      return <UserHeader />;
    }

    return null;
  }, [pathname, id]);

  return <Container>{renderHeader}</Container>;
};

export default UserManagementMenu;

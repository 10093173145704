import React, { useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { CheckCircle } from "react-bootstrap-icons";

import { ModalOverlay } from "../styles";
import {
  UpdateModalContentWrapper,
  UpdateModalHeaderWrapper,
  UpdateModalWrapper,
  AccountBlock,
  AccountFullName,
  UpdateTab,
  UpdateTabs,
  UpdateModalFooterWrapper,
} from "./styles";
import { AccountDto, ChangePasswordDTO } from "../../../models/Authentication";
import { getUser } from "../../../store/selectors/main";
import { Button } from "../../Button";
import { Essentials } from "./Essentials";
import { ContactDetails } from "./ContactDetails";
import { Security } from "./Security";
import { Notifications } from "./Notifications";
import { getProfiles } from "../../../store/selectors/profiles";
import { IProfile } from "../../../types/profile";
import {
  requestProfile,
  requestUpdateMeProfile,
} from "../../../store/slices/profiles";
import { getModalFirstItem } from "../../../store/selectors/modals";
import { AvatarCard } from "../../AvatarCard";
import { getPath } from "../../../helpers/getPath";

export const profileValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your First Name")
      .matches(/^[a-zA-Z]+$/, "First Name should only contain letters"),

    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Last Name")
      .matches(/^[a-zA-Z]+$/, "Last Name should only contain letters"),

    jobTitle: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Job Title")
      .matches(/^[a-zA-Z]+$/, "Job Title should only contain letters"),

    organization: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Organization"),

    location: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Location"),
  });
};

const getInitialTab = (firstItem: number): string => {
  switch (firstItem) {
    case 3:
      return "security";
    case 5:
      return "notifications";
    default:
      return "essentials";
  }
};

export const UpdateMeProfileModal = ({ onClose }: { onClose: () => void }) => {
  const user = useSelector(getUser);
  const profile = useSelector(getProfiles);
  const [account, setAccount] = useState<AccountDto | undefined>();
  const firstItem = useSelector(getModalFirstItem);
  const [selectedTab, setTab] = useState<string>(getInitialTab(firstItem));
  const [changePassword] = useState<ChangePasswordDTO>({
    currentPassword: "",
    newPassword: "",
  });
  const disabledPassChange = !(
    !!changePassword.currentPassword && !!changePassword.newPassword
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  const fullName = useMemo(() => {
    const name = [account?.firstName, account?.lastName]?.join(" ")?.trim();
    if (name?.length) {
      return name;
    }
    if (account?.name) {
      return account?.name;
    }
    return "Not set";
  }, [account?.firstName, account?.lastName, account?.name]);

  const initials = useMemo(() => {
    let initials = account?.email?.charAt(0);
    if (account?.firstName?.length || account?.lastName?.length) {
      initials =
        (account?.firstName?.charAt(0) || "") +
        (account?.lastName?.charAt(0) || "");
    }
    if (account?.name?.length) {
      initials = account?.name?.charAt(0);
    }
    return initials?.toUpperCase();
  }, [account?.email, account?.firstName, account?.lastName, account?.name]);

  const handleSubmitForm = ({ avatar, ...values }: IProfile) => {
    const avatarUrl = getPath(avatar);
    dispatch(
      requestUpdateMeProfile({
        ...values,
        avatar: avatarUrl,
        callbacks: {
          onSuccess: () => {
            dispatch(requestProfile());
            onClose();
          },
        },
      })
    );
  };

  return (
    <ModalOverlay onClick={onClose}>
      <UpdateModalWrapper onClick={(e) => e.stopPropagation()}>
        <UpdateModalHeaderWrapper>
          <AccountBlock>
            <AvatarCard avatar={profile?.avatar} initials={initials} />
            <AccountFullName>{fullName}</AccountFullName>
          </AccountBlock>
          <UpdateTabs>
            <UpdateTab
              $selected={selectedTab === "essentials"}
              onClick={() => setTab("essentials")}
            >
              Essentials
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "contact_details"}
              onClick={() => setTab("contact_details")}
            >
              Contact details
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "security"}
              onClick={() => setTab("security")}
            >
              Security
            </UpdateTab>
            {/* <UpdateTab
              $selected={selectedTab === "permissions"}
              onClick={() => setTab("permissions")}
            >
              Permissions
            </UpdateTab> */}
            <UpdateTab
              $selected={selectedTab === "notifications"}
              onClick={() => setTab("notifications")}
            >
              Notifications
            </UpdateTab>
          </UpdateTabs>
        </UpdateModalHeaderWrapper>
        <Formik
          initialValues={profile}
          validationSchema={profileValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            setValues,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <>
                <UpdateModalContentWrapper>
                  <Form onSubmit={handleSubmit}>
                    {selectedTab === "essentials" && (
                      <Essentials
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        initials={initials}
                        avatar={profile?.avatar}
                      />
                    )}
                    {selectedTab === "contact_details" && (
                      <ContactDetails
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {selectedTab === "security" && (
                      <Security
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {/* {selectedTab === "permissions" && (
                    <Permissions
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )} */}
                    {selectedTab === "notifications" && (
                      <Notifications
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setValues={setValues}
                      />
                    )}
                  </Form>
                </UpdateModalContentWrapper>
                <UpdateModalFooterWrapper>
                  <Button
                    name="Cancel"
                    onClick={onClose}
                    variant="neutral"
                    size="medium"
                  />
                  {selectedTab === "security" ? (
                    <Button
                      name="Change Password"
                      onClick={
                        () => {}
                        // dispatch(
                        //   requestChangePassword({
                        //     currentPassword: changePassword.currentPassword,
                        //     newPassword: changePassword.newPassword,
                        //     callbacks: {
                        //       onSuccess: (resp) => {
                        //         setChangePass(false);
                        //       },
                        //       onError() {
                        //         setChangePass(true);
                        //       },
                        //     },
                        //   })
                        // )
                      }
                      disabled={disabledPassChange}
                      variant={disabledPassChange ? "disabled" : "secondary"}
                      size="medium"
                      icon={<CheckCircle />}
                    />
                  ) : (
                    <Button
                      name="Update"
                      onClick={() => {
                        handleSubmitForm(values);
                      }}
                      disabled={_.isEqual(values, profile)}
                      variant={
                        _.isEqual(values, profile) ? "disabled" : "secondary"
                      }
                      size="medium"
                      icon={<CheckCircle />}
                    />
                  )}
                </UpdateModalFooterWrapper>
              </>
            );
          }}
        </Formik>
      </UpdateModalWrapper>
    </ModalOverlay>
  );
};

import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";
import { generateQueryString } from "../helpers/queryGenerator";
import {
  QueryGetUsers,
  QuerySendUserInvite,
  IDeleteUsersBulk,
} from "../models/Users";
import { DELETE_USERS_BULK, GET_USERS, SEND_USER_INVITE } from "./constants";

export const getAllUsers = async ({
  emails,
  teamIds,
  ...query
}: QueryGetUsers) => {
  const queryParams = generateQueryString<QueryGetUsers>({
    ...query,
    limit: query?.limit || 20,
  });

  return axios
    .get(`${GET_USERS}${queryParams}`, {
      params: {
        ...(!!emails?.length && { emails }),
        ...(!!teamIds?.length && { teamIds }),
      },
      paramsSerializer: (params) => {
        return Object.entries(params)
          .flatMap(([key, value]) =>
            value.map(
              (v: string) =>
                `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`
            )
          )
          .join("&");
      },
    })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const sendUserInvite = async (query: QuerySendUserInvite) => {
  return axios
    .post(`${SEND_USER_INVITE}`, query)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteUsersBulk = async (data: IDeleteUsersBulk) => {
  return axios
    .delete(`${DELETE_USERS_BULK}`, { data })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

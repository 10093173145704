import React, { useRef, useState, useEffect } from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  Buttons,
  Container,
  DownIcon,
  DropDownMenuItem,
  DropDownMenuList,
  DropDownSelect,
  SelectContainer,
  SelectedValue,
  SelectInput,
  SelectChip,
  XButton,
  ContentWrapper,
  Avatar,
  DropDownText,
  AvatarImage,
  VerticalLine,
  HiddenSpan,
  ChipVerticalLine,
} from "./styles";
import { ISelect } from "./types";
import { getTwoInitials } from "../../../helpers/getInitials";
import { toast } from "react-toastify";

type Props = {
  options: ISelect[];
  value: string[];
  hiddenOptions?: ISelect[];
  hiddenMessage?: string;
  placeholder?: string;
  limit?: number;
  limitMessage?: string;
  onSelect?: (values: ISelect[]) => void;
  disabled?: boolean;
  disableDelete?: boolean;
  disableRightIcons?: boolean;
  disableAddByEnter?: boolean;
  closeOnSelect?: boolean;
  errors?: string[];
};

export const MultiSelect: React.FC<Props> = ({
  options,
  value,
  placeholder,
  hiddenOptions,
  hiddenMessage,
  onSelect,
  disabled,
  disableDelete = false,
  disableRightIcons = false,
  disableAddByEnter = false,
  closeOnSelect = false,
  limit = 30,
  limitMessage = `You reached the limit of ${limit} selected values!`,
  errors = [],
}) => {
  const [selectedValues, setSelectedValues] = useState<ISelect[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null)
  const ref = useRef<HTMLDivElement | null>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  useOnClickOutside([ref], () => setActiveDropDown(false));

  useEffect(() => {
    const customValues = value
      .filter((v) => !options.some((opt) => opt.value === v))
      .map((v) => ({ value: v, option: v }));

    const initialSelected = [
      ...options.filter((opt) => value.includes(opt.value)),
      ...customValues,
    ];

    setSelectedValues(initialSelected);
  }, [value, options]);

  const handleSelectItem = (item: ISelect) => {
    if (selectedValues.length >= limit) {
      toast.warn(limitMessage)
      return;
    }
    if (!selectedValues.find((v) => v.value === item.value)) {
      const newSelected = [...selectedValues, item];
      setSelectedValues(newSelected);
      onSelect?.(newSelected);
    }
    setSearchTerm("");
    if (closeOnSelect) {
      setActiveDropDown(false);
    }
  };

  const handleRemoveChip = (value: string) => {
    const newSelected = selectedValues.filter((v) => v.value !== value);
    setSelectedValues(newSelected);
    onSelect?.(newSelected);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disableAddByEnter) {
      return;
    }
    if (e.key === "Enter" && searchTerm) {
      const fromOptions = options?.find((val) => val?.value?.toLocaleLowerCase() === searchTerm.toLocaleLowerCase() || val?.option?.toLocaleLowerCase() === searchTerm.toLocaleLowerCase())
      if (hiddenOptions?.find((val) => val?.value?.toLocaleLowerCase() === searchTerm.toLocaleLowerCase() || val?.option?.toLocaleLowerCase() === searchTerm.toLocaleLowerCase())) {
        return toast.error(hiddenMessage || 'User already in the team')
      } else if (fromOptions) {
        handleSelectItem(fromOptions);
      } else {
        const newOption = { option: searchTerm, value: searchTerm };
        handleSelectItem(newOption);
      }

      handleInputChange('')
    }
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputChange = (e: string) => {
    setActiveDropDown(true)
    if (!spanRef?.current || !inputRef?.current || !ref?.current) {
      return;
    }
    if (e.length > searchTerm.length && spanRef?.current?.offsetWidth + 25 >= ref?.current?.offsetWidth - 50) {
      toast.warn('Input too long!')
      return;
    }
    setSearchTerm(e);

    const newWidth = spanRef.current.offsetWidth + 20;
    inputRef.current.style.width = `${newWidth}px`;
  };

  const filteredOptions = options.filter((opt) => {
    const matchesSearch = opt?.option?.toLowerCase()?.includes(searchTerm?.toLowerCase());
    const matchesSearchByLabel = opt?.label && opt?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase());
    const isSelected = selectedValues.find((v) => v.value === opt.value);

    return (matchesSearch || matchesSearchByLabel) && (disableDelete || !isSelected);
  });

  return (
    <Container ref={ref}>
      <SelectContainer $opened={!!activeDropDown} $disabled={!!disabled} $isSelected={!!selectedValues.length} onClick={() => { setActiveDropDown((prev) => !prev); handleFocus() }}>
        <ContentWrapper>
          <SelectedValue $width={ref.current?.offsetWidth}>
            {selectedValues.map((item) => (
              <SelectChip $error={!!errors?.length && !errors?.some((error) => item?.value?.includes(error))} key={item?.value}>
                {item.option}
                <ChipVerticalLine />
                <XButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleRemoveChip(item.value)
                  }}
                />
              </SelectChip>
            ))}
            <SelectInput
              ref={inputRef}
              value={searchTerm}
              onChange={(e) => handleInputChange(e?.target?.value)}
              onKeyDown={handleKeyDown}
              placeholder={selectedValues.length ? '' : placeholder || "Search or add..."}
            />
            <HiddenSpan ref={spanRef}>{searchTerm || " "}</HiddenSpan>
          </SelectedValue>
        </ContentWrapper>
        {!disableRightIcons ? (
          <Buttons>
            <VerticalLine $opened={!!activeDropDown} />
            <DownIcon $close={activeDropDown} />
          </Buttons>
        ) : ''}
      </SelectContainer>

      {!!activeDropDown && filteredOptions.length ? (
        <DropDownSelect $width={ref?.current?.offsetWidth || undefined}>
          <DropDownMenuList>
            {filteredOptions.map((item) => {
              const isSelected = !!selectedValues.some(
                (selected) => selected.value === item.value
              );
              return (
                <DropDownMenuItem
                  key={item.value}
                  onClick={() => { isSelected ? handleRemoveChip(item.value) : handleSelectItem(item); handleInputChange('') }}
                  $selected={isSelected}
                >
                  {item?.avatar && (item?.icon ? <AvatarImage src={item.icon} alt="" /> : <Avatar>{getTwoInitials(item.option)}</Avatar>)}
                  <DropDownText>
                    <span>{item.option}</span>
                    {item?.label && <span>{item?.label}</span>}
                  </DropDownText>
                </DropDownMenuItem>
              )
            })}
          </DropDownMenuList>
        </DropDownSelect>
      ) : ''}
    </Container>
  );
};

export const generateQueryString = <T extends Record<string, any>>(
  obj: Partial<T>
): string => {
  return `?${Object.entries(obj)
    .filter(
      ([, value]) => value !== undefined && value !== null && value !== ""
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
    )
    .join("&")}`;
};

import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  QueryAddUserToTeam,
  QueryCreateTeam,
  QueryDeleteTeam,
  QueryDeleteTeamUser,
  QueryDeleteTeamUsersBulk,
  QueryEditTeam,
  QueryEditTeamUser,
  QueryGetTeams,
  QueryGetTeamUsers,
} from "../../models/Teams";
import { ServerResponse } from "../../models";
import {
  addUserToTeam,
  createTeam,
  deleteTeam,
  deleteTeamUser,
  deleteTeamUsersBulk,
  editTeam,
  editTeamUsersList,
  getAllTeams,
  getTeamUsersList,
} from "../../api/teams";
import {
  requestAllTeams,
  requestTeamUsersList,
  setAllTeams,
  setTeamUsersList,
} from "../slices/teams";
import { toast } from "react-toastify";
import { requestRefreshToken } from "../../api/auth";
import { store } from "..";
import { setSignInSuccess } from "../slices/auth";

export function* handleGetAllTeams({ payload }: PayloadAction<QueryGetTeams>) {
  const { callbacks, ...params } = payload;

  const { response }: ServerResponse = yield call(getAllTeams, params);

  if (response?.status === 200) {
    yield put(setAllTeams(response?.data?.items));
    callbacks?.onSuccess(response.data);
  }
}

export function* handleCreateTeam({ payload }: PayloadAction<QueryCreateTeam>) {
  const { response }: ServerResponse = yield call(createTeam, payload);
  if (response?.status === 201) {
    yield put(requestAllTeams({}));
    const refreshToken = store.getState()?.auth?.user?.refreshToken;
    const newData = yield call(requestRefreshToken, refreshToken);
    yield put(setSignInSuccess(newData.response.data));
    payload?.callbacks?.onSuccess(response.data);
  }
}

export function* handleEditTeam({ payload }: PayloadAction<QueryEditTeam>) {
  const { response }: ServerResponse = yield call(editTeam, payload);
  if (response?.status === 200) {
    yield put(requestAllTeams({}));
    payload?.callbacks?.onSuccess(response.data);
  }
}

export function* handleAddUserToTeam({
  payload,
}: PayloadAction<QueryAddUserToTeam>) {
  const { response }: ServerResponse = yield call(addUserToTeam, payload);
  if (response?.status === 201) {
    yield put(requestTeamUsersList({ id: payload.id }));
    payload?.callbacks?.onSuccess(response.data);
    toast.success("Successfully!");
  }
}

export function* handleGetTeamUsersList({
  payload,
}: PayloadAction<QueryGetTeamUsers>) {
  const { callbacks, ...params } = payload;

  const { response }: ServerResponse = yield call(getTeamUsersList, params);
  if (response?.status === 200) {
    yield put(setTeamUsersList(response.data.items));
    yield put(requestAllTeams({}));
    callbacks?.onSuccess(response.data);
  }
}

export function* handleEditTeamUser({
  payload,
}: PayloadAction<QueryEditTeamUser>) {
  const { response }: ServerResponse = yield call(editTeamUsersList, payload);
  toast.update(0, {
    render:
      response && response?.status < 300
        ? "Team user updated successfully"
        : "An error occurred",
    type: response && response?.status < 300 ? "success" : "error",
    isLoading: false,
    autoClose: 800,
  });
  if (response?.status === 200) {
    payload?.callbacks?.onSuccess(response.data);
  }
}

export function* handleDeleteTeamUser({
  payload,
}: PayloadAction<QueryDeleteTeamUser>) {
  const { response }: ServerResponse = yield call(deleteTeamUser, payload);
  if (response?.status === 200) {
    try {
      yield put(requestTeamUsersList({ id: payload.id, limit: 30 }));
      payload?.callbacks?.onSuccess(response.data);

      toast.success(`User deleted successfully!`);
    } catch (e) {}
  }
}

export function* handleDeleteTeam({ payload }: PayloadAction<QueryDeleteTeam>) {
  const { response }: ServerResponse = yield call(deleteTeam, payload);
  if (response?.status === 200) {
    try {
      yield put(requestAllTeams({}));
      const refreshToken = store.getState()?.auth?.user?.refreshToken;
      const newData = yield call(requestRefreshToken, refreshToken);
      yield put(setSignInSuccess(newData.response.data));
      payload?.callbacks?.onSuccess(response.data);

      toast.success(`Team deleted successfully!`);
    } catch (e) {}
  }
}

export function* handleDeleteTeamUsersBulk({
  payload,
}: PayloadAction<QueryDeleteTeamUsersBulk>) {
  const usersIdsLength = payload.teamUserIds.length;
  const { response }: ServerResponse = yield call(deleteTeamUsersBulk, payload);
  if (response?.status === 200) {
    try {
      yield put(requestTeamUsersList({ id: payload.teamId, limit: 30 }));
      payload?.callbacks?.onSuccess(response.data);

      toast.success(
        `User${usersIdsLength > 1 ? "s" : ""} deleted successfully!`
      );
    } catch (e) {}
  }
}

export function* watchTeamsSaga() {
  yield takeLatest("teams/requestAllTeams", handleGetAllTeams);
  yield takeLatest("teams/requestCreateTeam", handleCreateTeam);
  yield takeLatest("teams/requestEditTeam", handleEditTeam);
  yield takeLatest("teams/requestTeamUsersList", handleGetTeamUsersList);
  yield takeLatest("teams/requestEditTeamUser", handleEditTeamUser);
  yield takeLatest("teams/requestDeleteTeamUser", handleDeleteTeamUser);
  yield takeLatest("teams/requestDeleteTeam", handleDeleteTeam);
  yield takeLatest("teams/requestAddUserToTeam", handleAddUserToTeam);
  yield takeLatest("teams/requestDeleteTeamUsers", handleDeleteTeamUsersBulk);
}

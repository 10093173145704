import { Team, TeamMemberTeamList } from "../models/Teams";
import {IUserTeams} from "../models/Users";

export const hasLastManagerById = (
  members: TeamMemberTeamList[],
  id: string
): boolean => {
  const managers =
    members?.filter((member) => member?.role === "manager") || [];
  return managers?.length === 1 && managers?.[0]?.id === id;
};

export const getLastTeamsManagersById = (
  teams: Team[],
  userIds: string[]
): string[] => {
  return userIds.filter((userId) => {
    const userTeams = teams.filter((team) =>
      team.members.some(
        (member) => member.role === "manager" && member.user?.uid === userId
      )
    );

    if (userTeams.length === 0) return false;

    return userTeams.some((team) => {
      const managers = team.members.filter(
        (member) => member.role === "manager"
      );

      const remainingManagers = managers.filter(
        (manager) => !userIds.includes(manager.user?.uid)
      );

      return remainingManagers.length === 0;
    });
  });
};

export const getTeamsWithLastMembers = (
  userTeams: IUserTeams[],
  teams: Team[],
  lastTeamManagers: string[]
) =>
   userTeams?.filter((team) => {
    const fullTeam = teams.find((t) => t.id === team.id);

    if (!fullTeam) return false;

    const managers = fullTeam?.members?.filter(
      (member) => member.role === "manager"
    );

    const remainingManagers = managers.filter(
      (manager) => !lastTeamManagers.includes(manager.user?.uid)
    );

    return remainingManagers.length === 0;
  });

export const getTeamMembersByTeamId = (teams: Team[], teamId: string) => {
  return teams?.find((team) => team.id === teamId)?.members || [];
};

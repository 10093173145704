import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KPIProps } from "../../components/KPIComponent";
import { ApparitionConfig, WidgetItem } from "../../models/Widgets";

export type PageContentGeneratorItem = {
  sectionType: string;
  heading: string;
  highlights: string;
  blockId?: string;
  description: string;
  insights: string;
  apparitionConfig: ApparitionConfig;
  textAlignment: "left" | "center" | "right";
  image: string;
  backgroundColor: string;
  id: any;
  widget: string;
  gradient: string;
  rgbaColor: string;
  // | {
  //     r: number;
  //     g: number;
  //     b: number;
  //     a: number;
  //   }
  // | undefined;
};
export type PageContentGeneratorItemType =
  | PageContentGeneratorItem
  | WidgetContentItem;
export type PageContentGeneratorType = {
  items: (PageContentGeneratorItem | WidgetContentItem)[];
};

export type IPageHeader = {
  title?: string;
  align?: string;
  backgroundColor?: string;
  description?: string;
  disableImage?: boolean;
  insights?: string;
  textColor?: string;
  image?: string;
  backImage?: string;
  gradient?: string;
  rgbaColor?: string;
};

export interface WidgetContentItem extends WidgetItem {
  sectionType: string;
  apparitionConfig?: ApparitionConfig;
  description: string;
  insights: string;
  kpi?: KPIProps;
}

const pageInitialState: PageContentGeneratorItemType = {
  blockId: "1",
  sectionType: "textImage",
  insights: "string",
  heading: "Add a title to this story",
  highlights:
    "Please add a subtitle here that provides a brief overview of the content presented on this page below.",
  description:
    "Here, you'll find additional information that may not be crucial but could still be interesting or helpful.",
  textAlignment: "left",
  apparitionConfig: {
    textAlignment: "center",
    background: "",
    border: "",
    descriptionDisabled: false,
    insightsDisabled: false,
  },
  image: "",
  backgroundColor: "",
  id: 1,
  widget: "",
  gradient: "linear-gradient(90deg, #0052D4 0%, #4364F7 50%, #6FB1FC 100%)",
  rgbaColor: "",
};

const initialState: PageContentGeneratorType = {
  items: [],
};

const pageContentGenerator = createSlice({
  name: "pageContentGenerator",
  initialState,
  reducers: {
    addSection: (
      state: PageContentGeneratorType
    ): PageContentGeneratorType => ({
      ...state,
      items: [
        { ...pageInitialState, id: state.items.length + 1 },
        ...state.items,
      ],
    }),

    addWidgets: (
      state: PageContentGeneratorType,
      action: PayloadAction<PageContentGeneratorItemType[]>
    ): PageContentGeneratorType => ({
      ...state,
      items: [...action.payload],
    }),

    addWidgetSection: (
      state: PageContentGeneratorType,
      action: PayloadAction<PageContentGeneratorItemType>
    ): PageContentGeneratorType => ({
      ...state,
      items: [action.payload, ...state.items],
    }),

    setCurrentSectionById: (
      state: PageContentGeneratorType,
      action: PayloadAction<{ id: string }>
    ) => {},

    editSection: (
      state: PageContentGeneratorType,
      action: PayloadAction<PageContentGeneratorItemType>
    ): PageContentGeneratorType => {
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    },
  },
});
export const { addSection, editSection, addWidgetSection, addWidgets } =
  pageContentGenerator.actions;
export default pageContentGenerator.reducer;

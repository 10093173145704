import React from "react";
import { Envelope, Phone, Telephone } from "react-bootstrap-icons";
import { IProfile } from "../../types/profile";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
} from "../ProfileSideBar/styles";

type Props = {
  profile: IProfile;
};

export const ProfileContactsSection = ({ profile }: Props) => {
  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Contacts</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Envelope />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>Email</ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription $noData={!profile?.email}>
            {profile?.email || "No Data"}
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Telephone />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Office Phone
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription $noData={!profile?.officePhone}>
            {profile?.officePhone || "No Data"}
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Phone />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Cell Phone
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription $noData={!profile?.phoneNumber}>
            {profile?.phoneNumber || "No Data"}
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};

import styled from "styled-components";

const sizeContainer = {
  xl: "48px",
  lg: "48px",
  md: "40px",
  sm: "32px",
  xs: "24px",
  xxs: "20px",
};

const borderRadius = {
  xl: "8px",
  lg: "8px",
  md: "6px",
  sm: "4px",
  xs: "3px",
  xxs: "2px",
};

const sizeInitials = {
  xl: "20px",
  lg: "20px",
  md: "14px",
  sm: "12px",
  xs: "11px",
  xxs: "10px",
};

export const AvatarCardContainer = styled.div<{
  $size: "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
  $hasOnClick?: boolean;
}>`
  width: ${({ $size }) => sizeContainer[$size]};
  height: ${({ $size }) => sizeContainer[$size]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ $size }) => borderRadius[$size]};
  overflow: hidden;
  position: relative;
  cursor: ${({ $hasOnClick }) => $hasOnClick && "pointer"};
  flex-shrink: 0;

  &:hover {
    border: ${({ theme, $hasOnClick = false }) =>
      $hasOnClick && `1px solid ${theme.primaryBorder}`};
  }
`;

export const AvatarImg = styled.img<{
  $size: "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
}>`
  width: ${({ $size }) => sizeContainer[$size]};
  height: ${({ $size }) => sizeContainer[$size]};
  border-radius: ${({ $size }) => borderRadius[$size]};
  position: absolute;
  z-index: 0;
`;

export const AvatarInitials = styled.p<{
  $size: "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
}>`
  color: ${({ theme }) => theme.neutralText};
  font-size: ${({ $size }) => sizeInitials[$size]};
  z-index: 1;
`;

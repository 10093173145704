import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PlusLg } from "react-bootstrap-icons";
import UsersList from "../../../../components/UserManagementComponents/Users/UsersList";
import { ReactComponent as NoDataImage } from "./assets/noDataImage.svg";
import {
  requestTeamUsersList,
  setTeamUsersList,
} from "../../../../store/slices/teams";
import { getTeamUsers } from "../../../../store/selectors/teams";
import { EmptyPage } from "../../../../components/EmptyPage";

export const TeamsById = () => {
  const { id } = useParams();
  const users = useSelector(getTeamUsers);
  const dispatch = useDispatch();
  const [usersCount, setUsersCount] = useState(0);
  const [filters, setFilters] = useState<Record<string, any>>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFilters = useMemo(() => filters, [JSON.stringify(filters)]);

  useEffect(() => {
    if (!users?.length) {
      dispatch(
        requestTeamUsersList({
          id: id as string,
          limit: 20,
          callbacks: {
            onSuccess: (data) => {
              setUsersCount(data?.count || 0);
            },
          },
        })
      );
    }
    return () => {
      dispatch(setTeamUsersList([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!Object.values(memoizedFilters).some(Boolean)) {
      dispatch(
        requestTeamUsersList({
          id: id as string,
          limit: 20,
          ...memoizedFilters,
          callbacks: {
            onSuccess: (data) => {
              setUsersCount(data?.count || 0);
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedFilters, dispatch]);

  return (
    <>
      {!users?.length ? (
        <EmptyPage
          Icon={NoDataImage}
          title="No users were discovered"
          subTitle={`Feel free to invite new people into your space by clicking the "Invite People" button below! `}
          button={{
            name: "Invite People",
            onClick: () => {},
            icon: <PlusLg />,
          }}
        />
      ) : (
        <>
          <UsersList
            users={users}
            usersCount={usersCount}
            isTeamTable
            filters={filters}
            onChangeFilters={(value) =>
              setFilters((prevState) => ({ ...prevState, ...value }))
            }
          />
        </>
      )}
    </>
  );
};

import styled, { css } from "styled-components";

const textStyle = css`
  color: ${({ theme }) => theme.neutralText};
  font-style: normal;
  padding: 9px 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;

export const UserDataRow = styled.div<{ $selected?: boolean }>`
  display: flex;
  overflow: visible;
  align-items: center;
  transition: 0.3s;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  &:last-child {
    border: none;
  }

  &:hover {
    ${({ $selected, theme }) =>
      !$selected &&
      `
      background: ${theme.neutralBackgroundHover}
    `}
  }

  ${({ $selected, theme }) =>
    $selected &&
    `
    background: ${theme.primaryBackground}
  `}
`;

export const CheckboxWrapper = styled.div`
  ${textStyle};
  min-width: 34px;
`;

export const Name = styled.div`
  ${textStyle};
  width: 100%;
  gap: 8px;
`;
export const EmailAddress = styled.div`
  ${textStyle};
  min-width: 316px;
  max-width: 316px;
  margin-left: auto;
`;
export const Position = styled.div`
  ${textStyle};
  width: 100%;
  max-width: 200px;
`;
export const Team = styled.div`
  ${textStyle};
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
  max-width: 200px;
  gap: 4px;
`;

export const Role = styled.div`
  ${textStyle};
  display: flex;
  flex-wrap: wrap;
  min-width: 120px;
  max-width: 120px;
  gap: 4px;
  text-transform: capitalize;
`;

export const TeamsPopUp = styled.div<{
  $top?: number;
  $left?: number;
  $opacity?: number;
}>`
  position: absolute;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  max-width: 300px;
  top: ${({ $top }) => $top + "px"};
  left: ${({ $left }) => $left + "px"};
  opacity: ${({ $opacity }) => $opacity};

  &::before {
    position: absolute;
    bottom: -10px;
    content: "";
    z-index: 10;
    left: 15px;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.neutralBackgroundBase};
  }

  &::after {
    position: absolute;
    content: "";
    bottom: -11px;
    left: 14px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 11px solid ${({ theme }) => theme.neutralBorder};
  }
`;

export const Chip = styled.div<{ $disabled?: boolean; $role?: string }>`
  ${textStyle};
  cursor: pointer;
  padding: 3px 6px;
  background: ${({ theme }) => theme.neutralBackground};
  border-radius: 2px;
  ${({ $disabled, theme }) =>
    $disabled &&
    `
    color: ${theme.neutralTextWeak};
  `}

  ${({ $role, theme }) =>
    $role === "viewer" &&
    `
    color: ${theme.neutralTextWeak};
    border: 1px solid ${theme.neutralBorder};
  `}

  ${({ $role, theme }) =>
    $role === "manager" &&
    `
    color: ${theme.primaryText};
    background: ${theme.primaryBackground};
    border: 1px solid ${theme.primaryBackgroundActive};
  `}

  ${({ $role, theme }) =>
    $role === "editor" &&
    `
    color: ${theme.successText} !important;
    background: ${theme.successBackground};
    border: 1px solid ${theme.successBackgroundActive};
  `}

  ${({ $role, theme }) =>
    $role &&
    !["viewer", "manager", "viewer"].includes($role) &&
    `
    color: ${theme.neutralTextWeak};
    border: 1px solid ${theme.neutralBorder};
  `}
`;

export const Projects = styled.div`
  ${textStyle};
  min-width: 96px;
  max-width: 96px;
  display: flex;
`;
export const AddedOn = styled.div`
  ${textStyle};
  min-width: 160px;
  max-width: 160px;
`;
export const LastActive = styled.div`
  ${textStyle};
  min-width: 120px;
  max-width: 120px;
`;
export const More = styled.div`
  min-width: 32px;
  max-width: 32px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.neutralTextWeak};
  cursor: pointer;
`;

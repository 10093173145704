import { ChatLeftText } from "react-bootstrap-icons";
import { Button } from "../Button";
import { TeamAccessDeniedIcon } from "../../assets";

import * as s from "./style";

export const TeamAccessDenied = () => {
  return (
    <s.Container>
      <s.Content>
        <TeamAccessDeniedIcon />
        <s.Title>You're not in a team</s.Title>
        <s.Description>
          You'll be added to a team soon, and we'll send you an invitation via
          email. You can contact support for assistance.
        </s.Description>
        <Button
          onClick={() => {
            window.location.href = "mailto:support@dhcs.com";
          }}
          variant="primary"
          size="medium"
          name="Contact Support"
          icon={<ChatLeftText />}
        />
      </s.Content>
    </s.Container>
  );
};

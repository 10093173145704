import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Check, ChevronLeft } from "react-bootstrap-icons";

import {
  ModalFooterWrapper,
  ModalContent,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
  CloseIcon,
  ModalFooterButtons,
  Steps,
  CreateProjectWrapper,
  ModalHeadingRow,
  ModalSubHeading,
  Separator,
} from "../styles";

import { Button } from "../../Button";
import {
  requestCreateProject,
  requestUpdateProjectById,
} from "../../../store/slices/projects";
import { resetActiveModal } from "../../../store/slices/modals";

import {
  removeAllSelectedFiles,
  requestGetAllDatasets,
  resetNewProjectSelectedFile,
  setNewProjectDetails,
  setNewProjectSelectedFiles,
} from "../../../store/slices/datasets";
import { getSelectedTeamName } from "../../../store/selectors/teams";
import {
  getDraftProjectDetails,
  getNewProjectSelectedDatasets,
} from "../../../store/selectors/datasets";
import { getModalProjectId } from "../../../store/selectors/modals";
import { getCurrentProjectData } from "../../../store/selectors/projects";
import { FileLibraryItem } from "../../../models/Files";
import StepDot from "../../Inputs/CustomStepDot/StepDot";
import { Search } from "../../SearchBar";
import { DatasetsItemsWrapper } from "./styles";
import { DatasetsItem } from "../../DatasetsItem";
import { AddDatasetsContainer } from "../../AddDatasetsContainer";
import { ProjectDetails } from "../../NewProject/ProjectDetails";
import { setCurrentProjectData } from "../../../store/slices/projectPages";
import { useDebounceCallback } from "usehooks-ts";
import FirstStep from "./CreateProjectSteps/components/FirstStep/FirstStep";
import SelectPopulationStep from "./CreateProjectSteps/components/SelectPopulationStep/SelectPopulationStep";
import SelectMeasureStep from "./CreateProjectSteps/components/SelectMeasureStep/SelectMeasureStep";
import PreviewStep from "./CreateProjectSteps/components/PreviewStep/PreviewStep";
import SelectProgramStep from "./CreateProjectSteps/components/SelectProgramStep/SelectProgramStep";

interface ModalProps {
  onClose: () => void;
}

export const CreateProject = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newProjectSelectedDatasets = useSelector(getNewProjectSelectedDatasets);
  const projectId = useSelector(getModalProjectId);
  const selectedTeamName = useSelector(getSelectedTeamName);
  const current = useSelector(getCurrentProjectData);

  const [datasets, setDatasets] = useState<FileLibraryItem[] | any[]>([]);
  const newProjectDetails = useSelector(getDraftProjectDetails);
  const [isClosing, setIsClosing] = useState(false);
  const [allMeasures, setAllMeasures] = useState();
  const [programSelected, setProgramSelected] = useState<string>();
  const [populationsSelected, setPopulationsSelected] = useState<string>();
  const [measureSelected, setMeasureSelected] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();

  const debounced = useDebounceCallback(setSearch, 500);

  const populations =
    (programSelected &&
      (allMeasures as any)[programSelected].reduce((t: any[], p: any) => {
        if (!t.some((r) => r.bucket === p.bucket)) {
          return [...t, p];
        }
        return t;
      }, [])) ??
    [];

  const skipPopulationStep = Number(populations.length < 2) + 1;

  const setName = (name: string) => {
    dispatch(
      setNewProjectDetails({
        name,
        description: newProjectDetails?.description,
      })
    );
  };

  const setDescription = (description: string) => {
    dispatch(
      setNewProjectDetails({ name: newProjectDetails?.name, description })
    );
  };

  const goToPreview = () => {
    setSearch(undefined);
    setActiveStep(5);
  };

  const handleCreate = () => {
    setSearch(undefined);
    if (projectId) {
      dispatch(
        requestUpdateProjectById({
          id: projectId,
          name: newProjectDetails?.name,
          description: newProjectDetails?.description,
        })
      );
      dispatch(
        setCurrentProjectData({
          ...current,
          name: newProjectDetails.name,
          description: newProjectDetails.description,
        })
      );
      dispatch(resetActiveModal());
      dispatch(resetNewProjectSelectedFile());
      handleOnClose();
    }
    if (activeStep < 4) {
      if (activeStep === 2 && programSelected) {
        if (populations?.length === 1) {
          setPopulationsSelected(populations.at(0)?.program);
          setActiveStep(4);
        } else {
          setActiveStep((prev) => prev + 1);
        }
      } else {
        setActiveStep((prev) => prev + 1);
      }
    } else {
      const dataset = datasets.find((r) => r.id === programSelected);
      const newProjectData = {
        name: newProjectDetails.name,
        description: newProjectDetails.description,
        datasets: [
          {
            id: dataset.id,
            filePath: dataset.filePath,
            name: dataset.name,
            fields: dataset.fields,
          },
        ],
        pages: [],
        population: populationsSelected,
        measure: measureSelected,
      };
      dispatch(requestCreateProject({ ...newProjectData, navigate }));
      dispatch(resetActiveModal());
      dispatch(resetNewProjectSelectedFile());
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    setIsClosing(true);
    dispatch(resetNewProjectSelectedFile());
    dispatch(removeAllSelectedFiles());
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const checkIfDisabled = (step: number) => {
    switch (step) {
      case 1:
        return setDisabled(
          !(
            newProjectDetails?.name?.trim()?.length &&
            newProjectDetails.description.trim().length
          )
        );
      case 2:
        return setDisabled(!programSelected);

      case 3:
        return setDisabled(!populationsSelected);

      case 4:
        return setDisabled(!measureSelected);

      case 5:
        return setDisabled(!measureSelected);

      default:
        return setDisabled(true);
    }
  };

  useEffect(() => {
    if (projectId) {
      dispatch(
        setNewProjectDetails({
          name: current?.name,
          description: current?.description,
        })
      );
      dispatch(
        setNewProjectSelectedFiles(
            current.datasets.map((item) => {
            return {
              name: item?.name,
              filePath: item?.filePath,
              fields: item?.fields,
            };
          }) as FileLibraryItem[]
        )
      );
    }
  }, [current, dispatch, projectId]);

  useEffect(() => {
    checkIfDisabled(activeStep);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeStep,
    programSelected,
    newProjectDetails,
    populationsSelected,
    measureSelected,
  ]);

  useEffect(() => {
    dispatch(
      requestGetAllDatasets({
        callbacks: {
          onSuccess: (data) => {
            setDatasets(data.items || []);
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMeasures]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!process.env.REACT_APP_DATA_SET_URL) {
          return;
        }
        const response = await fetch(process.env.REACT_APP_DATA_SET_URL);
        if (!response.ok) {
          return;
        }
        const data = await response.json();
        setAllMeasures(data);
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <ModalOverlay
      $centred
      onClick={handleOnClose}
      $isClosing={activeStep !== 5 ? isClosing : false}
    >
      <ModalWrapper
        $centred
        onClick={(e) => e.stopPropagation()}
        $isClosing={activeStep !== 5 ? isClosing : false}
        $fileOverviewOpen
        style={{
          height: activeStep === 5 ? "100%" : "auto",
          maxWidth: activeStep === 5 ? "98%" : "min-content",
          width: activeStep === 5 ? "98%" : "min-content",
        }}
      >
        {activeStep !== 5 ? (
          <ModalHeadingWrapper>
            {projectId ? (
              <>
                <ModalHeadingRow>
                  <ModalHeading>Edit project</ModalHeading>
                </ModalHeadingRow>
                <ModalHeadingRow>
                  <CloseIcon onClick={handleOnClose} />
                </ModalHeadingRow>
              </>
            ) : (
              <>
                {activeStep === 1 && (
                  <div style={{ display: "flex", flexDirection: "column", gap: '4px' }}>
                    <ModalHeading>Create project</ModalHeading>
                    <ModalSubHeading>
                      {selectedTeamName || "Team name"}
                    </ModalSubHeading>
                  </div>
                )}
                {activeStep === 2 && (
                  <>
                    <ModalHeadingRow>
                      <ChevronLeft
                        style={{ cursor: "pointer", marginRight: "8px" }}
                        onClick={() => {
                          setSearch(undefined);
                          setActiveStep((prev) => prev - 1);
                        }}
                      />
                      <ModalHeading>Select Program</ModalHeading>
                    </ModalHeadingRow>
                    <ModalHeadingRow>
                      <Search width="xs" onChange={debounced} />
                      <CloseIcon onClick={handleOnClose} />
                    </ModalHeadingRow>
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <ModalHeadingRow>
                      <ChevronLeft
                        style={{ cursor: "pointer", marginRight: "8px" }}
                        onClick={() => {
                          setSearch(undefined);
                          setActiveStep((prev) => prev - 1);
                        }}
                      />
                      <ModalHeading>Select Population</ModalHeading>
                    </ModalHeadingRow>
                    <ModalHeadingRow>
                      <Search width="xs" onChange={debounced} />
                      <CloseIcon onClick={handleOnClose} />
                    </ModalHeadingRow>
                  </>
                )}
                {activeStep === 4 && (
                  <>
                    <ModalHeadingRow>
                      <ChevronLeft
                        style={{ cursor: "pointer", marginRight: "8px" }}
                        onClick={() => {
                          setSearch(undefined);
                          setActiveStep((prev) => prev - skipPopulationStep);
                        }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <ModalHeading>Select Measure</ModalHeading>
                        <ModalSubHeading>
                          Population <Separator /> {populationsSelected}
                        </ModalSubHeading>
                      </div>
                    </ModalHeadingRow>
                    <ModalHeadingRow>
                      <Search width="xs" onChange={debounced} />
                      <CloseIcon onClick={handleOnClose} />
                    </ModalHeadingRow>
                  </>
                )}
              </>
            )}
          </ModalHeadingWrapper>
        ) : null}
        {projectId ? (
          <OverviewContent>
            <ModalContent>
              <ProjectDetails
                name={newProjectDetails?.name || ""}
                description={newProjectDetails?.description || ""}
                setName={setName}
                setDescription={setDescription}
              />

              <AddDatasetsContainer
                name={current.population || "-"}
                description={current.measure || "-"}
                disabledButton={!!projectId}
              />
              <DatasetsItemsWrapper>
                {newProjectSelectedDatasets?.map((dataset, index) => (
                  <DatasetsItem
                    dataset={dataset}
                    key={index}
                    filePath={dataset.filePath}
                    hideRemove={!!projectId}
                  />
                ))}
              </DatasetsItemsWrapper>
            </ModalContent>
          </OverviewContent>
        ) : (
          <CreateProjectWrapper>
            {activeStep === 1 && (
              <FirstStep
                name={newProjectDetails.name}
                description={newProjectDetails.description}
                setName={setName}
                setDescription={setDescription}
              />
            )}
            {activeStep === 2 && (
              <SelectProgramStep
                search={search}
                setProgramSelected={setProgramSelected}
                programSelected={programSelected}
                datasets={datasets}
                allMeasures={allMeasures}
              />
            )}
            {activeStep === 3 && (
              <SelectPopulationStep
                search={search}
                populationSelected={populationsSelected}
                setPopulationsSet={setPopulationsSelected}
                allMeasures={
                  allMeasures && programSelected
                    ? allMeasures[programSelected]
                    : []
                }
              />
            )}
            {activeStep === 4 && (
              <SelectMeasureStep
                search={search}
                programSelected={programSelected!}
                populationSelected={populationsSelected!}
                measureSelected={measureSelected}
                setMeasuresSet={setMeasureSelected}
                goToPreview={goToPreview}
                allMeasures={
                  allMeasures && programSelected
                    ? allMeasures[programSelected]
                    : []
                }
              />
            )}
            {activeStep === 5 && (
              <PreviewStep
                programSelected={programSelected!}
                populationSelected={populationsSelected!}
                measureSelected={measureSelected}
                setMeasuresSet={setMeasureSelected}
                handleCreate={handleCreate}
                handleOnClose={handleOnClose}
                setActiveStep={setActiveStep}
                allMeasures={
                  allMeasures && programSelected
                    ? allMeasures[programSelected]
                    : []
                }
              />
            )}
          </CreateProjectWrapper>
        )}
        {activeStep !== 5 ? (
          <ModalFooterWrapper>
            <Button
              name="Cancel"
              onClick={handleOnClose}
              variant="neutral"
              size="medium"
            />
            {!projectId && (
              <Steps>
                <StepDot active={activeStep === 1} finished={activeStep > 1} />
                <StepDot active={activeStep === 2} finished={activeStep > 2} />
                {populations.length > 1 && (
                  <StepDot
                    active={activeStep === 3}
                    finished={activeStep > 3}
                  />
                )}

                <StepDot active={activeStep === 4} finished={activeStep > 4} />
              </Steps>
            )}

            <ModalFooterButtons>
              <Button
                name={projectId ? "Save" : activeStep > 2 ? "Create" : "Next"}
                onClick={handleCreate}
                type="submit"
                variant={disabled ? "disabled" : "secondary"}
                disabled={disabled}
                size="medium"
                icon={projectId ? <Check /> : <ArrowRight />}
              />
            </ModalFooterButtons>
          </ModalFooterWrapper>
        ) : null}
      </ModalWrapper>
    </ModalOverlay>
  );
};

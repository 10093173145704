// Auth
export const SIGN_IN_URL = "/auth/login";
export const REGISTER_URL = "/auth/register";
export const RESET_PASSWORD = "/auth/reset-password";
export const REFRESH_TOKEN_URL = "/auth/refresh-token";
export const FORGOT_PASSWORD = "/auth/forgot-password";

// Projects
export const GET_ALL_PROJECTS = "/projects";
export const CREATE_PROJECT = "/projects";
export const GET_PROJECT_BY_ID = "/projects/{id}";
export const UPDATE_PROJECT_BY_ID = "/projects/{id}";
export const DELETE_PROJECT_BY_ID = "/projects/{id}";

// Datasets
export const GET_DATASETS = "/datasets";
export const CREATE_DATASET = "/datasets";
export const GET_DATASET_CONTENT_BY_ID = "/datasets/{id}/contents";
export const PATCH_DATASET_BY_ID = "/datasets/{id}";
export const DELETE_DATASET_BY_ID = "/datasets/{id}";
export const UPDATE_DATASET_BY_ID = "/datasets/{id}";

// Files
export const GET_FILE_SIGNED = "/files/download";
export const UPLOAD_FILE = "/files/upload";
export const DELETE_FILE = "/files";

// Pages
export const GET_PAGES = "/pages";
export const CREATE_PAGE = "/pages";
export const CLONE_PAGE = "/pages/{id}/clone";

// Widgets
export const GET_WIDGETS = "/widgets";

export const GET_WIDGETS_SUGGESTIONS = "/suggestions";
export const GET_WIDGETS_STORIES = "/suggestions/stories";
export const GET_WIDGETS_BANNER = "/suggestions/banner";

export const CREATE_MAP_WIDGET = "/widgets/map";
export const CREATE_AREA_CHART_WIDGET = "/widgets/area-chart";
export const CREATE_LINE_CHART_WIDGET = "/widgets/line-chart";
export const CREATE_SPARKLINE_CHART_WIDGET = "/widgets/spark-line-chart";
export const CREATE_SPARKAREA_CHART_WIDGET = "/widgets/spark-area-chart";
export const CREATE_LOLLIPOP_CHART_WIDGET = "/widgets/lollipop-chart";
export const CREATE_BAR_CHART_WIDGET = "/widgets/bar-chart";
export const CREATE_SANKEY_CHART_WIDGET = "/widgets/sankey-chart";
export const CREATE_MATRIX_CHART_WIDGET = "/widgets/matrix-chart";
export const CREATE_MAP_CHART_WIDGET = "/widgets/map";
export const CREATE_SCATTERPLOT_CHART_WIDGET = "/widgets/scatterplot-chart";
export const CREATE_RADAR_CHART_WIDGET = "/widgets/radar-chart";
export const CREATE_POLAR_AREA_CHART_WIDGET = "/widgets/polar-area-chart";
export const CREATE_PUNCHCARD_CHART_WIDGET = "/widgets/punchcard-chart";
export const CREATE_BUBBLE_CHART_WIDGET = "/widgets/bubble-chart";
export const CREATE_RADIALBAR_CHART_WIDGET = "/widgets/radial-bar-chart";

export const GET_SECTIONS = "/sections";
export const CREATE_SECTIONS = "/sections";
export const PATCH_SECTION_BY_ID = "/sections/{id}";
export const REMOVE_SECTION_BY_ID = "/sections/{id}";

// Kpis
export const CREATE_KPIS = "/kpis";
export const PUBLIC_KPIS = "/public/kpis";

// Comparison

export const GET_COMPARISON = "/comparison"

// Profiles
export const GET_PROFILES = "/profiles"
export const DELETE_PROFILES = "/profiles"
export const PATCH__ME_PROFILES = "/profiles"
export const PATCH_PROFILES = "/profiles/{uid}"
export const GET_PROFILES_BY_UID = "/profiles/{uid}"

// comments 

export const COMMENTS_URL = '/comments';
export const COMMENTS_READ_URL = `${COMMENTS_URL}/read`;
export const COMMENTS_HIDE_URL = `${COMMENTS_URL}/hide`;
export const COMMENTS_GROUPED_URL = `${COMMENTS_URL}/grouped`;
export const COMMENTS_RESOLVE_URL = `${COMMENTS_URL}/resolve`;
export const COMMENTS_EDIT_URL = `${COMMENTS_URL}/{id}`;

// Teams
export const GET_TEAMS = "/teams";
export const CREATE_TEAM = "/teams";
export const DELETE_TEAM = "/teams/{id}";
export const EDIT_TEAM = "/teams/{id}";
export const ADD_USER_TO_TEAM = "/teams/{id}/users";
export const GET_TEAM_USERS = "/teams/{id}/users";
export const EDIT_TEAM_USER_BY_ID = "/teams/{id}/users/{userId}";
export const DELETE_TEAM_USER_BY_ID = "/teams/{id}/users/{userId}";
export const DELETE_TEAM_USERS_BULK = "/teams/{id}/users";

// Users
export const GET_USERS = "/users";
export const SEND_USER_INVITE = "/users/invite";
export const DELETE_USERS_BULK = "/users/bulk";

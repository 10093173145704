import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  QueryEditTeamUser,
  QueryDeleteTeamUser,
  QueryGetTeams,
  QueryGetTeamUsers,
  Team,
  TeamMember,
  QueryCreateTeam,
  QueryDeleteTeam,
  QueryAddUserToTeam,
  QueryEditTeam,
  QueryDeleteTeamUsersBulk
} from '../../models/Teams';

export interface TeamsState {
  teams: Team[];
  teamUsers: TeamMember[];
  loading: boolean;
  error: string | null;
  selectedTeam: string | null;
  selectedProjectTeam: string | null;
  singleTeamMembersId: string[];
}

const initialState: TeamsState = {
  teams: [],
  teamUsers: [],
  loading: false,
  error: null,
  selectedTeam: null,
  selectedProjectTeam: window.localStorage.getItem("selectedProjectTeam") || null,
  singleTeamMembersId: [],
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    requestAllTeams: (
      state: TeamsState,
      action: PayloadAction<QueryGetTeams>
    ) => { },

    requestEditTeam: (
      state: TeamsState,
      action: PayloadAction<QueryEditTeam>
    ) => { },

    requestCreateTeam: (
      state: TeamsState,
      action: PayloadAction<QueryCreateTeam>
    ) => { },

    requestTeamUsersList: (
      state: TeamsState,
      action: PayloadAction<QueryGetTeamUsers>
    ) => { },

    requestEditTeamUser: (
      state: TeamsState,
      action: PayloadAction<QueryEditTeamUser>
    ) => { },

    requestAddUserToTeam: (
      state: TeamsState,
      action: PayloadAction<QueryAddUserToTeam>
    ) => { },

    requestDeleteTeamUser: (
      state: TeamsState,
      action: PayloadAction<QueryDeleteTeamUser>
    ) => { },

    requestDeleteTeam: (
      state: TeamsState,
      action: PayloadAction<QueryDeleteTeam>
    ) => { },

    requestDeleteTeamUsers: (
      state: TeamsState,
      action: PayloadAction<QueryDeleteTeamUsersBulk>
    ) => { },

    setTeamUsersList: (
      state: TeamsState,
      action: PayloadAction<TeamMember[]>

    ) => {
      return {
        ...state,
        teamUsers: action.payload,
      };
    },

    setAllTeams: (
      state: TeamsState,
      action: PayloadAction<Team[]>
    ) => {
      return {
        ...state,
        teams: action.payload,
      };
    },
    setSelectedTeam: (
      state: TeamsState,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        selectedTeam: action.payload,
      };
    },
    setSelectedProjectTeam: (
      state: TeamsState,
      action: PayloadAction<string>
    ) => {
      window.localStorage.setItem('selectedTeam', action.payload);
      return {
        ...state,
        selectedProjectTeam: action.payload,
      };
    },

    setMembersIdWithSingleTeam: (
      state: TeamsState,
      { payload }: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        singleTeamMembersId: payload,
      };
    },
  },
});

export const {
  requestAllTeams,
  requestCreateTeam,
  requestEditTeam,
  requestTeamUsersList,
  requestEditTeamUser,
  requestDeleteTeam,
  requestDeleteTeamUser,
  requestDeleteTeamUsers,
  requestAddUserToTeam,
  setAllTeams,
  setTeamUsersList,
  setSelectedTeam,
  setSelectedProjectTeam,
  setMembersIdWithSingleTeam,
} = teamsSlice.actions;

export default teamsSlice.reducer;

import styled, { css } from "styled-components";
import { SquareFill, ThreeDots } from "react-bootstrap-icons";

const textStyle = css`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-right: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 9px 8px;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`

export const DatasetsTable = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  height: 36px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  background-color: ${({ theme }) => theme.neutralBackgroundMedium};
  border-radius: 7px 7px 0 0;
`;

export const CheckBoxWrapper = styled.div<{ $noPadding?: boolean }>`
  ${textStyle};
  display: flex;
  min-width: 34px;
  justify-content: center;
  height: 35px;
  ${({ $noPadding }) => $noPadding && `padding: 0;`}
`;

export const Name = styled.div<{ $noPadding?: boolean }>`
  ${textStyle}
  min-width: 220px;
  padding-left: 8px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  position: relative;
  ${({ $noPadding }) => $noPadding && `padding: 0;`}
`;

export const SelectAll = styled(SquareFill)`
  width: 100%;
  max-width: 16px;
  margin: 0 16px;
  cursor: pointer;
`;

export const Dimensions = styled.div<{ $noPadding?: boolean }>`
  ${textStyle}
  min-width: 250px;
  width: 100%;
  position: relative;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  ${({ $noPadding }) => $noPadding && `padding: 0;`}
`;

export const Teams = styled.div<{ $noPadding?: boolean }>`
  ${textStyle}
  min-width: 250px;
  width: 100%;
  position: relative;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  ${({ $noPadding }) => $noPadding && `padding: 0;`}
`;


export const UploadedOn = styled.div<{ $noPadding?: boolean }>`
  ${textStyle}
  width: 100%;
  min-width: 214px;
  text-align: left;
  flex: 1;
  position: relative;
  ${({ $noPadding }) => $noPadding && `padding: 0;`}
`;

export const Details = styled.div<{ $noPadding?: boolean }>`
  ${textStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  ${({ $noPadding }) => $noPadding && `padding: 0;`}
`;

export const More = styled(ThreeDots) <{ $noPadding?: boolean }>`
  color: ${({ theme }) => theme.neutralneutralTextWeakWeak};
  margin:0 8px 0 8px;
  ${({ $noPadding }) => $noPadding && `padding: 0;`}
`;

export const UploadedOnSort = styled(ThreeDots)`
  margin-left: 4px;
  cursor: pointer;
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ForgotRequestDto,
  LoginResponseDTO,
  ResetRequestDto,
} from "../../models/Authentication";

export interface AuthState {
  user: LoginResponseDTO | null;
}

const initialState: AuthState = {
  user: null,
};

const appConfigSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    requestRegistration: (state, action) => {},
    setRegistrationSuccess: (
      state,
      action: PayloadAction<LoginResponseDTO>
    ) => {
      state.user = action.payload;
    },

    requestSignIn: (state, payload) => {},
    setSignInSuccess: (state, action: PayloadAction<LoginResponseDTO>) => {
      state.user = action.payload;
    },

    signOut: (state) => {
      return {
        ...state,
        user: null,
      };
    },

    setUpdateUserToken: (state, action: PayloadAction<LoginResponseDTO>) => {
      state.user = action.payload;
    },
    requestForgotPassword: (
      state,
      action: PayloadAction<ForgotRequestDto>
    ) => {},
    requestResetPassword: (state, action: PayloadAction<ResetRequestDto>) => {},
  },
});

export const {
  requestRegistration,
  setRegistrationSuccess,
  requestSignIn,
  setSignInSuccess,
  signOut,
  setUpdateUserToken,
  requestForgotPassword,
  requestResetPassword,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;

import React, { useEffect, useMemo, useState } from "react";

import { Heading } from "../../styles";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import { DatasetsList } from "../../components/DatasetsList";
import { useDispatch, useSelector } from "react-redux";
import { EmptyDatasetsPage } from "../../components/EmptyDatasets";
import { requestGetAllDatasets } from "../../store/slices/datasets";
import { getAllDatasets } from "../../store/selectors/datasets";

export const Datasets = () => {
  const allDatasets = useSelector(getAllDatasets);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<Record<string, any>>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFilters = useMemo(() => filters, [JSON.stringify(filters)]);

  useEffect(() => {
    if (!allDatasets.items?.length) {
      dispatch(requestGetAllDatasets({ defaultDatasets: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!Object.values(memoizedFilters).some(Boolean)) {
      dispatch(
        requestGetAllDatasets({
          defaultDatasets: true,
          ...memoizedFilters,
        })
      );
    }
  }, [memoizedFilters, dispatch]);

  return (
    <>
      <HeadingMenuContainer>
        <Heading>Programs</Heading>
      </HeadingMenuContainer>
      {!allDatasets.items.length ? (
        <EmptyDatasetsPage />
      ) : (
        <DatasetsList
          items={allDatasets.items ?? []}
          filters={filters}
          onChangeFilters={(value) =>
            setFilters((prevState) => ({ ...prevState, ...value }))
          }
        />
      )}
    </>
  );
};

import React, { useEffect } from "react";
import { List } from "./styled";
import { PlusLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
// import { useSearchParams } from "react-router-dom";

import { TeamItem } from "../../../../components/UserManagementComponents/Teams/TeamItem";
import { EmptyPage } from "../../../../components/EmptyPage";
import { ReactComponent as NoDataImage } from "./assets/noDataImage.svg";
import { getTeams } from "../../../../store/selectors/teams";
import { requestAllTeams } from "../../../../store/slices/teams";
import { setActiveModal } from "../../../../store/slices/modals";

const colors = ["#2D6E8D", "#2F54EB", "#43A047", "#9E9DAF", "#EEA676", "#DA7973", "#7BA4B7"];

export const TeamsTable = () => {
  // const [searchParams] = useSearchParams();
  const teams = useSelector(getTeams);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      requestAllTeams({})
    );
  }, [dispatch]);

  return (
    <>
      {!teams?.length ? (
        <EmptyPage
          Icon={NoDataImage}
          title="No teams were discovered"
          subTitle={`Feel free to create a new team by clicking the "Create Team" button below! `}
          button={{
            name: "Create Team",
            onClick: () => { dispatch(setActiveModal({ id: "createTeamModal" })); },
            icon: <PlusLg />,
          }}
        />
      ) : (
        <List>
          {teams?.map((team, index) => {
            return <TeamItem {...team} color={colors[index]} />;
          })}
        </List>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Check } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Button } from "../../Button";
import {
  getAllUsers,
  getLastTeamsUsersManagers,
} from "../../../store/selectors/users";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { getTeams } from "../../../store/selectors/teams";
import { IUser } from "../../../models/Users";
import { AvatarCard } from "../../AvatarCard";
import { getInitials } from "../../../helpers/getInitials";
import { Chip } from "../../UserManagementComponents/Users/UsersRow/styled";
import { getTeamsWithLastMembers } from "../../../helpers/getLastManager";
import { selectNewUser } from "../../../store/slices/userManagement";
import { setLastTeamsUsersManagers } from "../../../store/slices/users";
import { setActiveModal } from "../../../store/slices/modals";
import { AloneUserUserIcon } from "../../../assets";

import {
  CloseIcon,
  HeaderContainer,
  ModalFooterWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import * as s from "../RemoveUsers/styles";


const tooltipId = "no-remove-user-name";

type Props = {
  onClose: () => void;
};

export const LastManagersTeamsDeclined = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);
  const users = useSelector(getAllUsers);
  const selectedUsers = useSelector(getSelectedUsers);
  const lastTeamManagers = useSelector(getLastTeamsUsersManagers);

  const [isClosing, setIsClosing] = useState(false);
  const [noRemoveUsers, setNoRemoveUsers] = useState<IUser[]>([]);

  const isMultipleLastTeamManagers = lastTeamManagers?.length > 1;

  useEffect(() => {
    if (!lastTeamManagers?.length && !users?.length) {
      return;
    }
    setNoRemoveUsers(
      users?.filter((user) => lastTeamManagers?.find((uid) => uid === user.uid))
    );
  }, [users, lastTeamManagers]);

  const handleClose = () => {
    setIsClosing(true);
    dispatch(setLastTeamsUsersManagers([]));

    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleRemoveUsers = () => {
    const filteredSelectedUsers = selectedUsers?.filter(
      (id) => !lastTeamManagers.includes(id)
    );

    lastTeamManagers.forEach((lastTeamMemberId) =>
      dispatch(selectNewUser(lastTeamMemberId))
    );

    if (!filteredSelectedUsers.length) {
      toast.error("There are not users for delete!");
    } else {
      setTimeout(() => {
        dispatch(setActiveModal({ id: "removeUsers" }));
      }, 600);
    }

    handleClose();
  };

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer>
          <AloneUserUserIcon />
          <s.HeaderTitle>
            {isMultipleLastTeamManagers
              ? "These users cannot be deleted!"
              : "This user cannot be deleted!"}
          </s.HeaderTitle>
          <s.HeaderDescrition>
            {isMultipleLastTeamManagers
              ? "These users are the last or only remaining manager of their team. To remove a manager, you must delete the team first.."
              : "This user is the last manager of his team. To remove a manager, you must delete the team first."}
          </s.HeaderDescrition>
          <s.HeaderCloseIcon>
            <CloseIcon onClick={handleClose} />
          </s.HeaderCloseIcon>
        </HeaderContainer>

        <OverviewContent>
          <s.BodyContainer>
            {!!noRemoveUsers?.length &&
              noRemoveUsers?.map((user, index, users) => (
                <s.RemoveUserContainer
                  key={user?.uid}
                  $isLastItem={users?.length === index + 1}
                >
                  <s.RemoveUserBox>
                    <s.RemoveAvatarBox>
                      <AvatarCard
                        avatar={user?.profile?.avatar}
                        size="xs"
                        initials={getInitials(user.name)}
                      />
                      <s.RemoveAvatarName
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={user?.name}
                      >
                        {user?.name}
                      </s.RemoveAvatarName>
                    </s.RemoveAvatarBox>
                    <s.RemoveTeamBox>
                      {getTeamsWithLastMembers(
                        user?.teams?.teams,
                        teams,
                        lastTeamManagers
                      )?.map((team, index) => (
                        <Chip key={index}>{team?.name}</Chip>
                      ))}
                    </s.RemoveTeamBox>
                  </s.RemoveUserBox>
                </s.RemoveUserContainer>
              ))}
            <Tooltip id={tooltipId} style={{ zIndex: 9999 }} />
          </s.BodyContainer>
        </OverviewContent>

        <ModalFooterWrapper $flexEnd={true}>
          <Button
            name="Got it"
            onClick={handleRemoveUsers}
            variant="secondary"
            size="medium"
            icon={<Check />}
          />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

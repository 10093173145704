import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeft, PlusLg, XLg } from "react-bootstrap-icons";
import {
  resetSelectedUsers,
  selectTeam,
} from "../../../store/slices/userManagement";
import { setSelectedTeam } from "../../../store/slices/teams";
import { DropDown } from "../../UserManagementComponents/Teams/DropDown";
import { useAbilityContext } from "../../../casl/context";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { getTeams } from "../../../store/selectors/teams";
import { Button } from "../../Button";
import { setActiveModal } from "../../../store/slices/modals";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

import {
  Chip,
  More,
  SelectedTeam,
  SelectedText,
  SelectedUsers,
  TeamName,
} from "../styles";

export const TeamHeader = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const ability = useAbilityContext();
  const menuRef = useRef<any>(null);
  const teams = useSelector(getTeams);
  const navigate = useNavigate();
  const selectedUsers = useSelector(getSelectedUsers);
  const selectedUsersCount = selectedUsers.length;
  const [menuOpen, setMenuOpen] = useState(false);

  useOutsideClick(menuRef, () => {
    setMenuOpen(false);
  });

  const handleRemoveTeamUsers = () => {
    const isAllManagersChecked = teams
      ?.find((team) => team?.id === id)
      ?.members?.filter((member) => member.role === "manager")
      ?.every((manager) => selectedUsers.includes(manager.id));

    if (isAllManagersChecked) {
      dispatch(setActiveModal({ id: "lastManagerDeclined" }));
    } else {
      dispatch(setSelectedTeam(id));
      dispatch(setActiveModal({ id: "removeTeamUsers" }));
    }
  };

  return (
    <>
      {!!selectedUsersCount ? (
        <SelectedUsers>
          <XLg onClick={() => dispatch(resetSelectedUsers())} />
          <SelectedText> {selectedUsersCount} selected </SelectedText>
        </SelectedUsers>
      ) : (
        <SelectedTeam>
          <ArrowLeft onClick={() => navigate("/management/teams")} />
          <TeamName>{teams?.find((item) => item?.id === id)?.name}</TeamName>
          {teams
            ?.find((item) => item?.id === id)
            ?.datasets?.map((dataset) => (
              <Chip>{dataset.name}</Chip>
            ))}
          <div style={{ position: "relative" }} ref={menuRef}>
            <More
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(selectTeam(id));
                dispatch(setSelectedTeam(id));
                setMenuOpen(!menuOpen);
              }}
            />
            {menuOpen && <DropDown id={id} menuOpen={menuOpen} isTeamPage />}
          </div>
        </SelectedTeam>
      )}

      {!!selectedUsersCount ? (
        <Button
          onClick={handleRemoveTeamUsers}
          variant={"danger"}
          name="Remove from Team"
          size={"large"}
        />
      ) : (
        ability.can("manage", "teams") && (
          <Button
            onClick={() => {
              dispatch(setActiveModal({ id: "inviteToTeamModal" }));
            }}
            variant={"secondary"}
            icon={<PlusLg />}
            name="Invite People"
            size="large"
          />
        )
      )}
    </>
  );
};

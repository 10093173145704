import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  Credentials,
  SignInButtonWrapper,
  SignInDescription,
  AuthHeading,
  AuthMethod,
  AuthWrapper,
  Container,
  Background,
  AuthPageWrapper,
  LogoWrapper,
  Logo,
} from "../styles";
import { FormGroup } from "../FormGroup";
import { Button } from "../../../components/Button";
import { NewProfileValidationSchema } from "../../../constants";
import { CheckLg } from "react-bootstrap-icons";
import {
  requestProfile,
  requestUpdateMeProfile,
} from "../../../store/slices/profiles";
import { getProfiles } from "../../../store/selectors/profiles";

export const NewProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(getProfiles);

  const formInitialValues = {
    firstName: "",
    lastName: "", 
  };

  useEffect(() => {
    dispatch(requestProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent, handleSubmit: () => void) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Container>
      <Background />
      <AuthPageWrapper>
        <LogoWrapper>
          <Logo onClick={() => navigate("/")} />
        </LogoWrapper>
        <AuthWrapper>
          <AuthMethod>
            <AuthHeading>Personal Details</AuthHeading>
          </AuthMethod>
          <SignInDescription>
            Personalize your profile by adding your name
          </SignInDescription>
          <Formik
            initialValues={formInitialValues}
            validationSchema={NewProfileValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(
                requestUpdateMeProfile({
                  ...values,
                  id: profile?.id,
                  uid: profile?.uid,
                  email: profile?.email,
                  phoneNumber: "",
                  jobTitle: "",
                  organization: "",
                  location: "",
                  avatar: "",
                  officePhone: "",
                  isAdmin: profile?.isAdmin || false,
                  canAddProjects: profile?.canAddProjects || false,
                  autoAccessToFutureProjects:
                    profile?.autoAccessToFutureProjects || false,
                  desktopNotifications: {
                    importantAlerts: false,
                    dashboardPublished: false,
                    viewerEditor: false,
                    playSoundOnReminder: false,
                    statusUpdate: false,
                  },
                  emailNotifications: {
                    emailViewerEditor: false,
                    emailDashboardCompleted: false,
                    emailStatusUpdate: false,
                  },
                  teams: [],
                  invitedBy: "",
                  callbacks: { onSuccess: () => navigate("/") },
                })
              );
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form
                onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
                onSubmit={handleSubmit}
              >
                <Credentials>
                  <FormGroup
                    id="firstName"
                    type="text"
                    name="firstName"
                    errors={errors.firstName}
                    placeholder="First Name"
                    values={values.firstName}
                    handleChange={handleChange}
                    touched={touched.firstName}
                    handleBlur={handleBlur}
                  />
                  <FormGroup
                    id="lastName"
                    type="text"
                    name="lastName"
                    errors={errors.lastName}
                    placeholder="Last Name"
                    values={values.lastName}
                    handleChange={handleChange}
                    touched={touched.lastName}
                    handleBlur={handleBlur}
                  />
                </Credentials>
                <SignInButtonWrapper>
                  <div />
                  <Button
                    type="submit"
                    variant="secondary"
                    size="medium"
                    name="Submit"
                    icon={<CheckLg />}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  />
                </SignInButtonWrapper>
              </Form>
            )}
          </Formik>
        </AuthWrapper>
      </AuthPageWrapper>
    </Container>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { createPortal } from "react-dom";
import useMeasure from "react-use-measure";

import {
  CheckBoxWrapper,
  DatasetDimensionsCount,
  Dimensions,
  FileName,
  RowWrapper,
  Teams,
  UploadedOn,
  UploadedOnTime,
} from "./styles";
import { setActiveModal } from "../../store/slices/modals";
import {
  requestDatasetRemove,
  setDatasetPreview,
} from "../../store/slices/datasets";
import { NewDatasetDTO } from "../../models/Datasets";
import { Submenu } from "../Submenu";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";
import Badge from "../Badge";
import { getIconSrc } from "../../helpers/getIconType";
import {
  Chip,
  TeamsPopUp,
} from "../UserManagementComponents/Users/UsersRow/styled";

interface NewDataSetDtoCustom extends NewDatasetDTO {
  selectAll?: boolean;
}

interface IPopUp {
  coords: {
    pageX: any;
    pageY: any;
    clientX: any;
    clientY: any;
  };
}

export const DatasetRow = (dataset: NewDataSetDtoCustom) => {
  const dispatch = useDispatch();
  const [ref, bounds] = useMeasure();
  const [menuOpen, setMenuOpen] = useState(false);
  const { name, createdAt, fields, id, selectAll, teams } = dataset;
  const [showAllDimensions, setShowAllDimensions] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState<IPopUp | null>(null);
  const openModal = (dataset: NewDatasetDTO) => {
    dispatch(setDatasetPreview(dataset));
    dispatch(setActiveModal({ id: "datasetOverviewModal" }));
  };

  const minus = 5

  const dimensionsToShow = () => {
    if (showAllDimensions) return fields;
    if (!showAllDimensions) return fields.slice(0,minus);
  };

  const [checked, setChecked] = useState<boolean | undefined>(false);

  useEffect(() => {
    setChecked(selectAll);
  }, [selectAll]);

  return (
    <RowWrapper onMouseLeave={() => setShowAllDimensions(false)}>
      <CheckBoxWrapper>
        <CheckBox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </CheckBoxWrapper>
      <FileName onClick={() => openModal(dataset)}>{name}</FileName>
      <Dimensions>
        {dimensionsToShow()!.map((dimension, idx) => {
          return (
            <Badge
              name={dimension.label}
              onClick={() => {}}
              icon={getIconSrc({
                type: dimension.type,
                subtype: dimension.subtype,
              })}
              key={idx}
              selectedItems={[]}
            />
          );
        })}
        {Math.sign(fields.length - minus) &&
        fields.length - minus > 0 &&
        !showAllDimensions ? (
          <DatasetDimensionsCount onClick={() => setShowAllDimensions(true)}>
            +{fields.length - minus}
          </DatasetDimensionsCount>
        ) : (
          ""
        )}
      </Dimensions>
      <Teams>
        {!teams.length && <Chip>No Team</Chip>}

        {teams.slice(0, 1).map((item) => (
          <Chip>{item.name}</Chip>
        ))}

        {teams.length - 1 > 0 && (
          <Chip
            onMouseMove={(event: any) => {
              const { pageX, pageY, clientX, clientY } = event;
              const coords = { pageX, pageY, clientX, clientY };

              setTooltip({ coords });
            }}
            onMouseLeave={() => setTooltip(null)}
          >
            +{teams.length - 1}
          </Chip>
        )}
      </Teams>
      <UploadedOn>
        {format(new Date(createdAt!), "MM.dd.yyyy")}
        <UploadedOnTime>
          {format(new Date(createdAt!), "HH:mm a")}
        </UploadedOnTime>
      </UploadedOn>
      {/* <More onClick={() => setMenuOpen((prevState) => !prevState)} /> */}
      {menuOpen && (
        <Submenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          datasetItemMenu
          onRemove={() => dispatch(requestDatasetRemove(id))}
        />
      )}
      {tooltip &&
        createPortal(
          <TeamsPopUp
            ref={ref}
            $left={tooltip.coords.pageX - 20}
            $top={
              tooltip.coords.pageY - (bounds.height ? bounds.height + 15 : 150)
            }
            $opacity={Number(!!bounds.height)}
          >
            {teams?.map((item) => (
              <Chip>{item.name}</Chip>
            ))}
          </TeamsPopUp>,
          document.body
        )}
    </RowWrapper>
  );
};

import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";
import {
  DELETE_PROFILES,
  GET_PROFILES,
  GET_PROFILES_BY_UID,
  PATCH__ME_PROFILES,
  PATCH_PROFILES,
} from "./constants";
import { IGetProfile, IUpdateProfile } from "./../types/profile";

export const getProfile = async () => {
  return axios
    .get(`${GET_PROFILES}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteProfile = async () => {
  return axios
    .get(`${DELETE_PROFILES}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const updateMeProfile = async (data: IUpdateProfile) => {
  return axios
    .patch(`${PATCH__ME_PROFILES}`, { ...data })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const updateProfile = async (data: IUpdateProfile) => {
  return axios
    .patch(`${PATCH_PROFILES.replace("{uid}", data.uid)}`, { ...data })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getProfileById = async (query: IGetProfile) => {
  return axios
    .get(`${GET_PROFILES_BY_UID.replace("{uid}", query.uid)}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

import React from "react";
import { useLocation } from "react-router-dom";
import { PlusLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useAbilityContext } from "../../../casl/context";
import { setActiveModal } from "../../../store/slices/modals";
import { getTeams } from "../../../store/selectors/teams";
import { Button } from "../../Button";
import { managementPaths } from "../data/paths";

import { Tab, Tabs, Title } from "../styles";

export const TeamsHeader = () => {
  const dispatch = useDispatch();
  const ability = useAbilityContext();
  const { pathname } = useLocation();
  const teams = useSelector(getTeams);

  return (
    <>
      {ability.can("read", "users") ? (
        <Tabs>
          {managementPaths?.map((item) => (
            <Tab $selected={pathname === item.path} to={item.path}>
              {item.icon} {item.title}
            </Tab>
          ))}
        </Tabs>
      ) : (
        <Title>My Teams</Title>
      )}
      {ability.can("create", "teams") && !!teams?.length && (
        <Button
          onClick={() => {
            dispatch(setActiveModal({ id: "createTeamModal" }));
          }}
          variant={"secondary"}
          icon={<PlusLg />}
          name="Create Team"
          size="large"
        />
      )}
    </>
  );
};

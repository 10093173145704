import React from "react";
import { ChartContainer } from "../..";
import { WIDGETS } from "../../../../../Widgets/widgets";
import { PlaceHolder } from "../PlaceHolder";
import { UpdateSingleWidgetDTO } from "../../../../../../models/Widgets";

interface Props {
  item: any;
  originalItem?: any;
  setContentItem: (data: UpdateSingleWidgetDTO) => void;
  isRight?: boolean;
  showLegend?: boolean;
}

const SectionItem: React.FC<Props> = ({
  item,
  originalItem,
  setContentItem,
  isRight,
  showLegend,
}) => {
  const ChartComponent = item && WIDGETS[item.chartType];

  if (item.sectionType === "map") {
    return (
      <ChartContainer
        key={item.uniqueId}
        item={item}
        originalItem={originalItem}
        setContentItem={setContentItem}
        isRight={isRight}
      >
        {ChartComponent ? (
          <ChartComponent
            key={item.uniqueId}
            currentWidget={{ ...item }}
            uniqueMapId={item.uniqueId}
            showSettings
            isSharedControls
          />
        ) : (
          <PlaceHolder />
        )}
      </ChartContainer>
    );
  }
  if (item.sectionType === "widget") {
    return (
      <ChartContainer
        item={item}
        setContentItem={setContentItem}
        isRight={isRight}
        originalItem={originalItem}
      >
        {ChartComponent ? (
          <ChartComponent
            key={item.id}
            storytelling
            showLegend={showLegend}
            currentWidget={{ ...item }}
            isRight={isRight}
            isComparison
          />
        ) : (
          <PlaceHolder />
        )}
      </ChartContainer>
    );
  }
  return <PlaceHolder />;
};

export default SectionItem;

export function getInitials(name: string): string {
  return name
    ?.split(" ")
    .filter((word) => word.length > 0)
    .map((word) => word[0].toUpperCase())
    .join("");
}

/**
 * Get the first two initials of a name
 * @param name - The full name (e.g., "John Doe")
 * @returns A string with the first two initials (e.g., "JD")
 */
export function getTwoInitials(name: string): string {
  if (!name) return "";

  return name
    .split(" ") // Split the name into parts
    .filter((part) => part.trim() !== "") // Remove empty parts
    .slice(0, 2) // Take the first two parts
    .map((part) => part[0].toUpperCase()) // Get the first letter and capitalize it
    .join(""); // Combine the initials
}

export const getFullName = (firstName?: string, lastName?: string) => {
  if (lastName || firstName) {
    return `${firstName || ""} ${lastName || ""}`;
  }
  return "";
};

import React, { useState } from "react";

import { PasswordResetConfirmation } from "./Confirmation";
import { ForgotPasswordForm } from "./ForgotPass";
import { useNavigate } from "react-router-dom";

export const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<"ForgotForm" | "ConfirmPage">("ForgotForm");
  const [email, setEmail] = useState<string>("");
  return (
    <>
      {step === "ForgotForm" ? (
        <ForgotPasswordForm
          setStep={() => setStep("ConfirmPage")}
          setEmail={(value) => setEmail(value)}
        />
      ) : (
        ""
      )}
      {step === "ConfirmPage" ? (
        <PasswordResetConfirmation
          setStep={() => navigate("/")}
          email={email}
        />
      ) : (
        ""
      )}
    </>
  );
};

import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  QueryDeleteUsersBulk,
  QueryGetUsers,
  QuerySendUserInvite,
} from "../../models/Users";
import { ServerResponse } from "../../models";
import { deleteUsersBulk, getAllUsers, sendUserInvite } from "../../api/users";
import { requestAllUsers, setAllUsers } from "../slices/users";
import { toast } from "react-toastify";

export function* handleGetAllUsers({ payload }: PayloadAction<QueryGetUsers>) {
  const { callbacks, ...params } = payload;
  const { response, error }: ServerResponse = yield call(getAllUsers, params);

  if (response?.status === 200) {
    yield put(setAllUsers(response.data.items));
    callbacks?.onSuccess(response.data);
  } else {
    toast.error(error?.message);
  }
}

export function* handleSendUserInvite({
  payload,
}: PayloadAction<QuerySendUserInvite>) {
  const { response }: ServerResponse = yield call(sendUserInvite, payload);

  if (response?.status === 200) {
    yield put(requestAllUsers({ limit: 100 }));
    payload?.callbacks?.onSuccess(response.data);
    toast.success("User invited successfully");
  }
}

export function* handleDeleteUsersBulk({
  payload,
}: PayloadAction<QueryDeleteUsersBulk>) {
  const usersIdsLength = payload.userIds.length;
  const { response }: ServerResponse = yield call(deleteUsersBulk, payload);
  if (response?.status === 200) {
    try {
      yield put(requestAllUsers({ limit: 100 }));
      payload?.callbacks?.onSuccess(response.data);

      toast.success(
        `User${usersIdsLength > 1 ? "s" : ""} deleted successfully!`
      );
    } catch (e) {}
  }
}

export function* watchUsersSaga() {
  yield takeLatest("users/requestAllUsers", handleGetAllUsers);
  yield takeLatest("users/requestSendUserInvite", handleSendUserInvite);
  yield takeLatest("users/requestDeleteUsersBulk", handleDeleteUsersBulk);
}

import React, { useRef } from "react";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { useOnClickOutside } from "usehooks-ts";
import { IMenuData } from "../UserManagementComponents/Users/UsersList";
import { ActionButton, ActionsPage, DimensionSettingsWrapper } from "./styles";

type Props = {
  columnMenu: IMenuData;
  setColumnMenu: React.Dispatch<React.SetStateAction<IMenuData | null>>;
  filters: Record<string, any>;
  onChangeFilters?: (filters: Record<string, any>) => void;
};

const UsersTableMenu = ({
  columnMenu: { top, left, key },
  setColumnMenu,
  filters,
  onChangeFilters,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setColumnMenu(null));

  return (
    <DimensionSettingsWrapper $top={top} $left={left} ref={ref}>
      <ActionsPage>
        <ActionButton
          $active={
            filters?.orderBy === key && filters?.orderDirection === "ASC"
          }
          onClick={() => {
            onChangeFilters({
              orderBy: key,
              orderDirection: "ASC",
            });
            setColumnMenu(null);
          }}
        >
          <ArrowUp />
          Sort Ascending
        </ActionButton>
        <ActionButton
          $active={
            filters?.orderBy === key && filters?.orderDirection === "DESC"
          }
          onClick={() => {
            onChangeFilters({
              orderBy: key,
              orderDirection: "DESC",
            });
            setColumnMenu(null);
          }}
        >
          <ArrowDown />
          Sort Descending
        </ActionButton>
      </ActionsPage>
    </DimensionSettingsWrapper>
  );
};

export default UsersTableMenu;

interface IColumnClick {
  event: React.MouseEvent;
  key: string;
}

export const handleColumnClick = ({ event, key } : IColumnClick) => {
  const rect = event.currentTarget.getBoundingClientRect();
  const top = rect.bottom;
  const left = rect.left;
  return {top, left, key};
};

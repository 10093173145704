import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  QueryGetUsers,
  IUser,
  QuerySendUserInvite,
  QueryDeleteUsersBulk,
} from "../../models/Users";

export interface UsersState {
  users: IUser[];
  deleteUserId?: string;
  lastTeamsUsersManagersId: string[];
  emails: string[];
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: UsersState = {
  users: [],
  lastTeamsUsersManagersId: [],
  emails: [],
  loading: false,
  error: null,
  success: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    requestAllUsers: (
      state: UsersState,
      action: PayloadAction<QueryGetUsers>
    ) => { },

    requestSendUserInvite: (
      state: UsersState,
      action: PayloadAction<QuerySendUserInvite>
    ) => { },

    setAllUsers: (state: UsersState, action: PayloadAction<IUser[]>) => {
      return {
        ...state,
        users: action.payload,
      };
    },
    setDeleteUserId: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        deleteUserId: action.payload,
      };
    },
    requestDeleteUsersBulk: (
      state: UsersState,
      action: PayloadAction<QueryDeleteUsersBulk>
    ) => {},

    setLastTeamsUsersManagers: (
      state: UsersState,
      { payload }: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        lastTeamsUsersManagersId: payload,
      };
    },
  },
});

export const {
  requestAllUsers,
  requestSendUserInvite,
  setAllUsers,
  setDeleteUserId,
  requestDeleteUsersBulk,
  setLastTeamsUsersManagers,
} = usersSlice.actions;

export default usersSlice.reducer;

import React from "react";

import { Button } from "../Button";
import { Description, DetailsWrapper, NoProjectWrapper, Title } from "./styles";
import { ReactComponent as NoProjectIcon } from "../../assets/new-project.svg";
import { PlusLg } from "react-bootstrap-icons";

type EmptyProjectsType = {
  openNewProjectModal: () => void;
  isShowButton?: boolean;
};

export const EmptyProjects = ({
  openNewProjectModal,
  isShowButton = true,
}: EmptyProjectsType) => {
  return (
    <NoProjectWrapper>
      <NoProjectIcon />
      <DetailsWrapper>
        <Title>
          {isShowButton
            ? "Looks like you haven't embarked on any projects just yet!"
            : "It seems that this user doesn’t have any created projects yet!"}
        </Title>
        <Description>
          {isShowButton
            ? "Ready to kick off your first project? Click the button below, select a measure, and start exploring!"
            : "Once they start creating projects or are added to existing ones, they’ll appear on this page."}
        </Description>
      </DetailsWrapper>

      {isShowButton && (
        <Button
          name="Create Project"
          onClick={() => openNewProjectModal()}
          icon={<PlusLg />}
          variant="secondary"
          size="medium"
        />
      )}
    </NoProjectWrapper>
  );
};

import { useState } from "react";
import { Check } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Button } from "../../Button";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { AloneUserUserIcon } from "../../../assets";

import {
  CloseIcon,
  ModalFooterWrapper,
  ModalOverlay,
  ModalWrapper,
  HeaderContainer,
} from "../styles";
import * as s from "../RemoveUsers/styles";

type Props = {
  onClose: () => void;
};

export const LastManagerDeclined = ({ onClose }: Props) => {
  const selectedUsers = useSelector(getSelectedUsers);
  const [isClosing, setIsClosing] = useState(false);

  const isSelectedManagers =
    !!selectedUsers?.length && selectedUsers?.length > 1;

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      onClose();
    }, 400);
  };

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer $isShowDivider={false}>
          <AloneUserUserIcon />
          <s.HeaderTitle>
            {isSelectedManagers
              ? "You cannot remove all managers!"
              : "This user can not be removed!"}
          </s.HeaderTitle>
          <s.HeaderDescrition>
            {isSelectedManagers
              ? "Each team must have at least one manager. Make sure at least one manager remains in the team."
              : "This user is the only remaining manager in his team. Each team must have at least one manager."}
          </s.HeaderDescrition>
          <s.HeaderCloseIcon>
            <CloseIcon onClick={handleClose} />
          </s.HeaderCloseIcon>
        </HeaderContainer>
        <ModalFooterWrapper $flexEnd={true}>
          <Button
            name="Got it"
            onClick={handleClose}
            variant="secondary"
            size="medium"
            icon={<Check />}
          />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

import React, { ChangeEvent, useState } from "react";
import { ErrorMsg, FieldWrapper, Input, RequiredStar } from "./styles";

type FormPhoneInputProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  placeholder: string;
  type: string;
  name: string;
  values: string | number;
  id: string;
  errors?: string;
  touched?: boolean;
  required?: boolean;
  disableMargin?: boolean;
};

export const FormPhoneInput: React.FC<FormPhoneInputProps> = ({
  handleChange,
  handleBlur,
  placeholder,
  type,
  name,
  values,
  errors,
  touched,
  id,
  required,
  disableMargin
}) => {
  const [phoneNumber, setPhoneNumber] = useState(() => {
    const val = String(values || '');
    const [ext = '', main = ''] = val.split(':');
    return { main, ext };
  });

  const handleInputChange = (field: 'main' | 'ext') => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const updatedPhone = { ...phoneNumber, [field]: newValue };
    setPhoneNumber(updatedPhone);

    // Combine values with extension first
    const combinedValue = `${updatedPhone.ext}:${updatedPhone.main}`;
    
    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        name,
        value: combinedValue
      }
    };
    handleChange(syntheticEvent);
  };

  return (
    <FieldWrapper $disableMargin={disableMargin}>
      {required && !phoneNumber.main && <RequiredStar>*</RequiredStar>}
      <div style={{ display: 'flex', gap: '8px' }}>
        <Input
          $error={!!(errors && touched)}
          $hasValue={!!phoneNumber.main}
          $required={required && !phoneNumber.main}
          id={id}
          type="tel"
          name={name}
          value={phoneNumber.main}
          onChange={handleInputChange('main')}
          onBlur={handleBlur}
          placeholder={placeholder}
          style={{ flex: 2 }}
        />
        <Input
          type="text"
          value={phoneNumber.ext}
          onChange={handleInputChange('ext')}
          onBlur={handleBlur}
          placeholder="ext."
          style={{ flex: 1 }}
          $hasValue={!!phoneNumber.ext}
          $error={false}
        />
      </div>
      {errors && touched && <ErrorMsg>{errors}</ErrorMsg>}
    </FieldWrapper>
  );
};

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProfileUserSideBar } from "../../../../components/ProfileUserSideBar";
import { UserProfileProjects } from "../../../../components/UserProfileProjects";
import { ProfileUserHeader } from "../../../../components/ProfileUserHeader";
import { ProfilePageWrapper } from "../../../Profile/styles";
import { requestUserProfile } from "../../../../store/slices/profiles";

export const UserById = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestUserProfile({ uid: id }));
  }, [dispatch, id]);

  return (
    <>
      <ProfileUserHeader />

      <ProfilePageWrapper>
        <ProfileUserSideBar />
        <UserProfileProjects />
      </ProfilePageWrapper>
    </>
  );
};

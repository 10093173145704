import styled, { css } from "styled-components";
import { Field } from "formik";

export const ErrorMsg = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.alertText};
  padding-left: 10px;
`;

export const FieldWrapper = styled.div<{ $disableMargin?: boolean }>`
  position: relative;
  width: 100%;
  ${({ $disableMargin }) => ($disableMargin ? "" : "margin-top: 8px")};
`;

export const RequiredStar = styled.div`
  position: absolute;
  z-index: 100;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.alertText};
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const inputStyle = css`
  height: 36px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  outline: none;
  padding: 0 14px;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`;

const fieldErrorState = css`
  background-color: ${({ theme }) => theme.alertBackground};
  border: 1px solid ${({ theme }) => theme.alertBackground};

  &:hover {
    background: ${({ theme }) => theme.alertBackground};
  }
`;

export const Input = styled(Field)<{
  $hasValue?: boolean;
  $error: boolean;
  $required?: boolean;
}>`
  ${inputStyle};
  position: relative;
  background-color: ${({ theme, $hasValue }) =>
    $hasValue ? theme.neutralBackgroundBase : theme.neutralBackground};
  ${({ $error }) => ($error ? fieldErrorState : "")};
  ${({ $required }) => ($required ? "padding-left: 20px" : "")};
  width: 100%;
  color: ${({ theme }) => theme.neutralText};
`;

import { PayloadAction } from "@reduxjs/toolkit";
import { IGetProfile, IProfile, IUpdateProfile } from "../../types/profile";
import { ServerResponse } from "../../models";
import { put, call, takeLatest } from "redux-saga/effects";
import { setProfiles, setUserProfileById } from "../../store/slices/profiles";
import { toast } from "react-toastify";
import {
  getProfile,
  updateMeProfile,
  deleteProfile,
  updateProfile,
  getProfileById,
} from "../../api/profiles";

export function* handleGetProfile({ payload }: PayloadAction<IProfile>) {
  const { response, error }: ServerResponse = yield call(getProfile);

  if (error) {
    console.error("Get profile failed", error);
    // If API call fails, we'll keep using the localStorage data loaded in the reducer
    return;
  }

  if (response?.status === 200) {
    yield put(setProfiles(response.data));
    payload?.callbacks?.onSuccess?.(response.data);
  }
}

export function* handleDeleteProfile({ payload }: PayloadAction<IProfile>) {
  const { response }: ServerResponse = yield call(deleteProfile);

  if (response?.status === 200) {
    payload?.callbacks?.onSuccess();
    toast.success("Profile deleted");
  }
}

export function* handleUpdateMeProfile({
  payload,
}: PayloadAction<IUpdateProfile>) {
  const { response, error }: ServerResponse = yield call(
    updateMeProfile,
    payload
  );
  if (error) {
    console.error("Update profile failed", error);
  }

  if (response?.status === 200) {
    yield put(setProfiles(response.data));
    payload?.callbacks?.onSuccess();
    toast.success("Profile saved");
  }
}

export function* handleUpdateProfile({
  payload,
}: PayloadAction<IUpdateProfile>) {
  const { response, error }: ServerResponse = yield call(
    updateProfile,
    payload
  );
  if (error) {
    console.error("Update profile failed", error);
  }

  if (response?.status === 200) {
    payload?.callbacks?.onSuccess();
    toast.success("Profile saved");
  }
}

export function* handleGetProfileById({ payload }: PayloadAction<IGetProfile>) {
  const { callbacks, ...params } = payload;
  const { response }: ServerResponse = yield call(getProfileById, params);

  if (response?.status === 200) {
    yield put(setUserProfileById(response.data));
    callbacks?.onSuccess?.(response.data);
  }
}

export function* watchProfileActions() {
  yield takeLatest("profile/requestProfile", handleGetProfile);
  yield takeLatest("profile/requestDeleteProfile", handleDeleteProfile);
  yield takeLatest("profile/requestUpdateMeProfile", handleUpdateMeProfile);
  yield takeLatest("profile/requestUpdateProfile", handleUpdateProfile);
  yield takeLatest("profile/requestUserProfile", handleGetProfileById);
}

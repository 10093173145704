import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Filter } from "react-bootstrap-icons";
import { useDebounce } from "../../hooks/useDebounce";
import { Search } from "../SearchBar";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";

import {
  ButtonsWrapper,
  Close,
  Container,
  DropDownMenu,
  FilterWrapper,
  SearchInput,
  SearchWrapper,
  Separator,
  ValueItem,
  ValuesWrapper,
} from "./styles";

type Props = {
  keyName?: string;
  onChangeFilters?: (filters: Record<string, any>) => void;
  filtersList?: Record<string, any>[];
  filtersKeyName?: string;
  disabled?: boolean;
  isFilter?: boolean;
};

export const TableValuesServerFilter = ({
  keyName,
  onChangeFilters,
  filtersKeyName,
  filtersList,
  disabled = false,
  isFilter = false,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>("");
  const [focus, setFocus] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedUniqueValues, setSelectedUniqueValues] = useState<string[]>(
    []
  );
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const debounceValue = useDebounce(value, 500);

  useOnClickOutside([ref], () => {
    if (focus) {
      setIsOpenFilter(null);
      setFocus(false);
    }
  });

  useEffect(() => {
    onChangeFilters?.({ [keyName]: debounceValue });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  const handleSelectItem = (value: string) => {
    setValue("");
    const updatedValues = selectedUniqueValues?.includes(value)
      ? selectedUniqueValues?.filter((item) => item !== value)
      : [...selectedUniqueValues, value];

    onChangeFilters?.({ [filtersKeyName]: updatedValues });
    setSelectedUniqueValues(updatedValues);
  };

  const handleSelectAll = (allValues: string[]) => {
    setValue("");
    if (selectedUniqueValues?.length < allValues?.length) {
      onChangeFilters?.({ [filtersKeyName]: allValues });

      setSelectedUniqueValues(allValues);
    } else {
      onChangeFilters?.({ [filtersKeyName]: [] });

      setSelectedUniqueValues([]);
    }
  };

  const filteredList =
    filtersList?.filter((item) =>
      item?.name?.toLowerCase().includes(searchValue.toLowerCase())
    ) ?? [];

  const isOpen = focus || isOpenFilter;

  return (
    <Container
      ref={ref}
      $focus={isOpen}
      onMouseDown={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      $disabled={disabled || !!selectedUniqueValues?.length}
    >
      <SearchInput
        disabled={disabled || !!selectedUniqueValues?.length}
        placeholder={
          !!selectedUniqueValues?.length ? "Filter is active" : "Type to filter"
        }
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus={isOpen}
      />

      <ButtonsWrapper>
        {!!value && <Close onClick={() => setValue("")} />}
        {isFilter && (
          <>
            <Separator />
            <FilterWrapper
              $active={isOpenFilter || !!selectedUniqueValues?.length}
              onClick={() => setIsOpenFilter((prev) => !prev)}
            >
              <Filter />
            </FilterWrapper>
          </>
        )}
      </ButtonsWrapper>

      {isFilter && isOpenFilter && (
        <DropDownMenu>
          <SearchWrapper>
            <Search width="xs" onChange={(value) => setSearchValue(value)} />
          </SearchWrapper>
          <ValuesWrapper>
            <ValueItem
              $selected={filteredList.every((item) =>
                selectedUniqueValues.includes(item?.id)
              )}
            >
              <CheckBox
                onChange={() =>
                  handleSelectAll(filtersList?.map((item) => item?.id) ?? [])
                }
                checked={filtersList?.every((item) =>
                  selectedUniqueValues?.includes(item?.id)
                )}
              />
              Select All
            </ValueItem>
            {filteredList?.map((item) => {
              const isSelected = selectedUniqueValues?.includes(item?.id);
              return (
                <ValueItem $selected={isSelected} key={item.id}>
                  <CheckBox
                    onChange={() => handleSelectItem(item?.id)}
                    checked={isSelected}
                    status={isSelected ? "checked" : "none"}
                  />
                  {item.name}
                </ValueItem>
              );
            })}
          </ValuesWrapper>
        </DropDownMenu>
      )}
    </Container>
  );
};

import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Field } from "formik";

import backgroundImage from "../../assets/auth-background.png";
import { ReactComponent as MainLogo } from "../../assets/logo_90x24.svg";
import { ReactComponent as EmailVerification } from "../../assets/emailVerification.svg";
import { ReactComponent as SuccesPassChanged } from "../../assets/succesPassChanged.svg";
import {
  X,
  ChevronCompactRight,
  CheckSquareFill,
  SquareFill,
  EyeFill,
  ArrowRight,
  EyeSlash,
  SlashLg,
} from "react-bootstrap-icons";

import { textFadeInUp } from "../../styles";

const inputStyle = css`
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  outline: none;
  padding: 0 10px;

  &:hover {
    background: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus {
    background: ${({ theme }) => theme.neutralBackgroundBase};
    border: 1px solid ${({ theme }) => theme.primaryBorder};
    caret-color: ${({ theme }) => theme.primaryText};
  }
  &::placeholder {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) => theme.neutralBackgroundBase} inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.neutralText} !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) => theme.neutralBackgroundHover} inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.neutralText} !important;
  }
`;

const fieldErrorState = css`
  background: ${({ theme }) => theme.alertBackground};
  border: 1px solid ${({ theme }) => theme.alertBackground};

  &:hover {
    background: ${({ theme }) => theme.alertBackground};
  }
`;

// --------------------> PAGE GENERAL <--------------------

export const Container = styled.div`
  display: flex;
  height: 100dvh;
  align-items: center;
`;
export const Background = styled.div`
  position: absolute;
  right: 0;
  height: 100dvh;
  width: 100dvw;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const AuthPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  border-radius: 16px;
  min-width: 560px;
  margin-left: 20px;
  height: calc(100dvh - 40px);
  background: ${({ theme }) => theme.neutralBackgroundBase};
`;
export const LogoWrapper = styled.div`
  display: flex;
  margin: 80px;
  margin-bottom: 40px;
  width: 560px;
`;
export const Logo = styled(MainLogo)`
  cursor: pointer;
`;

// --------------------> AUTH WRAPPER <--------------------

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 560px;
  margin: 0 80px 80px 80px;
  overflow: auto;
`;

export const AuthMethod = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AuthHeading = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: ${({ theme }) => theme.neutralText};
`;

export const SignInDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const FieldWrapper = styled.div<{ $width: string | undefined }>`
  position: relative;
  ${({ $width }) => ($width ? `width: ${$width};` : "")}
`;

export const Credentials = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  &:first-child {
    margin-top: 0;
  }
  input {
    width: 100%;
  }
`;

export const Input = styled(Field)<{ $hasValue?: boolean; $error: boolean }>`
  ${inputStyle};
  position: relative;
  width: 100%;
  margin-top: 16px;
  padding-top: ${({ $hasValue }) => $hasValue && "10px"};
  height: 40px;
  color: ${({ theme }) => theme.neutralText};
  background-color: ${({ theme, $hasValue }) =>
    $hasValue ? theme.neutralBackgroundBase : theme.neutralBackground};
  ${({ $error }) => ($error ? fieldErrorState : "")};
`;

const fieldLabelStyles = css`
  transition: all 0.3s ease;
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
  position: absolute;
  top: 21px;
  left: 11px;
  animation: 0.4s ${textFadeInUp} ease forwards;
  z-index: 1;
`;

export const FieldLabel = styled.label<{ $show?: boolean }>`
  ${({ $show }) => ($show ? fieldLabelStyles : "display: none")}
`;

export const ErrorMsg = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.alertText};
  padding-top: 4px;
`;

export const WrapperIcons = styled.div`
  position: absolute;
  top: 25px;
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const CancelInput = styled(X)`
  cursor: pointer;
  margin-right: 7px;
  height: 20px;
  width: 20px;
`;

export const ShowPassword = styled(EyeSlash)`
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  padding-left: 7px;
  cursor: pointer;
  width: 27px;
  height: 20px;
`;

export const HidePassword = styled(EyeFill)`
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  padding-left: 7px;
  cursor: pointer;
  width: 27px;
  height: 20px;
`;

export const Slash = styled(SlashLg)`
  display: block;
`;

export const Arrow = styled(ChevronCompactRight)`
  display: none;
`;

export const EmailVerificationSVG = styled(EmailVerification)`
  margin-bottom: 40px;
`;

export const ForgotPassword = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText};
  /* margin-top: 8px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    ${Slash} {
      display: none;
    }

    ${Arrow} {
      display: block;
    }

    color: ${({ theme }) => theme.primaryTextHover};
  }
`;

export const SignInButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
`;

export const SignUpButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  align-items: start;
`;

export const TermsText = styled.label<{ $checked?: boolean }>`
  color: ${({ theme, $checked }) =>
    $checked ? theme.neutralText : theme.neutralTextWeak};
`;

export const TermsWrapper = styled.div``;

export const CheckboxHovered = styled(CheckSquareFill)`
  margin-top: 1px;
  display: none;
  flex: none;
`;

export const Checkbox = styled(SquareFill)`
  display: block;
  cursor: pointer;
  margin-top: 1px;
  flex: none;
`;

export const CheckboxChecked = styled(CheckSquareFill)`
  cursor: pointer;
  margin-top: 1px;
  flex: none;
`;

export const TermsOfService = styled.div`
  display: flex;
  gap: 10px;
  max-width: 261px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};

  &:hover {
    ${TermsText} {
      color: ${({ theme }) => theme.neutralText};
    }
    ${Checkbox} {
      display: none;
    }
    ${CheckboxHovered} {
      display: block;
    }
  }
`;

export const TermsErrorMsg = styled.div`
  padding-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.alertText};
`;

export const TermsOfServiceLink = styled(Link)`
  color: ${({ theme }) => theme.primaryText};
`;

export const RegisterDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;

export const SecondaryButtonWrapper = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.neutralText};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 8px;
  margin-top: auto;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
// --------------------> DIVIDERS <--------------------

export const DividerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10%;
  margin-top: 24px;
`;

export const DividerLine = styled.div`
  background: ${({ theme }) => theme.neutralBorder};
  border-radius: 10px;
  width: 165px;
  height: 1px;
`;

export const DividerDot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: ${({ theme }) => theme.neutralBorder};
`;

// --------------------> CONFIRMATION <--------------------

export const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConfirmationHeading = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;

export const Email = styled.p`
  font-weight: bold;
`;

export const ConfirmationsDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  padding: 8px 0 32px 0;
`;

export const InviteEmail = styled.input`
  ${inputStyle};
  margin-bottom: 32px;
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PasswordResetDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;

export const SendIcon = styled(ArrowRight)`
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(101deg)
    brightness(111%) contrast(101%);
`;

// --------------------> Reset Password <--------------------

export const ResetPassInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

export const ResetPassConfirm = styled(SuccesPassChanged)`
  margin-bottom: 32px;
`;

import { useEffect, useState } from "react";
import {
  AddedOn,
  CheckboxWrapper,
  EmailAddress,
  LastActive,
  More,
  Name,
  Position,
  Projects,
  Team,
  Chip,
  UserDataRow,
  Role,
  TeamsPopUp,
} from "./styled";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import { ThreeDots } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { selectNewUser } from "../../../../store/slices/userManagement";
import { getSelectedUsers } from "../../../../store/selectors/userManagement";
import { createPortal } from "react-dom";
import useMeasure from "react-use-measure";
import UserRowMenu from "../UserRowMenu";
import { getProfiles } from "../../../../store/selectors/profiles";
import { useAbilityContext } from "../../../../casl/context";
import { AvatarCard } from "../../../AvatarCard";
import { getInitials } from "../../../../helpers/getInitials";
import { IFullUser } from "../../../../models/Teams";

type Props = {
  uuid: string;
  avatar: any;
  name: string;
  email: string;
  position: string;
  team?: string[];
  role?: string;
  projects?: number;
  addedOn: string;
  lastActive: string;
  teamTable?: boolean;
  teamMemberId?: string;
  user?: IFullUser;
};

interface IPopUp {
  coords: {
    pageX: any;
    pageY: any;
    clientX: any;
    clientY: any;
  };
}

const UsersRow = ({
  uuid,
  avatar,
  name,
  email,
  position,
  team,
  role,
  projects,
  addedOn,
  lastActive,
  teamTable,
  teamMemberId,
  user,
}: Props) => {
  const dispatch = useDispatch();
  const [ref, bounds] = useMeasure();

  const profile = useSelector(getProfiles);
  const ability = useAbilityContext();
  const selectedUsers = useSelector(getSelectedUsers);

  const [tooltip, setTooltip] = useState<IPopUp | null>(null);
  const [menu, setMenu] = useState<IPopUp | null>(null);
  const [selectId, setSelectId] = useState(teamTable ? teamMemberId : uuid);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const id = teamTable ? teamMemberId : uuid;
    setSelectId(id);
    setIsSelected(selectedUsers.includes(id));
  }, [teamTable, teamMemberId, uuid, selectedUsers]);

  const handleSelect = () => {
    dispatch(selectNewUser(selectId));
  };

  const canEdit = profile.isAdmin || role !== "manager";
  
  const canUpdate = teamTable ? ability.can("update", "teamUsers") : ability.can("update", "users");
  const canOpenMenu = teamTable ? ability.can("delete", "teamUsers") : ability.can("delete", "users");

  return (
    <>
      <UserDataRow $selected={isSelected}>
        {canUpdate && (
          <CheckboxWrapper>
            {canEdit && (
              <CheckBox
                onChange={handleSelect}
                checked={isSelected}
              />
            )}
          </CheckboxWrapper>
        )}
        <Name>
          <AvatarCard avatar={avatar} size="xs" initials={getInitials(name)} />
          {name}
        </Name>
        <EmailAddress>{email}</EmailAddress>
        {!!role && (
          <Role>
            <Chip $role={role}>{role}</Chip>
          </Role>
        )}

        <Position>{position}</Position>
        {!!team && (
          <Team>
            {!team.length && <Chip $disabled>No Team</Chip>}

            {team.slice(0, 1).map((item) => (
              <Chip>{item}</Chip>
            ))}

            {team.length - 1 > 0 && (
              <Chip
                onMouseMove={(event: any) => {
                  const { pageX, pageY, clientX, clientY } = event;
                  const coords = { pageX, pageY, clientX, clientY };

                  setTooltip({ coords });
                }}
                onMouseLeave={() => setTooltip(null)}
              >
                +{team.length - 1}
              </Chip>
            )}
          </Team>
        )}
        {typeof projects === "number" && (
          <Projects>
            <Chip>{projects}</Chip>
          </Projects>
        )}
        <AddedOn>{addedOn}</AddedOn>
        <LastActive>{lastActive}</LastActive>
        {(canOpenMenu && canUpdate) && (
          <More>
            {canEdit && (
              <ThreeDots
                onClick={(event: any) => {
                  const { pageX, pageY, clientX, clientY } = event;
                  const coords = { pageX, pageY, clientX, clientY };

                  setMenu({ coords });
                }}
              />
            )}
          </More>
        )}
      </UserDataRow>
      {tooltip &&
        createPortal(
          <TeamsPopUp
            ref={ref}
            $left={tooltip.coords.pageX - 20}
            $top={
              tooltip.coords.pageY - (bounds.height ? bounds.height + 15 : 150)
            }
            $opacity={Number(!!bounds.height)}
          >
            {team?.map((item) => (
              <Chip>{item}</Chip>
            ))}
          </TeamsPopUp>,
          document.body
        )}
      {menu &&
        createPortal(
          <UserRowMenu
            user={user}
            cords={menu.coords}
            setMenu={setMenu}
            uuid={uuid}
            teamMemberId={teamMemberId}
            isTeamTable={teamTable}
          />,
          document.body
        )}
    </>
  );
};

export default UsersRow;

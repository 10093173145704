import React, { useEffect, useState, useRef } from "react";
import {
  ButtonsWrapper,
  Close,
  Container,
  DropDownMenu,
  FilterWrapper,
  SearchInput,
  SearchWrapper,
  Separator,
  ValueItem,
  ValuesWrapper,
} from "./styles";
import { Filter } from "react-bootstrap-icons";
import { Search } from "../SearchBar";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";
import { applyFilters } from "./utils/filters";
import { IActiveFilters } from "../UserManagementComponents/Users/UsersList";
import { useOnClickOutside } from "usehooks-ts";

type FilterProps<T, K extends keyof T> = {
  keyName: K;
  subKeyName?: keyof T[K][keyof T[K]];
  subKeyName2?: keyof T[K][keyof T[K]];
  dataList: T[];
  setIsOpenFilter: React.Dispatch<React.SetStateAction<K | null>>;
  isOpenFilter: K | null;
  activeFilters: IActiveFilters<T>;
  setActiveFilters: React.Dispatch<React.SetStateAction<IActiveFilters<T>>>;
  disabled?: boolean;
  filter?: boolean;
};

const TableValuesFilter = <T, K extends keyof T>({
  keyName,
  subKeyName,
  subKeyName2,
  dataList,
  setIsOpenFilter,
  isOpenFilter,
  activeFilters,
  setActiveFilters,
  disabled = false,
  filter = true,
}: FilterProps<T, K>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const allUniqueValues = dataList ? [
    ...new Set(
      dataList?.flatMap((item) => {
        let value;
        if (subKeyName && subKeyName2 && item[keyName]) {
          const nestedObj = item[keyName] as any;
          value = nestedObj[subKeyName]?.[subKeyName2];
        } else if (subKeyName && item[keyName]) {
          const nestedObj = item[keyName] as any;
          value = nestedObj[subKeyName];
        } else {
          value = item[keyName];
        }
        return Array.isArray(value) ? value.map(String) : [String(value)];
      })
    ),
  ] : [];

  const uniqueValues = [
    ...new Set(
      applyFilters({
        skip: keyName,
        activeFilters,
        data: dataList,
      }).flatMap((item) => {
        let value;
        if (subKeyName && subKeyName2 && item[keyName]) {
          const nestedObj = item[keyName] as any;
          value = nestedObj[subKeyName]?.[subKeyName2];
        } else if (subKeyName && item[keyName]) {
          const nestedObj = item[keyName] as any;
          value = nestedObj[subKeyName];
        } else {
          value = item[keyName];
        }
        return Array.isArray(value) ? value.map(String) : [String(value)];
      })
    ),
  ];

  const [value, setValue] = useState<string>("");
  const [focus, setFocus] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedUniqueValues, setSelectedUniqueValues] =
    useState<string[]>(uniqueValues);

  useOnClickOutside([ref], () => {
    if (focus) {
      setIsOpenFilter(null)
      setFocus(false)
    }
  });

  const updateFilterValues = (updatedValues: string[]) => {
    const allActiveFilters = Object.keys(activeFilters);
    const existCurrentFilter = allActiveFilters.includes(String(keyName));
    const existAllUniqueValuesInUpdatedData = uniqueValues.every((value) =>
      updatedValues.includes(value)
    );

    if (existCurrentFilter) {
      if (existAllUniqueValuesInUpdatedData) {
        const newFilter = Object.fromEntries(
          Object.entries(activeFilters).filter(([key]) => key !== keyName)
        ) as IActiveFilters<T>;

        setActiveFilters(newFilter);
      } else {
        setActiveFilters((prev) => ({
          ...prev,
          [keyName]: {
            ...prev[keyName],
            filter: updatedValues,
            subKey: subKeyName ? String(subKeyName) : undefined,
            subKey2: subKeyName2 ? String(subKeyName2) : undefined,
          },
        }));
      }
    } else {
      setActiveFilters((prev) => ({
        ...prev,
        [keyName]: {
          filter: updatedValues,
          subKey: subKeyName ? String(subKeyName) : undefined,
          subKey2: subKeyName2 ? String(subKeyName2) : undefined,
        },
      }));
    }
  };

  const filtredUniqueValuesList = uniqueValues.filter((item) =>
    item.toLowerCase().includes(searchValue.toLowerCase())
  );

  const updateFilterSearch = (value: string) => {
    const allActiveFilters = Object.keys(activeFilters);
    const existCurrentFilter = allActiveFilters.includes(String(keyName));

    if (existCurrentFilter) {
      if (!value && !activeFilters[keyName]?.filter) {
        const newFilter = Object.fromEntries(
          Object.entries(activeFilters).filter(([key]) => key !== keyName)
        ) as IActiveFilters<T>;

        setActiveFilters(newFilter);
      } else {
        setActiveFilters((prev) => ({
          ...prev,
          [keyName as string]: {
            ...prev[keyName],
            search: value,
            subKeyName: subKeyName ? String(subKeyName) : undefined,
            subKeyName2: subKeyName2 ? String(subKeyName2) : undefined,
          },
        }));
      }
    } else if (value) {
      setActiveFilters((prev) => ({
        ...prev,
        [keyName]: {
          search: value,
          subKeyName: subKeyName ? String(subKeyName) : undefined,
          subKeyName2: subKeyName2 ? String(subKeyName2) : undefined,
        },
      }));
    }
  };

  const handleSelectAll = () => {
    if (searchValue) {
      const ifAllExist = filtredUniqueValuesList.every((item) =>
        selectedUniqueValues.includes(item)
      );

      if (ifAllExist) {
        const filterWithoutSelectedData = selectedUniqueValues.filter(
          (item) => !filtredUniqueValuesList.includes(item)
        );
        setSelectedUniqueValues(filterWithoutSelectedData);
        updateFilterValues(filterWithoutSelectedData);
      } else {
        const combineSelectedValues = [
          ...new Set([...filtredUniqueValuesList, ...selectedUniqueValues]),
        ];
        setSelectedUniqueValues(combineSelectedValues);
        updateFilterValues(combineSelectedValues);
      }
    } else {
      if (uniqueValues.length > selectedUniqueValues.length) {
        setSelectedUniqueValues(allUniqueValues);
        updateFilterValues(allUniqueValues);
      } else {
        setSelectedUniqueValues([]);
        updateFilterValues([]);
      }
    }
  };

  const handleSelectItem = (value: string) => {
    const updatedValues = selectedUniqueValues.includes(value)
      ? selectedUniqueValues.filter((item) => item !== value)
      : [...selectedUniqueValues, value];

    updateFilterValues(updatedValues);
    setSelectedUniqueValues(updatedValues);
  };

  useEffect(() => {
    updateFilterSearch(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const isOpen = focus || (isOpenFilter === keyName && subKeyName === activeFilters[keyName]?.subKeyName && subKeyName2 === activeFilters[keyName]?.subKeyName2)
  return (
    <Container
      ref={ref}
      $focus={isOpen}
      onMouseDown={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      $disabled={disabled || !!activeFilters?.[keyName]?.filter}
    >
      <SearchInput
        disabled={disabled || !!activeFilters?.[keyName]?.filter}
        placeholder={
          !!activeFilters[keyName]?.filter
            ? "Filter is active"
            : "Type to filter"
        }
        value={!!activeFilters[keyName]?.filter ? "Filter is active" : value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus={isOpen}
      />

      <ButtonsWrapper>
        {!!value && <Close onClick={() => setValue("")} />}
        {filter && (
          <>
            <Separator />
            <FilterWrapper
              $active={!!activeFilters[keyName]?.subKeyName2 || !!activeFilters[keyName]?.subKeyName || !!activeFilters[keyName]}
              onClick={() =>
                setIsOpenFilter((prev) => (prev === keyName ? null : keyName))
              }
            >
              <Filter />
            </FilterWrapper>
          </>
        )}
      </ButtonsWrapper>

      {filter && isOpenFilter === keyName && (
        // onMouseLeave={() => { setIsOpenFilter(null); setFocus(false) }}
        <DropDownMenu >
          <SearchWrapper>
            <Search width={"xs"} onChange={(value) => setSearchValue(value)} />
          </SearchWrapper>
          <ValuesWrapper>
            <ValueItem
              $selected={filtredUniqueValuesList.every((item) =>
                selectedUniqueValues.includes(item)
              )}
            >
              <CheckBox
                onChange={handleSelectAll}
                checked={filtredUniqueValuesList.every((item) =>
                  selectedUniqueValues.includes(item)
                )}
                status={
                  filtredUniqueValuesList.every((item) =>
                    selectedUniqueValues.includes(item)
                  )
                    ? "checked"
                    : filtredUniqueValuesList.filter((item) =>
                      selectedUniqueValues.includes(item)
                    ).length
                      ? "indeterminate"
                      : "none"
                }
              />
              Select All
            </ValueItem>
            {filtredUniqueValuesList.map((item) => {
              const isSelected = selectedUniqueValues.includes(item);
              return (
                <ValueItem $selected={isSelected}>
                  <CheckBox
                    onChange={() => handleSelectItem(item)}
                    checked={isSelected}
                    status={isSelected ? "checked" : "none"}
                  />
                  {item}
                </ValueItem>
              );
            })}
          </ValuesWrapper>
        </DropDownMenu>
      )}
    </Container>
  );
};

export default TableValuesFilter;

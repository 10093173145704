import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AddNewPage,
  AddNewPageWrapper,
  FeaturesSection,
  MenuWrapper,
  PageItemsWrapper,
  PagesSection,
  RecommendedWidgetsWrapper,
  WidgetsCount,
  WidgetsTitle,
  Settings,
  SettingsBlock,
  DotSeparator,
  StaticWrapper,
  FileIcon,
  FileBlock,
  DownloadIcon,
  NeutralButtonBlock,
} from "./styles";

import { setActiveModal } from "../../store/slices/modals";
import { PageTab } from "../PageTab";
import {
  getAllPages,
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentProjectData,
  getPageSettings,
} from "../../store/selectors/projects";

import {
  getIsEditMode,
  getIsPageTypeSelect,
  getIsPublicMode,
} from "../../store/selectors/main";
import { OptionsInterface } from "../YearFiltering";
import { ScrollWrapper } from "./components/ScrollWrapper";
import {
  setIsOpenThumbnailsScroll,
  triggerPDFGeneration,
} from "../../store/slices/pdfSlice";
import {
  setIsPageTypeSelect,
  setPageIdSelected,
  setReportActionType,
} from "../../store/slices/appConfig";
import { pdfGenerationProgress } from "../../store/selectors/reportPDF";
import { getAiKpis } from "../../store/selectors/widgets";
import Search from "../Search";
import { searchOptions } from "../Search/mock";

const options: OptionsInterface[] = [
  { label: "2017", value: "2017" },
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
];

export const ProjectMenu = () => {
  const currentPage = useSelector(getPageSettings);
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const isEditMode = useSelector(getIsEditMode);
  const pages = useSelector(getAllPages);
  const pageSettings = useSelector(getPageSettings);
  const isPublicRoute = useSelector(getIsPublicMode);
  const project = useSelector(getCurrentProjectData);
  const dispatch = useDispatch();
  const generatePDFProgress = useSelector(pdfGenerationProgress);
  const [, setYearsFilter] = useState<OptionsInterface[]>([]);
  const [firstLoad, setFirstLoad] = useState<string | undefined>();
  const [kpiWidgetsNr, setKpiWidgetsNr] = useState<number>(0);
  const isPageTypeSelect = useSelector(getIsPageTypeSelect);
  const pageItems = useMemo(() => pages?.items, [pages?.items]);
  const aiKpis = useSelector(getAiKpis);
  const pageKpi = useSelector(getCurrentPageKpi);

  useEffect(() => {
    if (pageWidgets.count && firstLoad !== pageSettings.id) {
      setFirstLoad(pageSettings.id);
      const item = pageWidgets.items[0];

      let data;
      if (item.chartType === "mapChart") {
        data = item?.layers?.[0]?.data;
      } else {
        data = item?.data;
      }

      if (data) {
        const mappedYears = data.reduce((uniqueYears, entry) => {
          if (!uniqueYears.includes(entry.year)) {
            uniqueYears.push(entry.year);
          }
          return uniqueYears;
        }, []);

        const years = mappedYears
          .map((year: any) => parseInt(year, 10))
          .filter(
            (year: any) =>
              !Number.isNaN(year) && year !== Infinity && year !== -Infinity
          );

        years.sort((a: any, b: any) => a - b);

        if (years.length) {
          const yearsOptions = years.map((year: any) => ({
            value: String(year),
            label: String(year),
          }));
          setYearsFilter(yearsOptions);
        } else {
          setYearsFilter(options);
        }
      } else {
        setYearsFilter(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad, pageWidgets.count, pageWidgets.items]);

  const handleAddPage = () => {
    if (!pages?.items?.length) {
      dispatch(setIsPageTypeSelect(false));
      dispatch(
        setActiveModal({
          id: "projectSettingsModal",
          createOptions: true,
        })
      );
    } else {
      dispatch(setIsPageTypeSelect(true));
      dispatch(setPageIdSelected(null));
    }
  };

  const ConditionalScrollWrapper = ({
    condition,
    children,
  }: React.PropsWithChildren<{ condition: any }>) => (
    <>{condition ? <ScrollWrapper>{children}</ScrollWrapper> : children}</>
  );

  useEffect(() => {
    if (pageKpi?.count === 0 && aiKpis?.length > 0) {
      setKpiWidgetsNr(1);
    }
  }, [aiKpis?.length, pageKpi?.count]);

  return (
    <StaticWrapper>
      <ConditionalScrollWrapper
        condition={["storytelling", "comparison"].includes(
          currentPage?.dashType
        )}
      >
        <MenuWrapper>
          <PagesSection>
            <PageItemsWrapper
              onClick={() => {
                dispatch(setIsPageTypeSelect(false));
              }}
            >
              {!!pages?.items?.length &&
                pages?.items?.map((item) => (
                  <React.Fragment key={item.id}>
                    <PageTab
                      tabId={item.id!}
                      name={item.name}
                      dashType={item.dashType}
                      isPublic={item.isPublic}
                    />
                  </React.Fragment>
                ))}
            </PageItemsWrapper>
            {!isPublicRoute && isEditMode ? (
              <AddNewPageWrapper onClick={handleAddPage}>
                <AddNewPage />
              </AddNewPageWrapper>
            ) : null}
          </PagesSection>

          {!!pageItems && !isPageTypeSelect && (
            <FeaturesSection>
              {currentPage?.dashType !== "storytelling" && (
                <>
                  {/* <DropDown
                  title="Race / Ethnicity"
                  selectedItem={"All"}
                  filterOptions={["All", "Male", "Female"]}
                />
                <DropDown
                  title="Measure"
                  selectedItem={"All"}
                  filterOptions={["All", "Male", "Female"]}
                /> */}
                  {/* <Button
                    onClick={downloadScreenshot}
                    variant={"secondary"}
                    size={"medium"}
                    name="Synchronize"
                    icon={<ArrowRepeat />}
                  />
                  {pageSettings?.showFilters ||
                    pageSettings?.showSearch ||
                    isEditMode ? (
                    <DotSeparator />
                  ) : (
                    ""
                  )} */}
                  {/* {!!pageItems.length && pageSettings?.showFilters ? (
                    <YearFiltering years={years} />
                  ) : null} */}
                  {/* <DropDown
                  title="Year"
                  selectedItem={"All"}
                  filterOptions={["All", "Male", "Female"]}
                /> */}
                  {/* <DotSeparator /> */}
                  {!!pageItems.length && pageSettings?.showSearch ? (
                    <Search searchOptions={searchOptions} />
                  ) : (
                    ""
                  )}
                  {!!pageItems.length && pageSettings?.showSearch ? (
                    <DotSeparator />
                  ) : (
                    ""
                  )}
                </>
              )}
              {currentPage.dashType === "report" && (
                <>
                  <NeutralButtonBlock
                    onClick={() => {
                      dispatch(setIsOpenThumbnailsScroll());
                    }}
                    disabled={generatePDFProgress > 0}
                  >
                    <FileIcon />
                  </NeutralButtonBlock>
                  <NeutralButtonBlock
                    onClick={() => {
                      dispatch(setReportActionType("update"));
                    }}
                  >
                    <Settings />
                  </NeutralButtonBlock>
                  <FileBlock
                    onClick={() => {
                      dispatch(triggerPDFGeneration());
                    }}
                    disabled={generatePDFProgress > 0}
                  >
                    <DownloadIcon />
                  </FileBlock>
                </>
              )}
              {!!pageItems.length &&
                isEditMode &&
                !isPublicRoute &&
                currentPage.dashType !== "report" && (
                  <>
                    <SettingsBlock
                      onClick={() => {
                        dispatch(
                          setActiveModal({ id: "projectSettingsModal" })
                        );
                      }}
                    >
                      <Settings />
                    </SettingsBlock>
                    <RecommendedWidgetsWrapper
                      onClick={() =>
                        dispatch(
                          setActiveModal({
                            id: "pageRecommendedWidgetsModal",
                            firstItem: pageWidgets.count + 1,
                          })
                        )
                      }
                    >
                      <WidgetsCount>
                        {project?.suggestionsCount! -
                          pageWidgets.count +
                          (!pageKpi?.count ? kpiWidgetsNr : 0)}
                      </WidgetsCount>
                      <WidgetsTitle>Recommended Widgets</WidgetsTitle>
                    </RecommendedWidgetsWrapper>
                  </>
                )}
            </FeaturesSection>
          )}
        </MenuWrapper>
      </ConditionalScrollWrapper>
    </StaticWrapper>
  );
};

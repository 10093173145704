import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { useDispatch, useSelector } from "react-redux";
import { Trash, Pen, DashCircle } from "react-bootstrap-icons";
import { setSelectedTeam } from "../../../../store/slices/teams";
import {
  setDeleteUserId,
  setLastTeamsUsersManagers,
} from "../../../../store/slices/users";
import { setActiveModal } from "../../../../store/slices/modals";
import { getTeams } from "../../../../store/selectors/teams";
import { IFullUser } from "../../../../models/Teams";
import {
  getLastTeamsManagersById,
  hasLastManagerById,
} from "../../../../helpers/getLastManager";

import { Container, MenuItem, Separator } from "./styles";

type Props = {
  setMenu: any;
  cords?: {
    pageX: any;
    pageY: any;
    clientX: any;
    clientY: any;
  };
  isTeamTable?: boolean;
  uuid: string;
  teamMemberId?: string;
  user?: IFullUser;
};

const UserRowMenu = ({
  cords,
  setMenu,
  isTeamTable,
  uuid,
  teamMemberId,
  user,
}: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);
  useOnClickOutside([ref], () => setMenu(null));

  const handleRemoveTeamUser = () => {
    const isLastManager = hasLastManagerById(
      teams?.find((team) => team?.id === id)?.members,
      teamMemberId
    );

    if (isLastManager) {
      dispatch(setActiveModal({ id: "lastManagerDeclined" }));
    } else {
      dispatch(setSelectedTeam(id));
      dispatch(setDeleteUserId(teamMemberId));
      dispatch(setActiveModal({ id: "removeTeamUsers" }));
    }
  };

  const handleRemoveUser = () => {
    const lastTeamsManager = getLastTeamsManagersById(teams, [uuid]);

    if (!!lastTeamsManager.length) {
      dispatch(setActiveModal({ id: "lastManagersTeamsDeclined" }));
      dispatch(setLastTeamsUsersManagers(lastTeamsManager));
    } else {
      dispatch(setActiveModal({ id: "removeUsers" }));
      dispatch(setDeleteUserId(uuid));
    }
  };

  return (
    <Container
      ref={ref}
      $top={cords?.pageY + 10}
      $left={cords?.pageX - 194}
      onMouseLeave={() => setMenu(null)}
    >
      <MenuItem
        onClick={() => {
          navigate(`/management/users/${user?.uid}`);
        }}
      >
        <Pen />
        Update Profile
      </MenuItem>
      <Separator></Separator>
      {isTeamTable ? (
        <MenuItem $delete onClick={handleRemoveTeamUser}>
          <DashCircle />
          Remove
        </MenuItem>
      ) : (
        <MenuItem $delete onClick={handleRemoveUser}>
          <Trash />
          Delete
        </MenuItem>
      )}
    </Container>
  );
};

export default UserRowMenu;

import React, { HTMLAttributes } from "react";

import AvatarBg from "../../assets/userBg.jpg";
import * as s from "./styles";

type SizeProps = "xl" | "lg" | "md" | "sm" | "xs" | "xxs";

type Props = {
  avatar?: string;
  size?: SizeProps;
  initials?: string;
} & HTMLAttributes<HTMLDivElement>;

export const AvatarCard = ({
  size = "md",
  avatar,
  initials,
  onClick,
}: Props) => {
  return (
    <s.AvatarCardContainer
      $size={size}
      onClick={onClick}
      $hasOnClick={Boolean(onClick)}
    >
      {!avatar && <s.AvatarInitials $size={size}>{initials}</s.AvatarInitials>}
      <s.AvatarImg src={avatar || AvatarBg} $size={size} />
    </s.AvatarCardContainer>
  );
};

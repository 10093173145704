import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Dragging } from "../../../../assets/dragging.svg";
import { getIsEditMode, getIsPublicMode } from "../../../../store/selectors/main";
import { useSelector } from "react-redux";
import { getCustomeCommentsCursor } from "../../../../store/selectors/comments";

const TextAreaBase = styled.textarea<{ $isEditMode?: boolean }>`
  color: ${({ theme }) => theme.neutralText};
  border: none;
  resize: none;
  width: 100%;
  background-color: transparent;

  overflow-y: hidden;

  pointer-events: ${({ $isEditMode }) => ($isEditMode ? "auto" : "none")};
  cursor: ${({ $isEditMode }) => ($isEditMode ? "text" : "default")};
  &:hover {
    background-color: ${({ theme, $isEditMode }) =>
    $isEditMode ? theme.neutralBackgroundHover : "none"};
  }

  &:focus-visible {
    background-color: ${({ theme, $isEditMode }) =>
    $isEditMode ? theme.primaryBackground : "none"};
    outline: none;
    border-radius: 5px;
  }
`;

export const TextArea = ({
  value,
  setContentItem,
  variant,
}: {
  value?: string;
  setContentItem: (val?: string) => void;
  variant: string;
}) => {
  const isEditMode = useSelector(getIsEditMode);
  const isPublic = useSelector(getIsPublicMode);
  const isCustomeCursor = useSelector(getCustomeCommentsCursor);
  const [localValue, setValue] = useState<string>();
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight(textareaRef.current);
  }, [localValue]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleInput = (e: any) => {
    if (!isEditMode) {
      return
    }
    const newValue = e.target.value;
    setValue(newValue);
    setContentItem(newValue);
  };

  const adjustTextareaHeight = (textarea: any) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  if (variant === "xl") {
    return (
      <SectionTitle
        ref={textareaRef}
        value={localValue}
        $isEditMode={!isPublic && isEditMode && !isCustomeCursor}
        onChange={handleInput}
        rows={1}
      />
    );
  }
  if (variant === "base") {
    return (
      <InformativeDescription
        ref={textareaRef}
        value={localValue}
        $isEditMode={!isPublic && isEditMode && !isCustomeCursor}
        onChange={handleInput}
        rows={1}
      />
    );
  }
  if (variant === "sm") {
    return (
      <InformativeSubDescription
        ref={textareaRef}
        value={localValue}
        $isEditMode={!isPublic && isEditMode && !isCustomeCursor}
        onChange={handleInput}
        rows={1}
      />
    );
  }

  return (
    <TextAreaBase
      ref={textareaRef}
      value={localValue}
      $isEditMode={!isPublic && isEditMode && !isCustomeCursor}
      onChange={handleInput}
      rows={1}
    />
  );
};

export const ChildrenWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;
export const SettingsWrapper = styled.div<{ $hover?: boolean }>`
  display: ${({ $hover }) => ($hover ? "flex" : "none")};
  position: absolute;
  left: 50%;
  top: 14px;
  transform: translateX(-50%);
  gap: 2px;
  z-index: 1;
  align-items: center;
  transition: 300ms all linear;
`;
export const CloseWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 15px;
  top: 14px;
`;
export const BorderedSettingsBlock = styled.div<{
  $background?: string;
  $gap?: string;
  $active?: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
  display: flex;
  padding: 2px 3px;
  gap: ${({ $gap }) => $gap || "4px"};
  align-items: center;
  transition: 0.3s;
  background: ${({ theme, $background }) =>
    $background ? theme[$background] : theme.neutralBackgroundBase};
  color: ${({ theme, $active }) => $active ? theme.neutralText : theme.neutralTextWeak};
  border: 1px solid ${({ theme, $active }) => $active ? theme.neutralBorderMedium : theme.neutralBorder};
  &:hover {
    color: ${({ theme }) => theme.neutralText};
    border: 1px solid ${({ theme }) => theme.neutralBorderMedium};
  }
  &:active {
    color: ${({ theme }) => theme.neutralText};
    background: ${({ theme }) => theme.neutralBackgroundMedium};
    border: 1px solid ${({ theme }) => theme.neutralBorder};
  }
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  margin: 0 6.5px;
  border-radius: 100%;
  background: ${({ theme }) => theme.neutralTextWeak};
`;

export const SectionWrapper = styled.div<{
  $draggable?: boolean;
  $storytelling?: boolean;
  $isEditMode?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 24px 32px;
  border: ${({ $draggable, theme }) =>
    `1px ${$draggable ? `dashed ${theme.primaryBorder}` : "solid transparent"
    }`};
  border-radius: 8px;
  padding: 24px 32px;
  &:hover {
    border: ${({ $storytelling, theme, $isEditMode }) =>
    `1px solid  ${!$isEditMode ? 'transparent' : $storytelling ? `${theme.primaryBorder}` : "rgba(84, 72, 255, 1)"
    }`};
  }
`;

export const IsDragging = styled(Dragging)`
  top: 14px;
  position: absolute;
  left: 0;
  z-index: 20;
`;

export const IsHovered = styled.div`
  top: 14px;
  position: absolute;
  left: 0;
  z-index: 20;
  background: ${({ theme }) => theme.primaryBackgroundMedium};
  width: 4px;
  height: 20px;
  border-radius: 0 2px 2px 0;
`;

export const SectionTitle = styled(TextAreaBase)`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.03em;
  text-align: left;
`;

export const ContentWrapper = styled.div<{ $flexOrientation?: string }>`
  display: flex;
  flex-direction: ${({ $flexOrientation }) => $flexOrientation};
  gap: 24px 32px;
`;

export const InformativeSection = styled.div<{ $flexOrientation?: string }>`
  display: flex;
  min-height: ${({ $flexOrientation }) =>
    $flexOrientation === "row" ? "100px" : "150px"};
  width: 100%;
  flex-direction: ${({ $flexOrientation }) => $flexOrientation};
`;

export const InformativeDescription = styled(TextAreaBase)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
`;

export const InformativeSubDescription = styled(TextAreaBase)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`;

export const BlockLoader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InformativeInsideWrapper = styled.div<{
  $addMargin?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

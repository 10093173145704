import { useEffect } from "react";

import { PageMenu } from "../../components/PageMenu";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import {
  ProfilePageWrapper,
  UserProfileHeaderUserDescription,
  UserProfileHeaderUserDetails,
  UserProfileHeaderUserName,
  UserProfileHeaderUserRoleBadge,
  UserProfileHeaderWrapper,
} from "./styles";
import { ProfileSideBar } from "../../components/ProfileSideBar";
import { UserProfileProjects } from "../../components/UserProfileProjects";
import { useSelector } from "react-redux";
import { getProfiles } from "../../store/selectors/profiles";
import { requestProfile } from "../../store/slices/profiles";
import { useDispatch } from "react-redux";
import { AvatarCard } from "../../components/AvatarCard";
import { getFullName, getInitials } from "../../helpers/getInitials";

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfiles);

  const {
    firstName = "",
    lastName = "",
    jobTitle = "",
    avatar = "",
    isAdmin = false,
  } = profile || {};

  useEffect(() => {
    dispatch(requestProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadingMenuContainer>
        <UserProfileHeaderWrapper>
          <AvatarCard
            avatar={avatar}
            size="lg"
            initials={getInitials(getFullName(firstName, lastName))}
          />
          <UserProfileHeaderUserDetails>
            <UserProfileHeaderUserName>
              {getFullName(firstName, lastName)}
            </UserProfileHeaderUserName>
            <UserProfileHeaderUserDescription>
              {jobTitle || ""}
              {isAdmin && (
                <UserProfileHeaderUserRoleBadge>
                  Admin
                </UserProfileHeaderUserRoleBadge>
              )}
            </UserProfileHeaderUserDescription>
          </UserProfileHeaderUserDetails>
        </UserProfileHeaderWrapper>
        <PageMenu />
      </HeadingMenuContainer>
      <ProfilePageWrapper>
        <ProfileSideBar />
        {!!profile?.teams?.length && <UserProfileProjects />}
      </ProfilePageWrapper>
    </>
  );
};

import React, { useRef, useState, useEffect } from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  Buttons,
  ButtonWrapper,
  Container,
  ContentWrapper,
  DownIcon,
  DropDownMenuItem,
  DropDownMenuList,
  DropDownSelect,
  Label,
  SelectContainer,
  SelectedValue,
} from "./style";
import { ISelect } from "./types";
import { useSelector } from "react-redux";
import { getUser } from "../../../store/selectors/main";
import { AccountDto } from "../../../models/Authentication";
import { jwtDecode } from "jwt-decode";
import { Button } from "../../Button";
import Category from "./components/Category";
import { useNavigate } from "react-router-dom";
import { setActiveModal } from "../../../store/slices/modals";
import { useDispatch } from "react-redux";
import { useAbilityContext } from "../../../casl/context";

type Props = {
  options: ISelect[];
  value: string;
  defaultValue?: string;
  withOutClose?: boolean;
  label?: string;
  onSelect?: (value: ISelect) => void;
  disabled?: boolean;
};

export const SelectTeam: React.FC<Props> = ({
  options,
  value,
  defaultValue,
  label,
  onSelect,
  disabled,
}: Props) => {
  const user = useSelector(getUser);
  const [account, setAccount] = useState<AccountDto | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = useAbilityContext()

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  const getInitialValue = () => {
    const valueOption = options?.find((item) => item.value === value);
    const defaultOption = options?.find((item) => item.value === defaultValue);
    return valueOption || defaultOption;
  };

  const [selectedValue, setSelectedValue] = useState<ISelect | undefined>(
    getInitialValue()
  );
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setActiveDropDown(false));

  useEffect(() => {
    const newValue = options?.find((item) => item.value === value);
    if (newValue && newValue.value !== selectedValue?.value) {
      localStorage.setItem("selectedTeam", newValue.value);
      setSelectedValue(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const handleSelectItem = (item: ISelect) => {
    setSelectedValue(item);
    setActiveDropDown(false);
    localStorage.setItem("selectedTeam", item.value);
    if (onSelect) {
      onSelect(item);
    }
  };

  const toggleDropDown = (e: React.MouseEvent) => {
    if (disabled) return;
    if ((e.target as HTMLElement).closest("button")) {
      return;
    }
    setActiveDropDown((prev) => !prev);
  };

  const NoAdminList = [
    {
      title: "Manager in",
      projects: [
        {
          option: "Support and Auxiliary",
          value: "SupportAndAuxiliary",
        },
        {
          option: "Quality Assurance",
          value: "QualityAssurance",
        },
      ],
    },
    {
      title: "Editor in",
      projects: [
        {
          option: "IT and Technical",
          value: "ITAndTechnical",
        },
        {
          option: "Research And Analytics",
          value: "ResearchAndAnalytics",
        },
      ],
    },
    {
      title: "viewer in",
      projects: [
        {
          option: "CyberPersona Architects",
          value: "CyberPersonaArchitects",
        },
        {
          option: "VirtuSynch Innovators",
          value: "VirtuSynchInnovators",
        },
      ],
    },
  ];

  return (
    <Container ref={ref}>
      <SelectContainer
        $opened={!!activeDropDown}
        $hasLabel={!!label}
        $disabled={!!disabled}
        onClick={toggleDropDown}
      >
        <ContentWrapper>
          {label && <Label>{label}</Label>}
          <SelectedValue
            $hasLabel={!!label}
            $placeholder={!!selectedValue?.option}
            $activeDropDown={activeDropDown}
          >
            {selectedValue?.option || "Select value"}
          </SelectedValue>
        </ContentWrapper>
        <Buttons>
          <DownIcon $close={activeDropDown} />
        </Buttons>
      </SelectContainer>

      {!!activeDropDown && (
        <DropDownSelect>
          <DropDownMenuList>
            {account?.isAdmin || account?.role === "owner" ? (
              <>
                {options.map((item) => {
                  const isSelected = selectedValue?.value === item.value;
                  return (
                    <DropDownMenuItem
                      key={item.value}
                      onClick={() => handleSelectItem(item)}
                      $selected={isSelected}
                    >
                      <span>{item.option}</span>
                      {item?.label ? <span>{item?.label}</span> : ""}
                    </DropDownMenuItem>
                  );
                })}
                {ability.can("create", "teams") && <ButtonWrapper>
                  <Button
                    onClick={() => {
                      navigate("/management/teams");
                      dispatch(setActiveModal({ id: "createTeamModal" }));
                    }}
                    variant={"primary"}
                    size={"full"}
                    name="Create Team"
                  />
                </ButtonWrapper>
                }

              </>
            ) : (
              <>
                {NoAdminList.map((item) => {
                  if (!item.projects.length) {
                    // eslint-disable-next-line array-callback-return
                    return;
                  }
                  return (
                    <Category
                      selectedValue={selectedValue?.value}
                      item={item}
                      handleSelectItem={handleSelectItem}
                    />
                  );
                })}
              </>
            )}
          </DropDownMenuList>
        </DropDownSelect>
      )}
    </Container>
  );
};

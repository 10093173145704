import { useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import { MultiSelect } from "../../Inputs/CustomMultiSelect/MultiSelect";
import { ContentWrapper, SelectBlock, SelectBlockTitle } from "./style";
import { CustomTextArea } from "../../Inputs/CustomTextArea";
import { useDispatch } from "react-redux";
import { requestSendUserInvite } from "../../../store/slices/users";

type ModalProps = {
  onClose: () => void;
};
interface Form {
  users: string[]
  message: string,
}

const defaultForm: Form = {
  users: [],
  message: '',
}

export const InvitePeople = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const dispatch = useDispatch();

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    handleOnClose();
    dispatch(requestSendUserInvite({
      emails: form.users,
      // message: form.message,
    }));
  }

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalContainer>
            <ModalHeading>Invite People</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ContentWrapper>
              <SelectBlock>
                <MultiSelect
                  placeholder="Emails"
                  disableRightIcons
                  disableDelete
                  value={form.users}
                  options={[]}
                  errors={['@', '.']}
                  onSelect={(item) => {
                    setForm({
                      ...form,
                      users: item.map((el) => (typeof el === 'string' ? el : el.value || String(el))),
                    });
                  }}
                />
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>
                Message
                </SelectBlockTitle>
                <CustomTextArea
                  disablePaddings
                  value={form.message}
                  name={"message"}
                  placeholder="Add a greeting message"
                  onChange={(e) => {
                    setForm({ ...form, message: e })
                  }}
                />
              </SelectBlock>
            </ContentWrapper>
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Invite"
              onClick={handleSave}
              variant={true ? "secondary" : "disabled"}
              disabled={false}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

import styled from "styled-components";
import { commonSeparatorStyle } from "../../../styles";

export const HeaderTitle = styled.p`
  margin-top: 28px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #000005;
`;
export const HeaderDescrition = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5f6877;
`;

export const HeaderCloseIcon = styled.p`
  position: absolute;
  top: 30px;
  right: 30px;
`;

export const BodyContainer = styled.div`
  overflow-y: auto;
  max-height: 260px;
  width: 100%;
`;

export const RemoveUserContainer = styled.div<{ $isLastItem?: boolean }>`
  position: relative;
  &:before {
    bottom: 0;
    ${({ $isLastItem = false }) => !$isLastItem && commonSeparatorStyle};
  }
`;

export const RemoveUserBox = styled.div<{ $alignItems?: string }>`
  padding: 8px 20px;
  display: flex;
  align-items: ${({ $alignItems = "start" }) => $alignItems};
  gap: 8px;
`;

export const RemoveAvatarBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 40%;
  overflow: hidden;
`;

export const RemoveAvatarName = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveTeamBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  flex: 0 0 60%;
`;

export const RemoveUserRole = styled.div`
  text-transform: capitalize;
`;

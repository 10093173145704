import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pencil, Trash } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Line, SubMenuWrapper } from "./styles";
import { setActiveModal } from "../../../../store/slices/modals";
import {
  requestDeleteTeam,
  setSelectedTeam,
  setMembersIdWithSingleTeam,
} from "../../../../store/slices/teams";
import { useAbilityContext } from "../../../../casl/context";
import { getTeams } from "../../../../store/selectors/teams";
import { requestAllUsers } from "../../../../store/slices/users";

interface Props {
  id: string;
  menuOpen: boolean;
  isTeamPage?: boolean;
  onCloseMenu?: () => void;
}

export const DropDown: React.FC<Props> = ({
  id,
  menuOpen,
  isTeamPage = false,
  onCloseMenu,
}) => {
  const teams = useSelector(getTeams);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useAbilityContext();

  const usersEmails = teams
    ?.find((item) => item?.id === id)
    ?.members?.map((member) => member?.email);

  const [membersTeam, setMembersTeam] = useState([]);

  useEffect(() => {
    if (!usersEmails.length) return;

    dispatch(
      requestAllUsers({
        limit: 30,
        emails: usersEmails,
        callbacks: {
          onSuccess: ({ items }) =>
            setMembersTeam(
              items?.filter((member) => member?.teams?.count === 1)
            ),
        },
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRemoveTeam = () => {
    if (id) {
      if (!!membersTeam.length) {
        dispatch(setSelectedTeam(id));
        dispatch(
          setMembersIdWithSingleTeam(membersTeam?.map((member) => member?.uid))
        );
        dispatch(setActiveModal({ id: "removeTeam" }));
      } else {
        dispatch(
          requestDeleteTeam({
            id: id,
            callbacks: {
              onSuccess: () => {
                const teamId = localStorage.getItem("selectedTeam");
                if (teamId === id) {
                  const newTeam = teams.filter((item) => item.id !== id)?.at(0);
                  localStorage.setItem("selectedTeam", newTeam.id);
                }
                dispatch(requestAllUsers({ limit: 30 }));
              },
            },
          })
        );

        if (isTeamPage) {
          navigate("/management/teams");
        }
      }

      onCloseMenu?.();
    } else {
      toast.error("Team not found");
    }
  };

  return (
    <SubMenuWrapper
      $open={menuOpen}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <li
        onClick={() => {
          dispatch(setActiveModal({ id: "editTeamModal" }));
          onCloseMenu?.();
        }}
      >
        <Pencil />
        Edit
      </li>
      {/* {!isTeamPage && (
        <li
          onClick={() => {
            dispatch(setActiveModal({ id: "inviteToTeamModal" }));
            onCloseMenu?.();
          }}
        >
          <PlusLg />
          Invite People
        </li>
      )} */}
      {/* <li
        onClick={() => {}}
      >
        <Link45deg />
        Copy Link
      </li> */}
      {ability.can("delete", "teams") && (
        <>
          <Line />
          <li onClick={handleRemoveTeam}>
            <Trash />
            Remove
          </li>
        </>
      )}
    </SubMenuWrapper>
  );
};

import React from "react";
import { ShieldLock } from "react-bootstrap-icons";

import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
} from "../ProfileSideBar/styles";
import { Status } from "./styles";

export const SecurityProfileSection = () => {
  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Security</ProfileSideBarHeading>
      <ProfileSideBarSectionWrapper>
        <ProfileSideBarAboutCardWrapper>
          <ProfileSideBarAboutCardIcon>
            <ShieldLock />
          </ProfileSideBarAboutCardIcon>
          <ProfileSideBarAboutCardDetailsWrapper>
            <ProfileSideBarAboutCardTitle>
              Two-Factor Authentication
            </ProfileSideBarAboutCardTitle>
            <ProfileSideBarAboutCardDescription>
              <Status>Enabled</Status>
            </ProfileSideBarAboutCardDescription>
          </ProfileSideBarAboutCardDetailsWrapper>
        </ProfileSideBarAboutCardWrapper>
      </ProfileSideBarSectionWrapper>
    </ProfileSideBarSectionWrapper>
  );
};

import React from "react";
import { Button } from "../../../../components/Button";
import {
  ConfirmationsDescription,
  ConfirmationHeading,
  ConfirmationWrapper,
  AuthPageWrapper,
  ResetPassInputs,
  ActionWrapper,
  AuthWrapper,
  Background,
  Container,
  LogoWrapper,
  Logo,
} from "../../styles";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { GetEmailForResetPassValidationSchema } from "../../../../constants";
import { Form, Formik } from "formik";
import { FormGroup } from "../../FormGroup";
import { useDispatch } from "react-redux";
import { requestForgotPassword } from "../../../../store/slices/auth";

type Props = {
  setStep: () => void;
  setEmail: (value: string) => void;
};

type TFormInitialValues = {
  email: string;
};

export const ForgotPasswordForm: React.FC<Props> = ({
  setStep,
  setEmail,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formInitialValues: TFormInitialValues = {
    email: "",
  };

  const handleSubmit = (values: any) => {
    dispatch(
      requestForgotPassword({
        email: values.email,
        callbacks: {
          onSuccess: () => {
            setStep();
            setEmail(values.email);
          },
        },
      })
    );
  };

  return (
    <Container>
      <Background />
      <AuthPageWrapper>
        <LogoWrapper>
          <Logo onClick={() => navigate("/")} />
        </LogoWrapper>
        <AuthWrapper>
          <ConfirmationWrapper>
            <ConfirmationHeading>Forgot Your Password?</ConfirmationHeading>
            <ConfirmationsDescription>
              No worries! Just enter your username or email, and we'll assist
              you.
            </ConfirmationsDescription>

            <Formik
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={handleSubmit}
              initialValues={formInitialValues}
              validationSchema={GetEmailForResetPassValidationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setValues,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <ResetPassInputs>
                    <FormGroup
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Username or Email"
                      errors={errors.email as string}
                      values={values.email}
                      handleChange={handleChange}
                      touched={touched.email as boolean}
                      handleBlur={handleBlur}
                      width="100%"
                      icon
                      setValues={() => setValues({ ...values, email: "" })}
                    />
                  </ResetPassInputs>
                  <ActionWrapper>
                    <Button
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      variant="neutral"
                      size="medium"
                      name="Back to Sign In"
                      iconLeft={<ArrowLeft />}
                    />
                    <Button
                      onClick={() => handleSubmit()}
                      variant={!values.email.length ? "disabled" : "secondary"}
                      disabled={!Boolean(values.email.length)}
                      size="medium"
                      name="Reset Password"
                      icon={<ArrowRight />}
                    />
                  </ActionWrapper>
                </Form>
              )}
            </Formik>
          </ConfirmationWrapper>
        </AuthWrapper>
      </AuthPageWrapper>
    </Container>
  );
};

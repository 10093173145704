import React, { useEffect, useMemo } from "react";
import { People, Compass, Buildings, Calendar3 } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { getTeams } from "../../store/selectors/teams";
import { requestAllTeams } from "../../store/slices/teams";
import {
  Chip,
  ChipsWrapper,
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
} from "../ProfileSideBar/styles";
import { capitalizeName } from "../../helpers/capitalizeName";
import { IProfile } from "../../types/profile";

type Props = {
  profile: IProfile;
};

export const ProfileAboutSection = ({ profile }: Props) => {
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);

  useEffect(() => {
    if (!teams.length) {
      dispatch(requestAllTeams({}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTeams = useMemo(() => {
    return teams?.map((team) => {
      const teamName = team.name;
      const teamRole = team?.members?.find(
        (member) => member?.user?.uid === profile?.uid
      )?.role;

      const userTeam = profile?.teams?.find((t) => t.id === team.id);

      return (
        !!userTeam && (
          <Chip $selected={false} key={team.id}>
            {teamName} <b>{capitalizeName(teamRole)}</b>
          </Chip>
        )
      );
    });
  }, [teams, profile]);

  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>About</ProfileSideBarHeading>
      <ProfileSideBarSectionWrapper>
        <ProfileSideBarAboutCardWrapper>
          <ProfileSideBarAboutCardIcon>
            <Buildings />
          </ProfileSideBarAboutCardIcon>
          <ProfileSideBarAboutCardDetailsWrapper>
            <ProfileSideBarAboutCardTitle>
              Department
            </ProfileSideBarAboutCardTitle>
            <ProfileSideBarAboutCardDescription
              $noData={!profile?.organization}
            >
              {profile?.organization || "No data"}
            </ProfileSideBarAboutCardDescription>
          </ProfileSideBarAboutCardDetailsWrapper>
        </ProfileSideBarAboutCardWrapper>
      </ProfileSideBarSectionWrapper>
      <ProfileSideBarSectionWrapper>
        <ProfileSideBarAboutCardWrapper>
          <ProfileSideBarAboutCardIcon>
            <Compass />
          </ProfileSideBarAboutCardIcon>
          <ProfileSideBarAboutCardDetailsWrapper>
            <ProfileSideBarAboutCardTitle>
              Location
            </ProfileSideBarAboutCardTitle>
            <ProfileSideBarAboutCardDescription $noData={!profile?.location}>
              {profile?.location || "No data"}
            </ProfileSideBarAboutCardDescription>
          </ProfileSideBarAboutCardDetailsWrapper>
        </ProfileSideBarAboutCardWrapper>
      </ProfileSideBarSectionWrapper>
      <ProfileSideBarSectionWrapper>
        <ProfileSideBarAboutCardWrapper>
          <ProfileSideBarAboutCardIcon>
            <People />
          </ProfileSideBarAboutCardIcon>
          <ProfileSideBarAboutCardDetailsWrapper>
            <ProfileSideBarAboutCardTitle>Teams</ProfileSideBarAboutCardTitle>
            <ChipsWrapper>{renderTeams}</ChipsWrapper>
          </ProfileSideBarAboutCardDetailsWrapper>
        </ProfileSideBarAboutCardWrapper>
      </ProfileSideBarSectionWrapper>
      <ProfileSideBarSectionWrapper>
        <ProfileSideBarAboutCardWrapper>
          <ProfileSideBarAboutCardIcon>
            <Calendar3 />
          </ProfileSideBarAboutCardIcon>
          <ProfileSideBarAboutCardDetailsWrapper>
            <ProfileSideBarAboutCardTitle>
              Added on
            </ProfileSideBarAboutCardTitle>
            <ProfileSideBarAboutCardDescription $noData={!profile?.createdAt}>
              {profile?.createdAt
                ? format(new Date(profile?.createdAt), "MM.dd.yyyy")
                : "No data"}
            </ProfileSideBarAboutCardDescription>
          </ProfileSideBarAboutCardDetailsWrapper>
        </ProfileSideBarAboutCardWrapper>
      </ProfileSideBarSectionWrapper>
    </ProfileSideBarSectionWrapper>
  );
};

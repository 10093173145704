import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserManagement {
  selectedUsers: string[];
  selectedTeam: string;
  emails: string[];
}

const initialState: IUserManagement = {
  selectedUsers: [],
  selectedTeam: "",
  emails: [],
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    selectNewUser: (state, { payload }: PayloadAction<string>) => {
      const existUser = state.selectedUsers.includes(payload)
      const newSelected = existUser ?
        state.selectedUsers.filter((item) => item !== payload)
        :
        [...state.selectedUsers, payload];

      return {
        ...state,
        selectedUsers: newSelected,
      };
    },

    selectAllUsers: (state, { payload }: PayloadAction<string[]>) => {
      if (state.selectedUsers.length < payload.length) {
        return { ...state, selectedUsers: payload }
      } else {
        return { ...state, selectedUsers: [] }
      }
    },

    resetSelectedUsers: (state) => {
      return { ...state, selectedUsers: [] }
    },

    selectTeam: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        selectedTeam: payload,
      };
    },

    setUsersEmails: (state, { payload }: PayloadAction<string>) => {
      const isExistEmails = state?.emails?.includes(payload);

      return {
        ...state,
        emails: !isExistEmails ? [...state.emails, payload] : state.emails,
      };
    },

    resetUsersEmails: (state) => {
      return { ...state, emails: [] };
    },
  },
});

export const {
  selectNewUser,
  selectAllUsers,
  resetSelectedUsers,
  selectTeam,
  setUsersEmails,
  resetUsersEmails,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
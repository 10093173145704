import styled from "styled-components";

export const Status = styled.div<{ $selected?: boolean }>`
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  background: ${({ theme }) => theme.successBackground};
  color: ${({ theme }) => theme.successText};
  border: 1px solid ${({ theme }) => theme.successBackground};
  border-radius: 2px;
`;

import React from "react";
import { ProjectCardUsersWrapper, UserIcon, UserIconWrapper } from "./styles";
import { getInitials } from "../../helpers/getInitials";
import { AvatarCard } from "../AvatarCard";

interface ProjectCardUsersInterface {
  users: { name: string; id: string; avatar?: string }[];
}

export const ProjectCardUsers = ({ users }: ProjectCardUsersInterface) => {
  return (
    <ProjectCardUsersWrapper>
      {users.slice(0, 4).map((user) => (
        <UserIconWrapper key={user?.id}>
          <AvatarCard
            key={user?.id}
            avatar={user?.avatar}
            initials={getInitials(user.name)}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </UserIconWrapper>
      ))}
      {users.length > 4 && (
        <UserIconWrapper
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <UserIcon>+{users.length - 4}</UserIcon>
        </UserIconWrapper>
      )}
    </ProjectCardUsersWrapper>
  );
};

import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";
import { generateQueryString } from "../helpers/queryGenerator";
import {
  QueryAddUserToTeam,
  QueryCreateTeam,
  QueryDeleteTeam,
  QueryDeleteTeamUser,
  QueryDeleteTeamUsersBulk,
  QueryEditTeam,
  QueryEditTeamUser,
  QueryGetTeams,
  QueryGetTeamUsers,
} from "../models/Teams";
import {
  ADD_USER_TO_TEAM,
  CREATE_TEAM,
  DELETE_TEAM,
  DELETE_TEAM_USER_BY_ID,
  DELETE_TEAM_USERS_BULK,
  EDIT_TEAM,
  EDIT_TEAM_USER_BY_ID,
  GET_TEAM_USERS,
  GET_TEAMS,
} from "./constants";

export const getAllTeams = async (query: QueryGetTeams) => {
  const queryParams = generateQueryString<QueryGetTeams>({
    ...query,
    limit: query?.limit || 20,
    orderBy: query?.orderBy || "createdAt",
    orderDirection: query?.orderDirection || "DESC",
  });

  return axios
    .get(`${GET_TEAMS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const createTeam = async (query: QueryCreateTeam) => {
  return axios
    .post(`${CREATE_TEAM}`, query)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const editTeam = async (query: QueryEditTeam) => {
  return axios
    .patch(`${EDIT_TEAM.replace('{id}', query.id)}`, query)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const addUserToTeam = async (query: QueryAddUserToTeam) => {
  return axios
    .post(`${ADD_USER_TO_TEAM.replace('{id}', query.id)}`, query)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getTeamUsersList = async (query: QueryGetTeamUsers) => {
  const queryParams = generateQueryString<QueryGetTeamUsers>({
    ...query,
    limit: query?.limit || 20,
  });

  return axios
    .get(`${GET_TEAM_USERS.replace('{id}', query.id)}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const editTeamUsersList = async (query: QueryEditTeamUser) => {
  return axios
    .patch(
      `${EDIT_TEAM_USER_BY_ID.replace('{id}', query.id).replace(
        '{userId}',
        query.userId
      )}`,
      query
    )
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteTeamUser = async (query: QueryDeleteTeamUser) => {
  return axios
    .delete(
      `${DELETE_TEAM_USER_BY_ID.replace('{id}', query.id).replace(
        '{userId}',
        query.userId
      )}`,
      {
        params: {
          id: query.id,
          userId: query.userId,
        },
      }
    )
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteTeam = async (query: QueryDeleteTeam) => {
  return axios
    .delete(`${DELETE_TEAM.replace('{id}', query.id)}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteTeamUsersBulk = async ({
  teamId,
  ...data
}: QueryDeleteTeamUsersBulk) => {
  return axios
    .delete(`${DELETE_TEAM_USERS_BULK.replace('{id}', teamId)}`, { data })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import {
  FORGOT_PASSWORD,
  REFRESH_TOKEN_URL,
  REGISTER_URL,
  RESET_PASSWORD,
  SIGN_IN_URL,
} from "./constants";
import { ResetRequestDto } from "../models/Authentication";

export const registerUser = async (userData: {
  teamName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}) =>
  axios
    .post(REGISTER_URL, userData)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const signInUser = async (userData: {
  email: string;
  password: string;
}) =>
  axios
    .post(SIGN_IN_URL, userData)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const requestRefreshToken = async (token: string) =>
  axios
    .post(REFRESH_TOKEN_URL, { token })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const forgotPassword = async (email: string) =>
  axios
    .post(FORGOT_PASSWORD, { email })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const resetPassword = async (body: ResetRequestDto) =>
  axios
    .post(
      RESET_PASSWORD,
      { password: body.password },
      { headers: { Authorization: `Bearer ${body.token}` } }
    )
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

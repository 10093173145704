import React from "react";
import { NavBar } from "./components/NavBar";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { Container } from "./styles";
import styled, { ThemeProvider } from "styled-components";
import { Themes } from "./constants";
import { getTheme } from "./store/selectors/main";
import ModalsWrapper from "./components/Modals";
import { useMobileDetect } from "./hooks/useMobileStatus";
import { MobileView } from "./components/MobileView";
import { buildAbilityFor } from "./casl";
import { getSelectedProjectTeam, getSelectedTeam } from "./store/selectors/teams";
import { getProfiles } from "./store/selectors/profiles";
import { AbilityContext } from "./casl/context";
interface LayoutProps {
  children: React.ReactNode;
}
const LayoutWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Layout = ({ children }: LayoutProps) => {
  const themeSelector = useSelector(getTheme);
  const isMobile = useMobileDetect();
  const profile = useSelector(getProfiles);
  const selectedTeamProject = useSelector(getSelectedProjectTeam);
  const selectedTeam = useSelector(getSelectedTeam);
  const ability = buildAbilityFor(profile, selectedTeam || selectedTeamProject);

  return (
    <AbilityContext.Provider value={ability as any}>
      <ThemeProvider theme={Themes[themeSelector]}>
        {isMobile ? (
          <MobileView />
        ) : (
          <LayoutWrapper>
            <NavBar />
            <Container>{children}</Container>
            <ModalsWrapper />
            <ToastContainer
              position="bottom-left"
              autoClose={2500}
              pauseOnFocusLoss={false}
              hideProgressBar
              theme="colored"
            />
          </LayoutWrapper>
        )}
      </ThemeProvider>
    </AbilityContext.Provider>
  );
};

export default Layout;

import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import useMeasure from "react-use-measure";

import { hexToRGBA } from "../../../../helpers/hexToRgba";

type Props = {
  values: any;
  maxValue: number;
  rangeStep: number;
  ranges: number[];
  colors?: string[];
  bubbles?: boolean;
  spikes?: boolean;
};

const DataRangeFilterChart = ({
  values,
  maxValue,
  rangeStep,
  ranges,
  colors,
  bubbles = true,
  spikes = true,
}: Props) => {
  const svgRef = useRef<any>(null);
  const [measureRef, bounds] = useMeasure({ debounce: 250, scroll: true });

  const [maxHeighValue, setMaxHeighValue] = useState(0);
  const [groupedData, setGroupedData] = useState<{ x: number; y: number }[]>(
    []
  );

  useEffect(() => {
    const lastValuesIndex = values.length - 1;
    const dataDensity = [{ x: 0, y: 0 }];
    let maxData = 0;
    let curentValuesIndex = 0;

    for (let i = 1; i <= 7; i++) {
      let dataInCurentRange = 0;
      const stepValue = i === 7 ? maxValue : rangeStep * i;

      for (
        curentValuesIndex;
        curentValuesIndex < lastValuesIndex;
        curentValuesIndex++
      ) {
        if (values[curentValuesIndex] <= stepValue) {
          dataInCurentRange++;
        } else {
          break;
        }
      }

      dataDensity.push({
        x: stepValue,
        y: dataInCurentRange,
      });
      if (maxData < dataInCurentRange) {
        maxData = dataInCurentRange;
      }
    }
    setGroupedData(dataDensity);
    setMaxHeighValue(maxData);
  }, [values, maxValue, rangeStep]);

  useEffect(() => {
    if (!svgRef.current || !bounds.width || groupedData.length === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const defs = svg.append("defs");
    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = bounds.width - margin.left - margin.right;
    const height = bounds.height - margin.top - margin.bottom;

    const xScale = d3.scaleLinear().domain([0, maxValue]).range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, maxHeighValue])
      .nice()
      .range([height, 2]);

    const area = d3
      .area<{ x: number; y: number }>()
      .x((d) => xScale(d.x))
      .y0(height)
      .y1((d) => yScale(d.y))
      .curve(d3.curveNatural);

    const line = d3
      .line<{ x: number; y: number }>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveNatural);

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Add area
    g.append("path")
      .datum(groupedData)
      .attr("fill", hexToRGBA("#F3F3F4", 1)!)
      .attr("d", area);

    // Add line
    g.append("path")
      .datum(groupedData)
      .attr("fill", "none")
      .attr("stroke", "#D3DBE3")
      .attr("stroke-width", 1)
      .attr("d", line);

    // Add bubbles
    if (bubbles) {
      const bubbleRadius = [5, 10, 14, 17, 20, 24, 26];
      const bubblesInside = [0, 1, 2, 2, 3, 3, 3];

      const midPoints = ranges.slice(0, -1).map((val, i) => {
        const nextVal = ranges[i + 1];
        const midpoint = (val + nextVal) / 2;
        return {
          x: xScale(midpoint),
          y: height,
        };
      });

      midPoints.forEach((point, i) => {
        const bubbleGroup = g.append("g");

        // Add concentric circles
        const numCircles = bubblesInside[i] + 1;
        const radiusStep = bubbleRadius[i] / numCircles;

        for (let j = 0; j < numCircles; j++) {
          bubbleGroup
            .append("circle")
            .attr("cx", point.x)
            .attr("cy", point.y)
            .attr("r", radiusStep * (j + 1))
            .attr("fill", "transparent")
            .attr("stroke", colors?.[i] || "#939BA7")
            .attr("stroke-width", 0.5)
            .attr("stroke-dasharray", "3 3")
            .attr("opacity", 0.9);
        }

        // Add main bubble
        bubbleGroup
          .append("circle")
          .attr("cx", point.x)
          .attr("cy", point.y)
          .attr("r", bubbleRadius[i])
          .attr("fill", colors?.[i] || "#939BA7")
          .attr("fill-opacity", 0.3)
          .attr("stroke", colors?.[i] || "#939BA7")
          .attr("stroke-width", 1);
      });
    }

    if (spikes) {
      const spikeBaseWidth = 16;
      const curveHeight = 0;

      const spikesHeight = [2, 8, 16, 24, 32, 44, 56];

      const midPoints = ranges.slice(0, -1).map((val, i) => {
        const nextVal = ranges[i + 1];
        const midpoint = (val + nextVal) / 2;
        return {
          x: xScale(midpoint),
          y: height,
        };
      });

      midPoints.forEach((point, i) => {
        const spikeGroup = g.append("g");

        // Create spike path
        const spikePath = d3.path();

        // Start from left base with small curve
        spikePath.moveTo(point.x - spikeBaseWidth / 2, height);

        // Left base curve
        spikePath.quadraticCurveTo(
          point.x - spikeBaseWidth / 2,
          height - curveHeight, // control point
          point.x - spikeBaseWidth / 2 + 2,
          height - curveHeight // end point
        );

        // Left straight line to peak
        spikePath.lineTo(point.x, height - spikesHeight[i]);

        // Right straight line from peak
        spikePath.lineTo(
          point.x + spikeBaseWidth / 2 - 2,
          height - curveHeight
        );

        // Right base curve
        spikePath.quadraticCurveTo(
          point.x + spikeBaseWidth / 2,
          height - curveHeight, // control point
          point.x + spikeBaseWidth / 2,
          height // end point
        );

        spikePath.closePath();

        // Add gradient for spike
        const gradientId = `spike-gradient-${i}`;
        const gradient = defs
          .append("linearGradient")
          .attr("id", gradientId)
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");

        // More transparent at top
        gradient
          .append("stop")
          .attr("offset", "0%")
          .attr("stop-color", colors?.[i] || "#5F6877")
          .attr("stop-opacity", 0.9);

        // Middle stop for smoother gradient
        gradient
          .append("stop")
          .attr("offset", "50%")
          .attr("stop-color", colors?.[i] || "#5F6877")
          .attr("stop-opacity", 0.6);

        // Most opaque at bottom
        gradient
          .append("stop")
          .attr("offset", "100%")
          .attr("stop-color", colors?.[i] || "#5F6877")
          .attr("stop-opacity", 0.3);

        spikeGroup
          .append("path")
          .attr("d", spikePath.toString())
          .attr("fill", `url(#${gradientId})`)
          .attr("stroke", colors?.[i] || "#5F6877")
          .attr("stroke-width", 0.5)
          .attr("stroke-opacity", 1);
      });
    }

    // Create a unique gradient for each line
    ranges.forEach((_, index) => {
      const gradient = defs
        .append("linearGradient")
        .attr("id", `line-gradient-${index}`)
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", bounds.height);

      gradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "#939BA7")
        .attr("stop-opacity", 0);

      gradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "#939BA7")
        .attr("stop-opacity", 1);
    });

    ranges.slice(1, -1).forEach((rangeValue, index) => {
      g.append("line")
        .attr("x1", xScale(rangeValue))
        .attr("x2", xScale(rangeValue))
        .attr("y1", yScale(0))
        .attr("y2", yScale(maxHeighValue))
        .attr("stroke", `url(#line-gradient-${index})`)
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "4 4");
    });
  }, [
    groupedData,
    bounds.width,
    bounds.height,
    maxHeighValue,
    maxValue,
    ranges,
    colors,
    bubbles,
    spikes,
  ]);

  return (
    <div
      style={{ height: "80px", width: "calc(100% - 10px)", margin: "0 auto" }}
    >
      <svg
        ref={(node) => {
          svgRef.current = node;
          measureRef(node);
        }}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default DataRangeFilterChart;

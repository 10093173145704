import { IActiveFilters } from "../../UserManagementComponents/Users/UsersList";

interface IApplyFilters<T> {
  skip?: keyof T;
  activeFilters: IActiveFilters<T>;
  data: T[];
}

export const applyFilters = <T>({ skip, activeFilters, data }: IApplyFilters<T>): T[] => {
  const filters = Object.keys(activeFilters) as (keyof T)[];
  let result = data;

  filters.forEach((keyName) => {
    const filterData = activeFilters[keyName];
    const search = filterData?.search;
    const filter = filterData?.filter;
    const subKeyName = filterData?.subKeyName;
    const subKeyName2 = filterData?.subKeyName2;

    if (search) {
      result = result.filter((item) => {
        // Special handling for teams structure
        if (keyName === 'teams' && subKeyName === 'teams') {
          const teamsArray = (item as any)[keyName]?.[subKeyName] || [];
          return teamsArray.some((team: any) => 
            (team[subKeyName2] || '').toString().toLowerCase().includes(search.toLowerCase())
          );
        }

        // Default handling for other cases
        let itemValue;
        if (subKeyName && subKeyName2 && item[keyName]) {
          const nestedObj = item[keyName] as any;
          itemValue = nestedObj[subKeyName]?.[subKeyName2]?.toString().toLowerCase() || '';
        } else if (subKeyName && item[keyName]) {
          const nestedObj = item[keyName] as any;
          itemValue = nestedObj[subKeyName]?.toString().toLowerCase() || '';
        } else {
          itemValue = item[keyName]?.toString().toLowerCase() || '';
        }
        return itemValue.includes(search.toLowerCase());
      });
    }

    if (skip === keyName) return;

    if (filter) {
      result = result.filter((item) => {
        // Special handling for teams structure
        if (keyName === 'teams' && subKeyName === 'teams') {
          const teamsArray = (item as any)[keyName]?.[subKeyName] || [];
          return teamsArray.some((team: any) => 
            filter.includes(String(team[subKeyName2]))
          );
        }

        // Default handling for other cases
        let itemValue;
        if (subKeyName && subKeyName2 && item[keyName]) {
          const nestedObj = item[keyName] as any;
          itemValue = nestedObj[subKeyName]?.[subKeyName2];
        } else if (subKeyName && item[keyName]) {
          const nestedObj = item[keyName] as any;
          itemValue = nestedObj[subKeyName];
        } else {
          itemValue = item[keyName];
        }

        if (Array.isArray(itemValue)) {
          return itemValue.some((val) => filter.includes(String(val)));
        }
        return filter.includes(String(itemValue));
      });
    }
  });

  return result;
};

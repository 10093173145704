import React, { useState } from "react";

import {
  CheckBoxWrapper,
  Dimensions,
  Name,
  Teams,
  UploadedOn,
  UploadedOnSort,
} from "./styles";
import { ThreeDots } from "react-bootstrap-icons";

import { DatasetRow } from "../DatasetRow";
import { NewDatasetDTO } from "../../models/Datasets";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";
import {
  TableContainer,
  TableFilters,
  TableHead,
  TableHeadWrapper,
} from "../UserManagementComponents/Users/UsersList/styled";
import { handleColumnClick } from "../UserManagementComponents/utils/usersList";
import { IMenuData } from "../UserManagementComponents/Users/UsersList";

import UsersTableMenu from "../UsersTableMenu";
import { TableValuesServerFilter } from "../TableValuesFilter/TableValuesServerFilter";

type Props = {
  items: NewDatasetDTO[];
  filters: Record<string, any>;
  onChangeFilters?: (filters: Record<string, any>) => void;
};

export const DatasetsList = ({ items, filters, onChangeFilters }: Props) => {
  const [columnMenu, setColumnMenu] = useState<IMenuData | null>(null);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  return (
    <TableContainer>
      <TableHeadWrapper>
        <TableHead>
          <CheckBoxWrapper>
            <CheckBox
              checked={checkAll}
              onChange={(e) => setCheckAll(e.target.checked)}
            />
          </CheckBoxWrapper>
          <Name>
            Name
            <ThreeDots
              onClick={(event) =>
                setColumnMenu(handleColumnClick({ event, key: "name" }))
              }
            />
          </Name>
          <Dimensions>
            Dimensions
            <ThreeDots
              onClick={(event) =>
                setColumnMenu(handleColumnClick({ event, key: "fields" }))
              }
            />
          </Dimensions>
          <Teams>Teams</Teams>
          <UploadedOn>
            Uploaded on
            <UploadedOnSort
              onClick={(event) =>
                setColumnMenu(handleColumnClick({ event, key: "createdAt" }))
              }
            />
          </UploadedOn>
        </TableHead>
        <TableFilters>
          <CheckBoxWrapper $noPadding />
          <Name $noPadding>
            <TableValuesServerFilter
              keyName="name"
              onChangeFilters={onChangeFilters}
            />
          </Name>
          <Dimensions $noPadding>
            <TableValuesServerFilter
              keyName="fieldLabelSearch"
              onChangeFilters={onChangeFilters}
            />
          </Dimensions>
          <Teams $noPadding>
            <TableValuesServerFilter
              keyName="teamSearch"
              onChangeFilters={onChangeFilters}
            />
          </Teams>
          <UploadedOn $noPadding>
            <TableValuesServerFilter
              keyName="updatedAtSearch"
              onChangeFilters={onChangeFilters}
            />
          </UploadedOn>
        </TableFilters>
      </TableHeadWrapper>
      {items?.map((dataset: NewDatasetDTO) => (
        <DatasetRow
          id={dataset.id}
          name={dataset.name}
          createdAt={dataset.createdAt}
          fields={dataset.fields}
          teams={dataset?.teams}
          filePath={dataset.filePath}
          updatedAt={dataset.updatedAt}
          selectAll={checkAll}
          key={dataset.id}
        />
      ))}
      {columnMenu && (
        <UsersTableMenu
          filters={filters}
          onChangeFilters={onChangeFilters}
          columnMenu={columnMenu}
          setColumnMenu={setColumnMenu}
        />
      )}
    </TableContainer>
  );
};

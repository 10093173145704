import { put, call, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  registerUser,
  forgotPassword,
  signInUser,
  resetPassword,
} from "../../api/auth";
import { setRegistrationSuccess, setSignInSuccess } from "../slices/auth";
import { toast } from "react-toastify";
import { ServerResponse } from "../../models";
import {
  CredentialsRequestDto,
  ForgotRequestDto,
  RegisterDto,
  ResetRequestDto,
} from "../../models/Authentication";
import { initialProfileState, setProfiles } from "../slices/profiles";

export function* handleRegistration(action: PayloadAction<RegisterDto>) {
  const { teamName, navigate, ...payload } = action.payload;
  const { response }: ServerResponse = yield call(registerUser, {
    teamName: teamName ? teamName : "Not Set",
    ...payload,
  });

  if (response?.status === 201) {
    yield put(setRegistrationSuccess(response.data));
    action.payload.navigate("/");
  }
}

export function* handleSignIn(action: PayloadAction<CredentialsRequestDto>) {
  const { response }: ServerResponse = yield call(signInUser, action.payload);
  if (response?.status === 200) {
    try {
      yield put(setSignInSuccess(response.data));
      toast.success("Sign in successful");
      action.payload.navigate("/new-profile", { replace: true });
    } catch (e) {}
  }
}

export function* handleForgotPassword(action: PayloadAction<ForgotRequestDto>) {
  const { response }: ServerResponse = yield call(
    forgotPassword,
    action.payload.email
  );
  if (response?.status === 200) {
    toast.success("Send in successful");
    action.payload.callbacks?.onSuccess();
  }
}

export function* handleSignOut() {
  yield put(setProfiles(initialProfileState.profile));
}

export function* handleResetPassword(action: PayloadAction<ResetRequestDto>) {
  const { response }: ServerResponse = yield call(
    resetPassword,
    action.payload
  );
  if (response?.status === 200) {
    toast.success("Send in successful");
    action.payload.callbacks?.onSuccess();
  }
}

export function* watchAuthActions() {
  yield takeLatest("auth/requestSignIn", handleSignIn);
  yield takeLatest("auth/signOut", handleSignOut);
  yield takeLatest("auth/requestRegistration", handleRegistration);
  yield takeLatest("auth/requestForgotPassword", handleForgotPassword);
  yield takeLatest("auth/requestResetPassword", handleResetPassword);
}

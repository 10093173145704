import React, { useEffect } from "react";
import {
  People,
  Buildings,
  Compass,
  Send,
  Calendar3,
} from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarSectionWrapper,
  ProfileSideBarHeading,
  Chip,
  ChipsWrapper,
  InvitedBy,
} from "./styles";
import { IProfile } from "../../types/profile";
import { getProfiles } from "../../store/selectors/profiles";
import { Team } from "../../models/Teams";
import { getTeams } from "../../store/selectors/teams";
import { requestAllTeams } from "../../store/slices/teams";

const aboutSection = (profile: IProfile, teams: Team[]) => [
  {
    icon: <Buildings />,
    type: "Department",
    data: profile?.organization,
  },
  {
    icon: <Compass />,
    type: "Location",
    data: profile?.location,
  },
  {
    icon: <People />,
    type: "Teams",
    data: (
      <ChipsWrapper>
        {teams?.map((team) => {
          const teamName = team.name;
          const isTeam = profile?.teams?.find((t) => t.id === team.id);
          return isTeam && (
            <Chip $selected={false}>{teamName}</Chip>
          )
        })}
      </ChipsWrapper>
    ),
  },
  {
    icon: <Calendar3 />,
    type: "Added on",
    data: format(new Date(profile?.createdAt), "MM.dd.yyyy"),
  },
  ...(!profile.isAdmin && profile?.invitedBy ? [{
    icon: <Send />,
    type: "Invited by",
    data: <InvitedBy>{profile?.invitedBy}</InvitedBy>,
  }] : []),
];

export const AboutSection = () => {
  const profile = useSelector(getProfiles);
  const teams = useSelector(getTeams);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!teams?.length) dispatch(requestAllTeams({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>About</ProfileSideBarHeading>
      <ProfileSideBarSectionWrapper>
        {aboutSection(profile, teams)?.map((section, idx) => (
          <ProfileSideBarAboutCardWrapper key={idx}>
            <ProfileSideBarAboutCardIcon>
              {section.icon}
            </ProfileSideBarAboutCardIcon>
            <ProfileSideBarAboutCardDetailsWrapper>
              <ProfileSideBarAboutCardTitle>
                {section.type}
              </ProfileSideBarAboutCardTitle>
              <ProfileSideBarAboutCardDescription $noData={!section.data}>
                {section.data ? section.data : "No data"}
              </ProfileSideBarAboutCardDescription>
            </ProfileSideBarAboutCardDetailsWrapper>
          </ProfileSideBarAboutCardWrapper>
        ))}
      </ProfileSideBarSectionWrapper>
    </ProfileSideBarSectionWrapper>
  );
};

import { createContext, useContext } from 'react'
import { AnyAbility } from '@casl/ability'
import { createContextualCan } from '@casl/react'

export const AbilityContext = createContext<AnyAbility>(undefined!)

export const useAbilityContext = () => {
  const context = useContext(AbilityContext);
  if (!context) {
    throw new Error('useAbilityContext error');
  }

  return context;
};
export default createContextualCan(AbilityContext.Consumer)

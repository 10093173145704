import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { DatasetsItem } from "../DatasetsItem";

import {
  KpiSettingDescription,
  KpiSettingLayerContent,
  KpiSettingList,
  KpiSettingsDatasets,
  KpiSettingsDatasetsHeading,
  KpiSettingsDatasetsWrapper,
  KpiSettingsLayerHeader,
  KpiSettingsLayersWrapper,
  KpiSettingsLeftSide,
  KpiSettingsRightSide,
  KpiSettingsWrapper,
  KpiSettingTitle,
} from "./styles";
import {
  getCurrentProjectData,
  getPageSettings,
} from "../../store/selectors/projects";
import { Select } from "../Inputs/CustomSelect/Select";
import { selectOptions } from "./data/mock";
import { AiKpiDto } from "../../models/Widgets";
import { KpiSettingsItem } from "../KpiSettingsItem";
import { Loader } from "../Loader";
import {
  requestPageKpis,
  requestUpdateKpis,
} from "../../store/slices/projectPages";

type Props = {
  isLoading: boolean;
  selectedKpis: string[];
  recommendedKpis: AiKpiDto[];
  setRecommendedKpis: React.Dispatch<React.SetStateAction<AiKpiDto[]>>;
  setSelectedKpis: React.Dispatch<React.SetStateAction<string[]>>;
  nrAvailbleKpi: number;
};

export const KpiSettings: React.FC<Props> = ({
  selectedKpis,
  recommendedKpis,
  setRecommendedKpis,
  setSelectedKpis,
  isLoading,
  nrAvailbleKpi,
}: Props) => {
  const dispatch = useDispatch();
  const projectData = useSelector(getCurrentProjectData);
  const currentPage = useSelector(getPageSettings);

  const handleChangeName = (kpi: AiKpiDto, name: string) => {
    const newKpis = recommendedKpis?.map((k) => {
      if (kpi.query === k.query) {
        return { ...k, name };
      }
      return k;
    });
    setRecommendedKpis(newKpis);
    if (kpi?.id) {
      dispatch(
        requestUpdateKpis({
          ...kpi,
          name,
          callbacks: {
            onSuccess: () => {
              dispatch(
                requestPageKpis({
                  pageId: currentPage?.id!,
                  includeData: true,
                })
              );
            },
          },
        })
      );
    }
  };

  const selectedKpi = (query: string) => {
    const exist = selectedKpis?.some((q) => q === query);
    if (exist) {
      const newSelected = selectedKpis?.filter((q) => q !== query);
      setSelectedKpis(newSelected);
    } else {
      setSelectedKpis([...selectedKpis, query]);
    }
  };

  return (
    <KpiSettingsWrapper>
      <KpiSettingsLeftSide>
        <KpiSettingsDatasetsWrapper>
          <KpiSettingsDatasetsHeading>
            Datasets
            {/* <Button
              onClick={() => {}}
              variant="primary"
              size="xs"
              name="Switch dataset"
              icon={<SwitchDataset />}
            /> */}
          </KpiSettingsDatasetsHeading>
          <KpiSettingsDatasets>
            {projectData.datasets.map((dataset) => (
              <DatasetsItem
                dataset={dataset}
                key={dataset.filePath}
                selectedDimensions={[]}
                isDraggable
                disablePreview
              />
            ))}
          </KpiSettingsDatasets>
        </KpiSettingsDatasetsWrapper>
        <KpiSettingsLayersWrapper>
          <KpiSettingsLayerHeader>Chart Type</KpiSettingsLayerHeader>
          <KpiSettingLayerContent>
            <Select withOutClose options={selectOptions} value={"KPI"} />
          </KpiSettingLayerContent>
        </KpiSettingsLayersWrapper>
      </KpiSettingsLeftSide>

      <KpiSettingsRightSide>
        {isLoading && <Loader fullScreen={false} />}
        <KpiSettingTitle>
          Available Key Performance Indicators (KPIs)
        </KpiSettingTitle>
        <KpiSettingDescription>
          Select KPI’s you would like to display on the dashboard.
        </KpiSettingDescription>
        <KpiSettingList>
          {recommendedKpis?.map((kpi, index) => (
            <KpiSettingsItem
              key={kpi.name + index}
              kpi={kpi}
              selectedKpis={selectedKpis}
              handleChangeName={(name) => handleChangeName(kpi, name)}
              selectedKpi={() => selectedKpi(kpi.query)}
              nrAvailbleKpi={nrAvailbleKpi}
            />
          ))}
        </KpiSettingList>
      </KpiSettingsRightSide>
    </KpiSettingsWrapper>
  );
};

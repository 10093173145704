import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pencil, Trash } from "react-bootstrap-icons";
import { AvatarCard } from "../AvatarCard";
import { getFullName, getInitials } from "../../helpers/getInitials";
import { Button } from "../Button";
import { setActiveModal } from "../../store/slices/modals";
import { setDeleteUserId } from "../../store/slices/users";
import { getUserProfilesById } from "../../store/selectors/profiles";
import {
  UserProfileHeaderUserDescription,
  UserProfileHeaderUserDetails,
  UserProfileHeaderUserName,
  UserProfileHeaderWrapper,
} from "../../pages/Profile/styles";
import { HeadingContainer, ButtonWrapper } from "./styles";

export const ProfileUserHeader = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfilesById);

  return (
    <HeadingContainer>
      <UserProfileHeaderWrapper>
        <AvatarCard
          avatar={userProfile?.avatar}
          size="lg"
          initials={getInitials(
            getFullName(userProfile?.firstName, userProfile?.lastName)
          )}
        />
        <UserProfileHeaderUserDetails>
          <UserProfileHeaderUserName>
            {getFullName(userProfile?.firstName, userProfile?.lastName)}
          </UserProfileHeaderUserName>
          <UserProfileHeaderUserDescription>
            {userProfile?.jobTitle || ""}
          </UserProfileHeaderUserDescription>
        </UserProfileHeaderUserDetails>
      </UserProfileHeaderWrapper>

      <ButtonWrapper>
        <Button
          onClick={() => {
            dispatch(setActiveModal({ id: "removeUsers" }));
            dispatch(setDeleteUserId(id));
          }}
          icon={<Trash />}
          variant="danger"
          size="medium"
        />
        <Button
          name="Update profile"
          onClick={() => {
            dispatch(
              setActiveModal({
                id: "uploadProfileModal",
                user: userProfile,
              })
            );
          }}
          icon={<Pencil />}
          variant="primary"
          size="medium"
        />
      </ButtonWrapper>
    </HeadingContainer>
  );
};

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FormikErrors, FormikTouched } from "formik";
import { requestUploadSimpleFile } from "../../../../store/slices/files";
import { FormProfileGroup } from "../FormGroup";
import { IProfile } from "../../../../types/profile";
import { AvatarCard } from "../../../AvatarCard";

import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlock,
  FullNameBlockWrapper,
  ProfileLabel,
  UploadButton,
  UploadMime,
  UploadWrapper,
} from "../styles";

type EssentialsProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  values: IProfile;
  errors: {
    [key: string]:
      | string
      | FormikErrors<any>
      | string[]
      | FormikErrors<any>[]
      | undefined;
  };
  touched: {
    [key: string]:
      | boolean
      | FormikTouched<any>
      | FormikTouched<any>[]
      | undefined;
  };
  initials?: string;
  avatar?: string;
  setFieldValue: (field: string, value: any) => void;
};

export const Essentials: React.FC<EssentialsProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  initials,
  avatar,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  const [localAvatar, setLocalAvatar] = useState<string | undefined>(avatar);
  const uploadFile = useRef<any>(null);

  useEffect(() => {
    if (!avatar) return;

    setLocalAvatar(avatar);
  }, [avatar]);

  const handleChangeImage = (event: any) => {
    dispatch(
      requestUploadSimpleFile({
        files: [event.target.files[0]],
        callbacks: {
          onSuccess: (resp: any) => {
            const uploadedFile = resp?.at(0);

            if (uploadedFile) {
              setFieldValue("avatar", uploadedFile.path);
              setLocalAvatar(URL.createObjectURL(event.target.files[0]));
            }
          },
        },
      })
    );
  };

  const openUpload = () => {
    uploadFile.current.click();
  };

  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <ProfileLabel>Full name</ProfileLabel>
          <FullNameBlock>
            <FormProfileGroup
              id="firstName"
              type="text"
              name="firstName"
              errors={errors.firstName as string}
              placeholder="First name"
              values={values.firstName}
              handleChange={handleChange}
              touched={touched.firstName as boolean}
              handleBlur={handleBlur}
            />

            <FormProfileGroup
              id="lastName"
              type="text"
              name="lastName"
              errors={errors.lastName as string}
              placeholder="Last name"
              values={values.lastName}
              handleChange={handleChange}
              touched={touched.lastName as boolean}
              handleBlur={handleBlur}
            />
          </FullNameBlock>
        </FullNameBlockWrapper>
        <FullNameBlockWrapper>
          <ProfileLabel>Job Position</ProfileLabel>
          <FormProfileGroup
            id="jobTitle"
            type="text"
            name="jobTitle"
            errors={errors.jobTitle as string}
            placeholder="Job Position"
            values={values.jobTitle}
            handleChange={handleChange}
            touched={touched.jobTitle as boolean}
            handleBlur={handleBlur}
          />
        </FullNameBlockWrapper>
        <FullNameBlockWrapper>
          <ProfileLabel>Departament</ProfileLabel>
          <FormProfileGroup
            id="organization"
            type="text"
            name="organization"
            errors={errors.organization as string}
            placeholder="Departament"
            values={values.organization}
            handleChange={handleChange}
            touched={touched.organization as boolean}
            handleBlur={handleBlur}
          />
        </FullNameBlockWrapper>
        <FullNameBlockWrapper>
          <ProfileLabel>Location</ProfileLabel>
          <FormProfileGroup
            id="location"
            type="text"
            name="location"
            errors={errors.location as string}
            placeholder="Location"
            values={values.location}
            handleChange={handleChange}
            touched={touched.location as boolean}
            handleBlur={handleBlur}
          />
        </FullNameBlockWrapper>
      </EssentialsWrapper>
      <UploadWrapper>
        <AvatarCard avatar={localAvatar} size="lg" initials={initials} />
        <input
          type="file"
          name="avatar"
          hidden
          onChange={handleChangeImage}
          accept="image/*"
          ref={uploadFile}
        />
        <UploadButton htmlFor="avatar" onClick={openUpload}>
          Upload photo
        </UploadButton>
        <UploadMime>JPG, GIF or PNG. Max size of 800K</UploadMime>
      </UploadWrapper>
    </EssentialsBlock>
  );
};

import { TeamMember } from "../../../../../models/Teams";
import { IUser } from "../../../../../models/Users";

export interface ManagementUserList {
  uuid: string;
  name: string;
  email: string;
  position: string;
  team: string[];
  projects: number;
  addedOn: string;
  role: string,
  lastActive: string; 
}

export type SortedColumn = keyof (IUser | TeamMember);

export const mockDataUsersList: ManagementUserList[] = [
  {
    uuid: "1",
    name: "Mr. Glenn Lambert MD",
    email: "shannoncross@shelton.net",
    position: "Operations Manager",
    team: ['Marketing', 'Sales'],
    projects: 4,
    addedOn: "21.06.2024",
    role: "viewer",
    lastActive: "Last Month",
  },    
  {
    uuid: "2",
    name: "Susan Sanchez",
    email: "thomashouston@gmail.com",
    position: "Project Managers",
    team: ['IT', 'Technical'],
    projects: 9,
    addedOn: "23.03.2024",
    role: "manager",
    lastActive: "Last Month",
  },
  {
    uuid: "3",
    name: "Dr. John Jackson",
    email: "walkerjonathon@garcia.com",
    position: "Public Health Official",
    team: ['HR', 'Compliance'],
    projects: 10,
    addedOn: "02.05.2024",
    role: "editor",
    lastActive: "Today",
},
  {
    uuid: "4",
    name: "Brittney Ford",
    email: "sarahdickerson@gmail.com",
    position: "Patient Coordinator",
    team: ['Quality Assurance'],
    projects: 8,
    addedOn: "24.09.2024",
    role: "viewer",
    lastActive: "Today",
},
  {
    uuid: "5",
    name: "Monica Wright",
    email: "ohess@steele.com",
    position: "Operations Manager",
    team: [],
    projects: 7,
    addedOn: "12.11.2024",
    role: "viewer",
    lastActive: "This Week",
},
  {
    uuid: "6",name: "Carol Miller",
    email: "garciatimothy@gmail.com",
    position: "Operations Manager",
    team: ['Quality Assurance'],
    projects: 7,
    addedOn: "04.01.2024",
    role: "viewer",
    lastActive: "Yesterday",
},
  {
    uuid: "7",name: "Deanna Ruiz",
    email: "helen74@yahoo.com",
    position: "Patient Coordinator",
    team: ['Support', 'Auxiliary'],
    projects: 1,
    addedOn: "16.06.2024",
    role: "viewer",
    lastActive: "Yesterday",
},
  {
    uuid: "8",name: "Shirley Kramer",
    email: "mcknightbethany@richardson-doyle.com",
    position: "-",
    team: ['Research', 'Analytics'],
    projects: 7,
    addedOn: "02.01.2024",
    role: "viewer",
    lastActive: "Today",
},
  {
    uuid: "9",name: "Linda Davis",
    email: "bweber@evans-bartlett.com",
    position: "Designer",
    team: ['Operations', 'Logistics'],
    projects: 1,
    addedOn: "27.10.2024",
    role: "viewer",
    lastActive: "This Week",
},
  {
    uuid: "10",name: "Katherine Dyer",
    email: "paula32@smith.com",
    position: "Public Health Official",
    team: ['Research', 'Analytics'],
    projects: 4,
    addedOn: "10.05.2024",
    role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "11",name: "Alexandria Lynch",
  email: "donald70@dominguez-perez.info",
  position: "Business Analytic",
  team: ['Research', 'Analytics'],
  projects: 10,
  addedOn: "07.07.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "12",name: "James King",
  email: "meagansimmons@mcneil-lopez.com",
  position: "Project Managers",
  team: ['Research', 'Analytics'],
  projects: 10,
  addedOn: "25.05.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "13",name: "Tristan Alexander",
  email: "hughesnathan@davis.net",
  position: "Project Managers",
  team: ['IT', 'Technical'],
  projects: 2,
  addedOn: "04.10.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "14",name: "Melissa Rivera",
  email: "andrea97@davis.biz",
  position: "Designer",
  team: [],
  projects: 8,
  addedOn: "21.11.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "15",name: "Patrick Graham",
  email: "jennifergoodman@hotmail.com",
  position: "Designer",
  team: ['Quality Assurance'],
  projects: 9,
  addedOn: "16.01.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "16",name: "Jesse Meyers",
  email: "kmoore@hotmail.com",
  position: "Business Analytic",
  team: ['Research', 'Analytics'],
  projects: 7,
  addedOn: "01.05.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "17",name: "Nicholas Scott",
  email: "adam64@yahoo.com",
  position: "Operations Manager",
  team: ['Marketing', 'Sales'],
  projects: 4,
  addedOn: "04.07.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "18",name: "Christopher Garrett",
  email: "kreed@hotmail.com",
  position: "Operations Manager",
  team: ['IT', 'Technical'],
  projects: 3,
  addedOn: "11.02.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "19",name: "Jasmin Shannon",
  email: "anthonytownsend@smith.biz",
  position: "Public Health Official",
  team: ['IT', 'Technical'],
  projects: 3,
  addedOn: "09.03.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "20",name: "Ryan Russell",
  email: "tyler84@wade.com",
  position: "-",
  team: ['Marketing', 'Sales'],
  projects: 6,
  addedOn: "28.05.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "21",name: "Kristin Williams",
  email: "hannah12@yahoo.com",
  position: "Project Managers",
  team: ['HR', 'Compliance'],
  projects: 9,
  addedOn: "24.08.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "22",name: "Luis Walters",
  email: "kristinemorgan@hotmail.com",
  position: "Operations Manager",
  team: [],
  projects: 3,
  addedOn: "22.05.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "23",name: "Sandra Benjamin",
  email: "mike27@wright-kirby.com",
  position: "Public Health Official",
  team: ['HR', 'Compliance'],
  projects: 10,
  addedOn: "29.06.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "24",name: "Karen Robertson DVM",
  email: "dvazquez@hotmail.com",
  position: "Public Health Official",
  team: ['Quality Assurance'],
  projects: 8,
  addedOn: "25.05.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "25",name: "Gabriela Ferguson",
  email: "westjennifer@drake.com",
  position: "Business Analytic",
  team: ['Operations', 'Logistics'],
  projects: 1,
  addedOn: "17.06.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "26",name: "Daniel Obrien",
  email: "bryanball@moore.net",
  position: "Business Analytic",
  team: ['Operations', 'Logistics'],
  projects: 2,
  addedOn: "13.05.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "27",name: "Mary Foley",
  email: "anthonygrant@gmail.com",
  position: "Operations Manager",
  team: [],
  projects: 5,
  addedOn: "22.11.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "28",name: "Richard Nielsen",
  email: "mary34@hotmail.com",
  position: "Designer",
  team: ['Operations', 'Logistics'],
  projects: 2,
  addedOn: "13.01.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "29",name: "Ms. Latasha Potts",
  email: "simpsonjon@mccarthy.org",
  position: "Project Managers",
  team: ['HR', 'Compliance'],
  projects: 9,
  addedOn: "05.08.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "30",name: "Ashley Ramos",
  email: "wrightandrew@yahoo.com",
  position: "Designer",
  team: ['Research', 'Analytics'],
  projects: 5,
  addedOn: "11.09.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "31",name: "Tracy Garrett",
  email: "andrea19@yahoo.com",
  position: "Operations Manager",
  team: ['HR', 'Compliance'],
  projects: 3,
  addedOn: "11.02.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "32",name: "Amber Baker",
  email: "brittanyvasquez@hotmail.com",
  position: "-",
  team: ['Quality Assurance'],
  projects: 0,
  addedOn: "07.08.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "33",name: "Theresa Smith",
  email: "mwilkinson@hotmail.com",
  position: "Designer",
  team: ['Research', 'Analytics'],
  projects: 7,
  addedOn: "16.04.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "34",name: "Tracy Hall",
  email: "taylorcynthia@yahoo.com",
  position: "Project Managers",
  team: ['Support', 'Auxiliary'],
  projects: 6,
  addedOn: "15.11.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "35",name: "Sandra Schmitt",
  email: "alicia94@bowen-ford.com",
  position: "Designer",
  team: ['Support', 'Auxiliary'],
  projects: 7,
  addedOn: "17.07.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "36",name: "Richard White",
  email: "millermary@butler.com",
  position: "Operations Manager",
  team: ['HR', 'Compliance'],
  projects: 1,
  addedOn: "30.08.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "37",name: "Pamela Scott",
  email: "jblack@young.com",
  position: "-",
  team: ['Support', 'Auxiliary'],
  projects: 5,
  addedOn: "09.01.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "38",name: "Carrie Goodwin",
  email: "wpadilla@hall-hancock.org",
  position: "Public Health Official",
  team: ['IT', 'Technical'],
  projects: 9,
  addedOn: "31.05.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "39",name: "Cynthia Smith",
  email: "pgeorge@serrano.com",
  position: "-",
  team: ['HR', 'Compliance'],
  projects: 4,
  addedOn: "30.10.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "40",name: "John Schneider",
  email: "prose@nielsen.info",
  position: "Designer",
  team: ['HR', 'Compliance'],
  projects: 9,
  addedOn: "29.01.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "41",name: "Elizabeth Brooks",
  email: "hendersonsamantha@yahoo.com",
  position: "Public Health Official",
  team: ['HR', 'Compliance'],
  projects: 10,
  addedOn: "25.01.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "42",name: "Richard Thomas",
  email: "dgarcia@hotmail.com",
  position: "Patient Coordinator",
  team: ['Research', 'Analytics'],
  projects: 0,
  addedOn: "08.05.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "43",name: "Tara Martin",
  email: "andersonsherri@thornton.com",
  position: "Project Managers",
  team: ['Quality Assurance'],
  projects: 1,
  addedOn: "09.07.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "44",name: "Melissa Mcmahon",
  email: "vincentlivingston@leonard.com",
  position: "Project Managers",
  team: ['IT', 'Technical'],
  projects: 9,
  addedOn: "17.05.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "45",name: "Jay Valdez",
  email: "jessicaramos@dean.biz",
  position: "Business Analytic",
  team: ['Support', 'Auxiliary'],
  projects: 1,
  addedOn: "18.09.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "46",name: "Peter Norris Jr.",
  email: "murphydebbie@valentine-maldonado.com",
  position: "Operations Manager",
  team: ['Marketing', 'Sales'],
  projects: 1,
  addedOn: "20.08.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "47",name: "Brandon Long",
  email: "eandrews@gmail.com",
  position: "Public Health Official",
  team: ['Support', 'Auxiliary'],
  projects: 9,
  addedOn: "13.03.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "48",name: "Samantha Martin",
  email: "darrell20@hotmail.com",
  position: "Public Health Official",
  team: ['Quality Assurance'],
  projects: 7,
  addedOn: "18.11.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "49",name: "David Bowman",
  email: "linsteven@phelps.com",
  position: "Project Managers",
  team: ['HR', 'Compliance'],
  projects: 10,
  addedOn: "12.07.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "50",name: "Matthew Anderson",
  email: "orrjoseph@hotmail.com",
  position: "-",
  team: ['IT', 'Technical'],
  projects: 1,
  addedOn: "22.02.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "51",name: "Dr. Kim Clark",
  email: "fhart@reyes-peters.info",
  position: "-",
  team: ['IT', 'Technical'],
  projects: 7,
  addedOn: "16.04.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "52",name: "Todd Burns",
  email: "vernon11@hill.com",
  position: "Public Health Official",
  team: ['Quality Assurance'],
  projects: 9,
  addedOn: "18.10.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "53",name: "Erica Cooke",
  email: "hcampbell@yahoo.com",
  position: "Public Health Official",
  team: ['HR', 'Compliance'],
  projects: 10,
  addedOn: "05.04.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "54",name: "Jeffrey Pacheco",
  email: "ngraham@yahoo.com",
  position: "Business Analytic",
  team: [],
  projects: 3,
  addedOn: "17.06.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "56",name: "John Avery",
  email: "rowlandclinton@gmail.com",
  position: "Business Analytic",
  team: ['Research', 'Analytics'],
  projects: 0,
  addedOn: "11.09.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "57",name: "Tommy Moreno",
  email: "keithmorrison@yahoo.com",
  position: "Public Health Official",
  team: [],
  projects: 3,
  addedOn: "22.08.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "58",name: "Rachel Sutton MD",
  email: "xjones@cochran-case.org",
  position: "Public Health Official",
  team: [],
  projects: 10,
  addedOn: "29.10.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "59",name: "Tracy Dean",
  email: "amandachurch@vega.info",
  position: "Business Analytic",
  team: ['Research', 'Analytics'],
  projects: 4,
  addedOn: "17.10.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "60",name: "Patricia Ballard",
  email: "edunn@gmail.com",
  position: "-",
  team: ['Research', 'Analytics'],
  projects: 4,
  addedOn: "19.04.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "61",name: "James Contreras",
  email: "icarpenter@gmail.com",
  position: "Project Managers",
  team: ['Quality Assurance'],
  projects: 10,
  addedOn: "04.06.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "62",name: "Erin Jennings",
  email: "osmith@herman-bishop.com",
  position: "-",
  team: ['HR', 'Compliance'],
  projects: 8,
  addedOn: "14.11.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "63",name: "Adam Brown",
  email: "trobinson@terry.com",
  position: "Patient Coordinator",
  team: ['HR', 'Compliance'],
  projects: 2,
  addedOn: "18.02.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "64",name: "Fred Wood",
  email: "jacob92@yahoo.com",
  position: "Public Health Official",
  team: ['IT', 'Technical'],
  projects: 7,
  addedOn: "02.06.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "65",name: "Daniel Owen",
  email: "yjuarez@gmail.com",
  position: "-",
  team: [],
  projects: 5,
  addedOn: "02.07.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "66",name: "Lindsay Heath",
  email: "dewing@bishop-moore.com",
  position: "Operations Manager",
  team: ['HR', 'Compliance'],
  projects: 5,
  addedOn: "18.07.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "68",name: "Norma Snyder",
  email: "jessica48@brown.com",
  position: "Designer",
  team: ['HR', 'Compliance'],
  projects: 3,
  addedOn: "16.08.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "69",name: "Julie Tucker",
  email: "scastro@miller.net",
  position: "Designer",
  team: ['Quality Assurance'],
  projects: 1,
  addedOn: "06.11.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "70",name: "William Burke",
  email: "austinpreston@young-smith.org",
  position: "Public Health Official",
  team: ['HR', 'Compliance'],
  projects: 8,
  addedOn: "22.10.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "71",name: "Tanya Lewis",
  email: "petervelasquez@lowe.com",
  position: "Operations Manager",
  team: ['Marketing', 'Sales'],
  projects: 4,
  addedOn: "12.03.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "72",name: "Anthony Wilson",
  email: "harrisonannette@johnson.net",
  position: "Designer",
  team: ['Operations', 'Logistics'],
  projects: 0,
  addedOn: "10.09.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "73",name: "Michelle Smith MD",
  email: "sbrady@yahoo.com",
  position: "Operations Manager",
  team: ['Support', 'Auxiliary'],
  projects: 9,
  addedOn: "25.06.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "74",name: "Brandi Hill",
  email: "nsantiago@hotmail.com",
  position: "Business Analytic",
  team: ['Operations', 'Logistics'],
  projects: 3,
  addedOn: "09.02.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "75",name: "Debra Odonnell",
  email: "smithjohnny@david-nelson.com",
  position: "Business Analytic",
  team: ['IT', 'Technical'],
  projects: 6,
  addedOn: "07.09.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "76",name: "Gene Nielsen",
  email: "rirwin@gmail.com",
  position: "Public Health Official",
  team: ['Quality Assurance'],
  projects: 1,
  addedOn: "24.06.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "77",name: "Kimberly Patton",
  email: "rdavid@solomon.com",
  position: "-",
  team: ['IT', 'Technical'],
  projects: 5,
  addedOn: "16.02.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "78",name: "Lynn Campbell",
  email: "haynesjonathan@yahoo.com",
  position: "Patient Coordinator",
  team: ['HR', 'Compliance'],
  projects: 0,
  addedOn: "18.08.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "79",name: "Megan Rodgers",
  email: "cummingsdavid@gmail.com",
  position: "Patient Coordinator",
  team: ['HR', 'Compliance'],
  projects: 3,
  addedOn: "07.03.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "80",name: "Craig Todd",
  email: "ronaldcook@cannon.com",
  position: "Patient Coordinator",
  team: ['Marketing', 'Sales'],
  projects: 10,
  addedOn: "23.02.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "81",name: "Andrew Hall",
  email: "watersthomas@allen.org",
  position: "Business Analytic",
  team: ['Quality Assurance'],
  projects: 4,
  addedOn: "06.08.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "82",name: "Amanda Dickerson",
  email: "jennifer73@young.org",
  position: "Public Health Official",
  team: ['HR', 'Compliance'],
  projects: 4,
  addedOn: "09.09.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "83",name: "Johnny Mcclure",
  email: "imanning@brennan-smith.com",
  position: "-",
  team: ['IT', 'Technical'],
  projects: 2,
  addedOn: "07.07.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "84",name: "John Wong",
  email: "figueroakaren@gmail.com",
  position: "-",
  team: ['Support', 'Auxiliary'],
  projects: 4,
  addedOn: "23.03.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "85",name: "Glenda Jones",
  email: "tara70@vazquez.com",
  position: "-",
  team: ['Research', 'Analytics'],
  projects: 1,
  addedOn: "31.03.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "86",name: "Jennifer Burke",
  email: "nbartlett@terrell-king.biz",
  position: "Patient Coordinator",
  team: ['HR', 'Compliance'],
  projects: 3,
  addedOn: "05.04.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "87",name: "Joanna Francis",
  email: "kent72@james.org",
  position: "Patient Coordinator",
  team: ['HR', 'Compliance'],
  projects: 8,
  addedOn: "21.08.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "88",name: "Samuel Wiley",
  email: "barneschristy@nguyen.org",
  position: "Project Managers",
  team: ['Marketing', 'Sales'],
  projects: 5,
  addedOn: "09.05.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "89",name: "Kathleen Lee",
  email: "imoore@gmail.com",
  position: "Patient Coordinator",
  team: ['IT', 'Technical'],
  projects: 10,
  addedOn: "21.03.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
  {
  uuid: "90",name: "James Wyatt",
  email: "ashleysmith@hotmail.com",
  position: "-",
  team: ['Research', 'Analytics'],
  projects: 8,
  addedOn: "21.09.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "91",name: "Christopher Chambers",
  email: "douglasmaureen@woodward-hill.com",
  position: "-",
  team: ['HR', 'Compliance'],
  projects: 3,
  addedOn: "02.01.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "92",name: "Jacqueline Hernandez",
  email: "lambertangela@carr.com",
  position: "Designer",
  team: ['IT', 'Technical'],
  projects: 6,
  addedOn: "07.02.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "93",name: "Kendra Hamilton",
  email: "gonzalezjames@gmail.com",
  position: "Patient Coordinator",
  team: ['HR', 'Compliance'],
  projects: 5,
  addedOn: "14.02.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "94",name: "Shannon Garcia",
  email: "justin01@hotmail.com",
  position: "Public Health Official",
  team: ['Quality Assurance'],
  projects: 4,
  addedOn: "07.03.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "95",name: "Miss Anna Kemp",
  email: "janet13@yahoo.com",
  position: "Operations Manager",
  team: [],
  projects: 7,
  addedOn: "22.05.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "96",name: "Angela Charles",
  email: "alexander83@robinson.com",
  position: "Project Managers",
  team: ['Operations', 'Logistics'],
  projects: 1,
  addedOn: "12.07.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "97",name: "Diana Reed",
  email: "bryantkimberly@johnson.net",
  position: "Designer",
  team: ['Marketing', 'Sales'],
  projects: 9,
  addedOn: "12.02.2024",
  role: "viewer",
    lastActive: "This Week",
},
  {
  uuid: "98",name: "Juan Fry",
  email: "craigpage@rowe.info",
  position: "Project Managers",
  team: [],
  projects: 3,
  addedOn: "18.07.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "99",name: "Eric Jacobs",
  email: "toddchloe@yahoo.com",
  position: "Business Analytic",
  team: ['Quality Assurance'],
  projects: 6,
  addedOn: "28.01.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "100",name: "Lori Johnson",
  email: "leejustin@houston.net",
  position: "Patient Coordinator",
  team: [],
  projects: 1,
  addedOn: "21.01.2024",
  role: "viewer",
    lastActive: "Last Month",
},
  {
  uuid: "55",name: "Steven Norris",
  email: "floreselizabeth@gmail.com",
  position: "Patient Coordinator",
  team: ['Research', 'Analytics'],
  projects: 8,
  addedOn: "05.08.2024",
  role: "viewer",
    lastActive: "Today",
},
  {
  uuid: "67",name: "Ryan Higgins",
  email: "benglish@cole-schwartz.biz",
  position: "Business Analytic",
  team: ['Research', 'Analytics'],
  projects: 0,
  addedOn: "24.03.2024",
  role: "viewer",
    lastActive: "Yesterday",
},
];
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  AddedOn,
  CheckboxWrapper,
  EmailAddress,
  LastActive,
  More,
  Name,
  Position,
  Projects,
  Role,
  TableContainer,
  TableFilters,
  TableHead,
  TableHeadWrapper,
  Team,
} from "./styled";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import UsersRow from "../UsersRow";
import { selectAllUsers } from "../../../../store/slices/userManagement";
import { getSelectedUsers } from "../../../../store/selectors/userManagement";
import { ThreeDots } from "react-bootstrap-icons";
import { handleColumnClick } from "../../utils/usersList";
import UsersTableMenu from "../../../UsersTableMenu";
import { TableValuesServerFilter } from "../../../TableValuesFilter/TableValuesServerFilter";
import { IUser } from "../../../../models/Users";
import { format } from "date-fns";
import { IFullUser, TeamMember } from "../../../../models/Teams";
import { getTeams } from "../../../../store/selectors/teams";
import { useAbilityContext } from "../../../../casl/context";
import { requestAllUsers } from "../../../../store/slices/users";
import {
  requestAllTeams,
  requestTeamUsersList,
} from "../../../../store/slices/teams";
import { getFullName } from "../../../../helpers/getInitials";

export interface IMenuData {
  top: number;
  left: number;
  key: any;
}
export type IActiveFilters<T> = {
  [K in keyof T]?: {
    search?: string;
    filter?: string[];
    subKeyName?: keyof T[K];
    subKeyName2?: keyof T[K][keyof T[K]];
  };
};

type Props = {
  users: (IUser | TeamMember)[];
  usersCount: number;
  isTeamTable?: boolean;
  filters: Record<string, any>;
  onChangeFilters?: (filters: Record<string, any>) => void;
};

const UsersList = ({
  users,
  usersCount,
  filters,
  onChangeFilters,
  isTeamTable = false,
}: Props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const selectedUsers = useSelector(getSelectedUsers);
  const teams = useSelector(getTeams);
  const ability = useAbilityContext();
  const [maxData, setMaxData] = useState<number>(30);
  const [hasMore, setHasMore] = useState(true);
  const [columnMenu, setColumnMenu] = useState<IMenuData | null>(null);

  const maxDataLength = usersCount;
  const end = maxData;
  const canUpdate = isTeamTable
    ? ability.can("update", "teamUsers")
    : ability.can("update", "users");
  const canOpenMenu = isTeamTable
    ? ability.can("delete", "teamUsers")
    : ability.can("delete", "users");

  const fetchData = () => {
    if (maxData + 30 < maxDataLength) {
      setMaxData((prev) => prev + 30);
    } else {
      setMaxData(maxDataLength);
      setHasMore(false);
    }
  };

  const selectAll = () => {
    dispatch(
      selectAllUsers(
        users
          ?.slice(0, end)
          .map((item) => (isTeamTable ? (item as TeamMember).id : item?.uid))
      )
    );
  };

  useEffect(() => {
    if (!teams.length) {
      dispatch(requestAllTeams({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTeamTable) {
      dispatch(
        requestTeamUsersList({
          id: id as string,
          ...filters,
          limit: maxData,
        })
      );
    } else {
      dispatch(
        requestAllUsers({
          limit: maxData,
          ...filters,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxData, dispatch]);

  const filterTeamsList = useMemo(
    () => teams.map(({ id, name }) => ({ id, name })),
    [teams]
  );

  return (
    <>
      <TableContainer style={{ marginBottom: "20px" }}>
        <TableHeadWrapper>
          <TableHead>
            {canUpdate && (
              <CheckboxWrapper>
                <CheckBox
                  onChange={() => selectAll()}
                  checked={selectedUsers?.length === end}
                  status={
                    !!selectedUsers?.length && selectedUsers?.length < end
                      ? "indeterminate"
                      : selectedUsers?.length === end
                        ? "checked"
                        : "none"
                  }
                />
              </CheckboxWrapper>
            )}
            <Name>
              Name
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(
                    handleColumnClick({
                      event,
                      key: "firstName",
                    })
                  )
                }
              />
            </Name>
            <EmailAddress>
              Email address
              {!isTeamTable && (
                <ThreeDots
                  onClick={(event) =>
                    setColumnMenu(handleColumnClick({ event, key: "email" }))
                  }
                />
              )}
            </EmailAddress>
            {isTeamTable && <Role>Role</Role>}
            <Position>
              Position
              {!isTeamTable && (
                <ThreeDots
                  onClick={(event) =>
                    setColumnMenu(handleColumnClick({ event, key: "jobTitle" }))
                  }
                />
              )}
            </Position>
            {!isTeamTable && <Team>Team</Team>}
            <Projects>Projects</Projects>
            <AddedOn>
              Added on
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(handleColumnClick({ event, key: "createdAt" }))
                }
              />
            </AddedOn>
            <LastActive>Last active</LastActive>
            {canOpenMenu && canUpdate && <More></More>}
          </TableHead>

          {!isTeamTable && (
            <TableFilters>
              {canUpdate && <CheckboxWrapper $noPadding />}
              <Name $noPadding>
                <TableValuesServerFilter
                  keyName="searchFullName"
                  onChangeFilters={onChangeFilters}
                />
              </Name>
              <EmailAddress $noPadding>
                <TableValuesServerFilter
                  keyName="searchEmail"
                  onChangeFilters={onChangeFilters}
                />
              </EmailAddress>

              <Position $noPadding>
                <TableValuesServerFilter
                  keyName="searchJobTitle"
                  onChangeFilters={onChangeFilters}
                />
              </Position>

              <Team $noPadding>
                <TableValuesServerFilter
                  keyName="searchTeam"
                  filtersKeyName="teamIds"
                  onChangeFilters={onChangeFilters}
                  filtersList={filterTeamsList}
                  isFilter={true}
                />
              </Team>

              <Projects $noPadding>
                <TableValuesServerFilter disabled={true} />
              </Projects>
              <AddedOn $noPadding>
                <TableValuesServerFilter
                  keyName="searchCreatedAt"
                  onChangeFilters={onChangeFilters}
                />
              </AddedOn>
              <LastActive $noPadding>
                <TableValuesServerFilter disabled={true} />
              </LastActive>
              <More $noPadding />
            </TableFilters>
          )}
        </TableHeadWrapper>

        <InfiniteScroll
          dataLength={maxData}
          next={fetchData}
          hasMore={hasMore}
          loader={false && <h4>Loading...</h4>}
          endMessage={null}
        >
          <div
            style={{
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {users.map((item: IUser | TeamMember) => {
              const role = isTeamTable
                ? teams
                  .find((team) => team.id === id)
                  ?.members?.find((member) => member.email === item.email)
                  ?.role
                : undefined;

              return (
                <>
                  <UsersRow
                    uuid={
                      (item as TeamMember) && (item as TeamMember)?.user
                        ? (item as TeamMember)?.user?.uid
                        : item?.uid
                    }
                    user={
                      ((item as TeamMember) && (item as TeamMember)?.user
                        ? (item as TeamMember).user
                        : item) as IFullUser
                    }
                    teamMemberId={
                      (item as TeamMember) ? (item as TeamMember)?.id : null
                    }
                    name={
                      (item as TeamMember) && (item as TeamMember)?.user
                        ? getFullName(
                          (item as TeamMember)?.user?.profile?.firstName,
                          (item as TeamMember)?.user?.profile?.lastName
                        ) ||
                        (item as TeamMember)?.user?.name ||
                        item?.name
                        : getFullName(
                          (item as IUser)?.profile?.firstName,
                          (item as IUser)?.profile?.lastName
                        ) || item?.name
                    }
                    email={item.email}
                    team={
                      isTeamTable
                        ? undefined
                        : "teams" in item
                          ? item.teams?.teams?.map(
                            (team: { name: string }) => team.name
                          )
                          : [""]
                    }
                    projects={
                      isTeamTable
                        ? 0
                        : "projects" in item
                          ? item.projects?.count
                          : 0
                    }
                    addedOn={
                      isTeamTable
                        ? (item as TeamMember)?.user?.profile?.createdAt
                          ? format(
                            new Date(
                              (item as TeamMember)?.user?.profile?.createdAt!
                            ),
                            "MM.dd.yyyy HH:mm"
                          )
                          : "-"
                        : (item as IUser)?.profile?.createdAt
                          ? format(
                            new Date((item as IUser)?.profile?.createdAt!),
                            "MM.dd.yyyy HH:mm"
                          )
                          : "-"
                    }
                    lastActive={format(new Date(), "MM.dd.yyyy HH:mm")}
                    role={role}
                    avatar={
                      (item as TeamMember) && (item as TeamMember)?.user
                        ? (item as TeamMember)?.user?.profile?.avatar
                        : (item as IUser)?.profile?.avatar
                    }
                    position={
                      isTeamTable
                        ? (item as TeamMember)?.user?.profile?.jobTitle
                          ? (item as TeamMember)?.user?.profile?.jobTitle
                          : "-"
                        : (item as IUser)?.profile?.jobTitle
                          ? (item as IUser)?.profile?.jobTitle
                          : "-"
                    }
                    teamTable={isTeamTable}
                  />
                </>
              );
            })}
          </div>
        </InfiniteScroll>
      </TableContainer>
      {columnMenu && (
        <UsersTableMenu
          filters={filters}
          onChangeFilters={onChangeFilters}
          columnMenu={columnMenu}
          setColumnMenu={setColumnMenu}
        />
      )}
    </>
  );
};

export default UsersList;

import { useEffect, useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import { MultiSelect } from "../../Inputs/CustomMultiSelect/MultiSelect";
import { CustomInput } from "../../Inputs/CustomInput";
import {
  ContentWrapper,
  InfoCircleIcon,
  SelectBlock,
  SelectBlockLabel,
  SelectBlockTitle,
} from "./style";
import { useDispatch } from "react-redux";
import { requestCreateTeam } from "../../../store/slices/teams";
import { useSelector } from "react-redux";
import { requestGetAllDatasets } from "../../../store/slices/datasets";
import { getAllUsers } from "../../../store/selectors/users";
import { requestAllUsers } from "../../../store/slices/users";
import { DatasetsResponseListDto } from "../../../models/Datasets";

type ModalProps = {
  onClose: () => void;
};
interface Form {
  name: string;
  managers: string[];
  programs: string[];
}

const defaultForm: Form = {
  name: "",
  managers: [],
  programs: [],
};

export const CreateTeam = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [datasets, setDatasets] = useState<DatasetsResponseListDto>({
    items: [],
    count: 0,
  });
  const users = useSelector(getAllUsers);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!users?.length) {
      dispatch(requestAllUsers({ limit: 1000 }));
    }
    if (!datasets?.count) {
      dispatch(
        requestGetAllDatasets({
          defaultDatasets: true,
          callbacks: {
            onSuccess: (resp) => {
              setDatasets(resp);
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClose = () => {
    setIsClosing(true);
    setIsLoading(false);

    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    setIsLoading(true);
    dispatch(
      requestCreateTeam({
        name: form.name,
        managerEmails: form.managers,
        datasetIds: form.programs,
        callbacks: {
          onSuccess: () => {
            handleOnClose();
          },
        },
      })
    );
  };

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalContainer>
            <ModalHeading>Create Team</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ContentWrapper>
              <CustomInput
                name="name"
                value={form.name}
                type={"text"}
                onChange={(e: string) => {
                  setForm({ ...form, name: e });
                }}
                placeholder={"Name"}
                disablePaddings
                size={"base"}
              />
              <SelectBlock>
                <SelectBlockTitle>Programs</SelectBlockTitle>
                <MultiSelect
                  placeholder="Select programs"
                  value={form.programs}
                  disableAddByEnter
                  onSelect={(item) => {
                    setForm({ ...form, programs: item.map((el) => el.value) });
                  }}
                  options={datasets?.items?.map((el) => ({
                    value: el.id,
                    option: el.name,
                  }))}
                />
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>Managers</SelectBlockTitle>
                <MultiSelect
                  placeholder="Managers"
                  value={form.managers}
                  limit={50}
                  errors={["@", "."]}
                  limitMessage="You can add maximum 50 members to team"
                  options={users?.map((el) => ({
                    value: el.email,
                    option: el.name,
                    label: el.email,
                    avatar: true,
                  }))}
                  onSelect={(item) => {
                    setForm({
                      ...form,
                      managers: item.map((el) =>
                        typeof el === "string" ? el : el.value || String(el)
                      ),
                    });
                  }}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon />A team can have up to 50 members.
                </SelectBlockLabel>
              </SelectBlock>
            </ContentWrapper>
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Create"
              onClick={handleSave}
              variant={
                Object.values(form).every(
                  (value) => value && value.length > 0
                ) && !isLoading
                  ? "secondary"
                  : "disabled"
              }
              disabled={
                isLoading ||
                !Object.values(form).every((value) => value && value.length > 0)
              }
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

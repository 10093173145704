import React, { useEffect } from "react";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import { SignIn } from "../pages/Auth/SignIn";
import { Projects } from "../pages/ProjectsList";
import { Project } from "../pages/Project";
import { Datasets } from "../pages/Datasets";
import { Community } from "../pages/Community";
import { SignUp } from "../pages/Auth/SignUp";
import { PasswordReset } from "../pages/Auth/PasswordRest";
import { PrivateRoutes } from "./PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import { ErrorPage } from "../pages/404";
import { ProjectPage } from "../pages/ProjectPage";
import { getUser } from "../store/selectors/main";
import { getProfiles } from "../store/selectors/profiles";
import { ProfilePage } from "../pages/Profile";
// import { SettingsPage } from "../pages/SettingsPage";
import { UsersManagement } from "../pages/UserManagement";
import * as UsersManagementPages from "../pages/UserManagement/pages";
import { Templates } from "../pages/Templates";
import { NewProfile } from "../pages/Auth/Profile";
import { ResetPasswordForm } from "../pages/Auth/PasswordRest/ResetPass";
import { signOut } from "../store/slices/auth";

export const Routing = () => {
  const user = useSelector(getUser);
  const profile = useSelector(getProfiles);
  const dispatch = useDispatch();
  const matchManagement = useMatch("/sign-in");

  useEffect(() => {
    try {
      if (!matchManagement) {
        return;
      }
      dispatch(signOut());
      localStorage.removeItem("selectedTeam");
    } catch (e) {
      console.log(e);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to="/projects" replace={true} />} />
        <Route path="/new-profile" element={<NewProfile />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/projects" element={<Projects />} />
        {!!profile?.teams?.length && (
          <>
            <Route path="/projects/:id" element={<Project />}>
              <Route path=":pageId" element={<ProjectPage />} />
            </Route>
            <Route path="/programs" element={<Datasets />} />
            <Route path="/management" element={<UsersManagement />}>
              <Route
                path="users"
                element={<UsersManagementPages.UsersTable />}
              />
              <Route
                path="users/:id"
                element={<UsersManagementPages.UserById />}
              />
              <Route
                path="teams"
                element={<UsersManagementPages.TeamsTable />}
              />
              <Route
                path="teams/:id"
                element={<UsersManagementPages.TeamsById />}
              />
            </Route>
            <Route path="/community" element={<Community />} />
            <Route path="/templates" element={<Templates />} />
          </>
        )}
        {/* <Route path="/settings" element={<SettingsPage />} /> */}
        <Route path="*" element={<ErrorPage />} />
      </Route>
      <Route path="/projects/public/:id" element={<Project />}>
        <Route path=":pageId" element={<ProjectPage />} />
      </Route>
      <Route
        path="/sign-in"
        element={!user ? <SignIn /> : <Navigate to="/" replace />}
      />
      <Route
        path="/sign-up"
        element={!user ? <SignUp /> : <Navigate to="/" replace />}
      />
      <Route
        path="/forgot-password"
        element={!user ? <PasswordReset /> : <Navigate to="/" replace />}
      />
      <Route
        path="/reset-password"
        element={!user ? <ResetPasswordForm /> : <Navigate to="/" replace />}
      />
    </Routes>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trash } from "react-bootstrap-icons";
import { Tooltip } from "react-tooltip";
import { getAllUsers, getDeleteUserId } from "../../../store/selectors/users";
import {
  requestDeleteUsersBulk,
  setDeleteUserId,
} from "../../../store/slices/users";
import {
  resetSelectedUsers,
  selectNewUser,
} from "../../../store/slices/userManagement";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { Button } from "../../Button";
import { AvatarCard } from "../../AvatarCard";
import { getInitials } from "../../../helpers/getInitials";
import { IUser } from "../../../models/Users";
import { Chip } from "../../UserManagementComponents/Users/UsersRow/styled";
import { RemoveUsersIcon } from "../../../assets";
import { setUserProfileById } from "../../../store/slices/profiles";
import {
  Actions,
  CloseIcon,
  ModalFooterWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
  HeaderContainer,
} from "../styles";
import * as s from "./styles";

const tooltipId = "user-name";

type Props = {
  onClose: () => void;
};

export const RemoveUsers = ({ onClose }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedUsers = useSelector(getSelectedUsers);
  const deleteUserId = useSelector(getDeleteUserId);
  const users = useSelector(getAllUsers);

  const [isClosing, setIsClosing] = useState(false);
  const [removeUsers, setRemoveUsers] = useState<IUser[]>([]);
  const hasMultipleRemoveUsers = removeUsers?.length > 1;
  const isDeleteUserInSelection = selectedUsers?.includes(deleteUserId);

  useEffect(() => {
    if ((!deleteUserId || !selectedUsers?.length) && !users?.length) {
      return;
    }
    setRemoveUsers(
      users?.filter((user) =>
        (deleteUserId ? [deleteUserId] : selectedUsers)?.find(
          (us) => us === user.uid
        )
      )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserId, selectedUsers]);

  const handleClose = () => {
    dispatch(setDeleteUserId(undefined));
    setIsClosing(true);
    setRemoveUsers([]);

    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    dispatch(
      requestDeleteUsersBulk({
        userIds: removeUsers?.map((user) => user?.uid),
        callbacks: {
          onSuccess: () => {
            if (deleteUserId && isDeleteUserInSelection) {
              dispatch(selectNewUser(deleteUserId));
            }
            if (deleteUserId && window?.location.pathname.includes("/users/")) {
              navigate("/management/users");
              dispatch(setUserProfileById(undefined));
            }
            if (!deleteUserId) {
              dispatch(resetSelectedUsers());
            }
          },
        },
      })
    );
    handleClose();
  };

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer>
          <RemoveUsersIcon />
          <s.HeaderTitle>
            {`Are you sure you want to delete ${
              hasMultipleRemoveUsers
                ? `these ${selectedUsers?.length} users`
                : "this user"
            }?`}
          </s.HeaderTitle>
          <s.HeaderDescrition>
            {hasMultipleRemoveUsers
              ? "Deleting them from the system will result in their removal from their teams as well!"
              : "Deleting the user from the system will result in their removal from their teams as well!"}
          </s.HeaderDescrition>
          <s.HeaderCloseIcon>
            <CloseIcon onClick={handleClose} />
          </s.HeaderCloseIcon>
        </HeaderContainer>

        <OverviewContent>
          <s.BodyContainer>
            {!!removeUsers?.length &&
              removeUsers?.map((user, index, users) => (
                <s.RemoveUserContainer
                  key={user?.uid}
                  $isLastItem={users?.length === index + 1}
                >
                  <s.RemoveUserBox>
                    <s.RemoveAvatarBox>
                      <AvatarCard
                        avatar={user?.profile?.avatar}
                        size="xs"
                        initials={getInitials(user.name)}
                      />
                      <s.RemoveAvatarName
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={user?.name}
                      >
                        {user?.name}
                      </s.RemoveAvatarName>
                    </s.RemoveAvatarBox>
                    <s.RemoveTeamBox>
                      {!user?.teams?.teams?.length && <Chip>No Team</Chip>}

                      {user?.teams?.teams?.map((team, index) => (
                        <Chip key={index}>{team?.name}</Chip>
                      ))}
                    </s.RemoveTeamBox>
                  </s.RemoveUserBox>
                </s.RemoveUserContainer>
              ))}
            <Tooltip id={tooltipId} style={{ zIndex: 9999 }} />
          </s.BodyContainer>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Delete"
              onClick={handleSave}
              variant="danger"
              size="medium"
              icon={<Trash />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

import React from "react";
import { useLocation } from "react-router-dom";
import { People, PlusLg, Trash, XLg } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { managementPaths } from "../data/paths";
import { useAbilityContext } from "../../../casl/context";
import { resetSelectedUsers } from "../../../store/slices/userManagement";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { setActiveModal } from "../../../store/slices/modals";
import { getLastTeamsManagersById } from "../../../helpers/getLastManager";
import { setLastTeamsUsersManagers } from "../../../store/slices/users";
import { getTeams } from "../../../store/selectors/teams";
import { Button } from "../../Button";

import {
  ButtonsWrapper,
  SelectedText,
  SelectedUsers,
  Tab,
  Tabs,
} from "../styles";

export const UsersHeader = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const ability = useAbilityContext();
  const selectedUsers = useSelector(getSelectedUsers);
  const teams = useSelector(getTeams);

  const selectedUsersCount = selectedUsers.length;

  const handleRemoveUsers = () => {
    const lastTeamsManagers = getLastTeamsManagersById(teams, selectedUsers);

    if (!!lastTeamsManagers.length) {
      dispatch(setActiveModal({ id: "lastManagersTeamsDeclined" }));
      dispatch(setLastTeamsUsersManagers(lastTeamsManagers));
    } else {
      dispatch(setActiveModal({ id: "removeUsers" }));
    }
  };

  return (
    <>
      {!!selectedUsersCount ? (
        <SelectedUsers>
          <XLg onClick={() => dispatch(resetSelectedUsers())} />
          <SelectedText> {selectedUsersCount} selected </SelectedText>
        </SelectedUsers>
      ) : (
        ability.can("read", "users") && (
          <Tabs>
            {managementPaths?.map((item) => (
              <Tab $selected={pathname === item.path} to={item.path}>
                {item.icon} {item.title}
              </Tab>
            ))}
          </Tabs>
        )
      )}

      {!!selectedUsersCount ? (
        <ButtonsWrapper>
          {ability.can("delete", "users") && (
            <Button
              onClick={handleRemoveUsers}
              variant={"danger"}
              icon={<Trash />}
              name="Delete"
              size="large"
            />
          )}
          {ability.can("update", "teams") && (
            <Button
              onClick={() => {
                if (selectedUsers.length >= 50) {
                  toast.warn("You can add maximum 50 members to team");
                  return;
                }
                dispatch(setActiveModal({ id: "addToTeamModal" }));
              }}
              variant={"secondary"}
              icon={<People />}
              name="Add to Teams"
              size={"large"}
            />
          )}
        </ButtonsWrapper>
      ) : (
        <Button
          onClick={() => {
            dispatch(setActiveModal({ id: "invitePeopleModal" }));
          }}
          variant={"secondary"}
          icon={<PlusLg />}
          name="Invite People"
          size={"large"}
        />
      )}
    </>
  );
};
